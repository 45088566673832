import { SIMULATION_COUNTRY_STATE_LIST } from '../data';
import DataService from '../services/DataService';

export default class Utils {
  static getSimulationCountryStateById(id) {
    return SIMULATION_COUNTRY_STATE_LIST[id] || 'Unknown';
  }

  static calculateResourceValues(resources) {
    const RESOURCEID_LIGHT_WEAPON = 34;
    const RESOURCEID_MEDIUM_WEAPON = 42;
    const RESOURCEID_HEAVY_WEAPON = 30;
    const RESOURCEID_SOLDIER = 62;
    const RESOURCEID_JUNIOR_MILITARY_OFFICER = 33;
    const RESOURCEID_SENIOR_MILITARY_OFFICER = 58;
    const RESOURCEID_WATER = 115;
    const RESOURCEID_GOLD = 29;
    const RESOURCEIDS_SYSTEM = [111, 112, 113, 114, 135, 136];
    const RESOURCEIDS_LAND = [10, 16, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 81, 94, 126, 127, 130];
    const RESOURCEIDS_NATURAL = [70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 81, 88, 94, 126, 127, 129, 130];
    const MILITARY_POWER_LIGHT = 2;
    const MILITARY_POWER_MEDIUM = 6;
    const MILITARY_POWER_HEAVY = 18;

    const lightPower = resources.find(
      resource => resource.resourceId === RESOURCEID_LIGHT_WEAPON,
    ).currentSimulationYearRemainingUses;
    const mediumPower = resources.find(
      resource => resource.resourceId === RESOURCEID_MEDIUM_WEAPON,
    ).currentSimulationYearRemainingUses;
    const heavyPower = resources.find(
      resource => resource.resourceId === RESOURCEID_HEAVY_WEAPON,
    ).currentSimulationYearRemainingUses;
    const soldierPower = resources.find(resource => resource.resourceId === RESOURCEID_SOLDIER).remainingQuantity;
    const juniorMilitaryOfficerPower = resources.find(
      resource => resource.resourceId === RESOURCEID_JUNIOR_MILITARY_OFFICER,
    ).remainingQuantity;
    const seniorMilitaryOfficerPower = resources.find(
      resource => resource.resourceId === RESOURCEID_SENIOR_MILITARY_OFFICER,
    ).remainingQuantity;
    const militaryPower =
      MILITARY_POWER_LIGHT * Math.min(lightPower, soldierPower) +
      MILITARY_POWER_MEDIUM * Math.min(mediumPower, juniorMilitaryOfficerPower) +
      MILITARY_POWER_HEAVY * Math.min(heavyPower, seniorMilitaryOfficerPower);

    let totalWater = 0;
    let totalGold = 0;
    let totalLand = 0;
    let totalNatural = 0;
    let totalWealth = 0;

    resources.forEach(resource => {
      if (resource.resourceId === RESOURCEID_WATER) {
        totalWater += resource.remainingQuantity;
      }
      if (resource.resourceId === RESOURCEID_GOLD) {
        totalGold += resource.remainingQuantity;
      }
      if (RESOURCEIDS_LAND.includes(resource.resourceId)) {
        totalLand += resource.remainingQuantity;
      }
      if (RESOURCEIDS_NATURAL.includes(resource.resourceId)) {
        totalNatural += resource.remainingQuantity;
      }
      if (!RESOURCEIDS_SYSTEM.includes(resource.resourceId)) {
        totalWealth += resource.remainingQuantity * resource.ngv;
      }
    });

    return {
      totalWater,
      totalGold,
      totalLand,
      totalNatural,
      totalWealth,
      militaryPower,
    };
  }

  static checkPermissions(minRole, schoolId, classId) {
    const accountRoleList = DataService.getItem('accountRoleList');

    if (accountRoleList.some(role => ['Admin of System', 'Admin of Simpolicon'].includes(role.roleName))) {
      return true;
    }
    if (minRole === 'Admin of School') {
      if (schoolId) {
        return accountRoleList.some(
          role => role.referenceId === schoolId && ['Admin of School'].includes(role.roleName),
        );
      }
      return accountRoleList.some(role => ['Admin of School'].includes(role.roleName));
    }
    if (minRole === 'Teacher of Class') {
      return accountRoleList.some(
        role =>
          (role.referenceId === classId && ['Teacher of Class'].includes(role.roleName)) ||
          (role.referenceId === schoolId && ['Admin of School'].includes(role.roleName)),
      );
    }

    return false;
  }

  static getResourceTypeNameById(resourceTypeId) {
    const resourceTypeList = DataService.getItem('resourceTypeList');
    return resourceTypeList.find(rt => rt.resourceTypeId === resourceTypeId).resourceTypeNameLocalized;
  }

  static getResourceTypeNameByResourceId(resourceId) {
    const resourceTypeList = DataService.getItem('resourceTypeList');
    const resourceList = DataService.getItem('resourceList');
    const resource = resourceList.find(r => r.resourceId === resourceId);
    return resourceTypeList.find(rt => rt.resourceTypeId === resource.resourceTypeId).resourceTypeNameLocalized;
  }

  static getResourceNameById(resourceId) {
    const resourceList = DataService.getItem('resourceList');
    return resourceList.find(r => r.resourceId === resourceId).resourceNameLocalized;
  }
}
