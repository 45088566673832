import styled from 'styled-components';
import { Card } from 'antd';

export const Page = styled(Card)`
  & > .ant-card-body {
    padding: 0;
  }

  .menu-title {
    padding: 4px;
    background-color: #ddd;
    text-align: center;
    font-size: 10px;
  }

  .ant-menu-item {
    height: 64px;
    line-height: 64px;

    .quantity {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid #ddd;
      font-size: 10px;
      line-height: 10px;
      color: #007070;
    }
  }

  .description {
    margin-top: 16px;

    p {
      margin: 0 0 4px;
      font-size: 12px;
    }
  }

  .basic-production .ant-card-body {
    padding: 0 8px;
  }

  .recipe-summary {
    .ant-card-body {
      img {
        margin-right: 16px;
      }

      .recipe-summary-data {
        flex: 1;

        h2 {
          margin-top: 0;
          margin-bottom: 4px;
        }

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }

  div {
    max-width: 100px;
    font-size: 12px;
    line-height: 1.2;
    text-wrap: wrap;
  }
`;

export const SummaryTable = styled.table`
  width: 100%;
  font-size: 14px;

  tr {
    th:first-child {
      text-align: left;
    }

    th {
      background-color: #5b5b5b;
      color: #fff;
    }

    td:nth-child(2) {
      text-align: center;
    }

    td:nth-child(3) {
      text-align: center;
    }

    th,
    td {
      padding: 0 8px;
      border: 1px solid #eee;
    }
  }
`;
