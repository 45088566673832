import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment-timezone';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';

const getHistoryTableColumns = scResourceList => {
  const countryList = DataService.getItem('countryList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'attackingCountry',
      title: 'Attacking Country',
      render: row => {
        const country = countryList.find(c => c.countryId === row.attackingCountryId);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'countryAttacked',
      title: 'Country Attacked',
      render: row => {
        const country = countryList.find(c => c.countryId === row.attackedCountryId);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'attackForceApplied',
      title: 'Attack Force Applied',
      render: row => row.lightPowerQuantity * 2 + row.mediumPowerQuantity * 6 + row.heavyPowerQuantity * 18,
    },
    {
      id: 'outcome',
      title: 'Outcome',
      render: row => {
        const country = countryList.find(c => c.countryId === row.attackingCountryId);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>
              {countryName}{' '}
              <span className={row.warResult > 0 ? 'text-success' : 'text-error'}>
                ({row.warResult > 0 ? 'Won' : 'Lost'})
              </span>
            </span>
          </>
        );
      },
    },
    {
      id: '1stResourceGainedLost',
      title: '1st Resource Gained Lost',
      render: row => {
        const gainTxn = row.resourceTransactionArrayAttacker.find(txn => txn.ResourceId === row.resourceIdDemand1);
        const resource = resourceList.find(r => r.resourceId === row.resourceIdDemand1);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return row.warResult > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>
              {resource.resourceNameLocalized}{' '}
              <span className={row.warResult > 0 ? 'text-success' : 'text-error'}>
                ({gainTxn?.TransactionQuantity ?? 0})
              </span>
            </span>
          </div>
        ) : (
          <span className={row.warResult > 0 ? 'text-success' : 'text-error'}>--</span>
        );
      },
    },
    {
      id: '2ndResourceGainedLost',
      title: '2nd Resource Gained Lost',
      render: row => {
        const gainTxn = row.resourceTransactionArrayAttacker.find(txn => txn.ResourceId === row.resourceIdDemand2);
        const resource = resourceList.find(r => r.resourceId === row.resourceIdDemand2);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return row.warResult > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>
              {resource.resourceNameLocalized}{' '}
              <span className={row.warResult > 0 ? 'text-success' : 'text-error'}>
                ({gainTxn?.TransactionQuantity ?? 0})
              </span>
            </span>
          </div>
        ) : (
          <span className={row.warResult > 0 ? 'text-success' : 'text-error'}>--</span>
        );
      },
    },
    {
      id: 'dateOfWar',
      title: 'Date of War',
      render: row => moment(row.submittedOn).format('YYYY-MM-DD hh:mm:ss A'),
    },
  ];

  return columns;
};

export const History = () => {
  const params = useParams();
  const [history, setHistory] = useState([]);
  const HISTORY_TABLE_COLUMNS = useMemo(() => getHistoryTableColumns(), []);

  const loadData = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    const scWarList = data.result.simulationCountryWarObjectList;
    data = await ApiService.retrieveSimulation(data.result.simulationId);
    const scCountryList = data.result.simulationCountrySimpleViewObjectList;
    scWarList.forEach((w, index) => {
      const attackingCountry = scCountryList.find(c => c.simulationCountryId === w.simulationCountryIdAttackedAnother);
      w.attackingCountryId = attackingCountry.countryId;
      const attackedCountry = scCountryList.find(c => c.simulationCountryId === w.simulationCountryIdAttackedByAnother);
      w.attackedCountryId = attackedCountry.countryId;
      w.id = index;
    });
    setHistory(scWarList);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Table size="small" pagination={false} rowKey="id" columns={HISTORY_TABLE_COLUMNS} dataSource={history} />
    </>
  );
};
