import styled from 'styled-components';
import { Breadcrumb, Layout } from 'antd';

const { Content, Header, Sider } = Layout;

const bgColor = '#0f131a';

export const MainLayout = styled(Layout)({
  minHeight: '100vh',
});

export const StyledHeader = styled(Header)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSider = styled(Sider)`
  background: #fff !important;

  .ant-menu-item {
    height: 60px;
    line-height: 60px;

    .ant-menu-item-icon {
      width: 40px;
    }

    .ant-menu-title-content {
      font-size: 16px;
    }

    &-selected {
      .ant-menu-item-icon {
        border: 3px solid red;
        border-radius: 8px;
        box-sizing: content-box;
      }
    }

    &-disabled {
      opacity: 0.5;
      color: inherit !important;
      pointer-events: none;

      .ant-menu-item-icon {
        border: none;
      }
    }
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.88) !important;
  }
`;

export const StyledContent = styled(Content)({
  padding: 0,
  backgroundColor: '#fff',
});

export const StyledBreadcrumb = styled(Breadcrumb)({
  marginBottom: 16,
});

export const Logo = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #fff;
`;

export const DataLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: ${bgColor};

  .ant-progress-status-normal .ant-progress-text {
    color: #fff;
  }

  h4 {
    color: #fff;
  }
`;
