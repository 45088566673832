import { useEffect, useState } from 'react';
import { notification, Button, Form, Modal } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

export const ManageOfferModal = ({ isOpen, offer, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const resource = DataService.getItem('resourceList').find(r => r.resourceId === offer?.resourceId);
  const iconName = resource?.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.offerId = offer.offerId;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.buyAnonymous = false;
      await ApiService.addSimulationOfferResponse(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Manage Offer"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="manage-offer-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Update Offer
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <p>
          This is your country's offer. You can reduce or remove this offer. Please verify the quantity to remove from
          the existing offer. Reducing all units will remove the offer from the market.
        </p>
        <p>There are {offer?.quantity} units available.</p>
        <SelectOption style={{ marginBottom: 16 }}>
          {resource && <img src={iconUrl} alt={iconName} />}
          <span>{resource?.resourceNameLocalized}</span>
        </SelectOption>
        <hr />
        <Form.Item
          label="Quantity to Remove"
          name="offerResponseQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
            {
              validator: (_, value) => {
                const max = offer?.quantity ?? 1;
                if (value > max) {
                  return Promise.reject(new Error(`The maximum quantity to remove is ${max} for this offer.`));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber2 min={1} max={offer?.quantity ?? 1} />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
