import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal, Select } from 'antd';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';

export const SimulationCountryModal = ({ simulationId, country, onClose, onSave }) => {
  const regionList = DataService.getItem('regionList').sort((a, b) => {
    const aRegionName = a.regionName;
    const bRegionName = b.regionName;
    const aPrimaryPart = aRegionName.includes(' ') ? aRegionName.split(' ')[1] : aRegionName;
    const bPrimaryPart = bRegionName.includes(' ') ? bRegionName.split(' ')[1] : bRegionName;
    if (aPrimaryPart !== bPrimaryPart) {
      return aPrimaryPart.localeCompare(bPrimaryPart);
    }
    const aSecondaryPart = aRegionName.split(' ')[0];
    const bSecondaryPart = bRegionName.split(' ')[0];
    return aSecondaryPart.localeCompare(bSecondaryPart);
  });
  const countryList = DataService.getItem('countryList');

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [countryOptions, setCountryOptions] = useState(countryList);

  const handleFormValuesChange = async e => {
    if (e.countryId) {
      const country = countryList.find(country => country.countryId === e.countryId);
      form.setFieldValue('regionId', country.regionId);
    } else if (e.regionId) {
      setCountryOptions(countryList.filter(country => country.regionId === e.regionId));
      form.resetFields(['countryId', 'simulationCountryName']);
    }
  };

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = {
        countryId: values.countryId,
        simulationCountryName: values.simulationCountryName || '',
      };
      await ApiService.addSimulationCountry(simulationId, payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
    setCountryOptions(countryList);
    // eslint-disable-next-line
  }, [form, country]);

  return (
    <Modal
      title="Add Country"
      open={!!country}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={country}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          label="Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: 'Country is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="countryName"
            fieldNames={{ label: 'countryName', value: 'countryId' }}
            options={countryOptions}
          />
        </Form.Item>
        <Form.Item
          label="Region"
          name="regionId"
          rules={[
            {
              required: true,
              message: 'Region is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a region"
            optionFilterProp="regionName"
            fieldNames={{ label: 'regionName', value: 'regionId' }}
            options={regionList}
          />
        </Form.Item>
        <Form.Item label="Custom Name" name="simulationCountryName">
          <Input />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
