import { Card } from 'antd';

export const RulesOfWar = () => {
  return (
    <>
      <Card size="small" title="Military Points">
        1 Soldier and 1 Light Weapon = 2 MPs
        <br />
        1 Jr. Military Officer and 1 Medium Weapon = 6 MPs
        <br />
        1 Sr. Military Officer and 1 Heavy Weapon = 18 MPs
        <br />
        Military Points are based on the lesser of the two components (CPs). For example, 20 Light Weapons with 5
        Soldiers makes 10 MPs, 3 Light Weapons with 5 Soldiers makes 6 MPs.
      </Card>
      <Card style={{ marginTop: 16 }} size="small" title="Rules">
        Personnel without a weapon or a weapon without personnel have no power to attack or defend.
        <br />
        Attacking country may attack with a minimum 6 MPs.
        <br />
        When attacking, one use will be subtracted from every personnel or weapon used.
        <br />
        Weanons consumed this way will be counted as part of MPs lost.
        <br />
        Attacking country must use Gold to finance all attacks. The attacking country is charged. in Gold. 10% of the
        total MPs used for the attack. or 2 Gold. whichever is larger.
        <br />
        To win the attacking country must have committed more than 3 times the military points of the defending country.
        <br />
        Example: defending country has 4 MPs: attacking country needs more than 12 MPs to win (4MPs x 3 = 12 MPs). The
        amount of MPs more than 12 is the margin of victory (MV).
        <br />
        Miltary losses are calculated using MPs and/or CPs that meet or exceed the required amount by the smallest
        margin.
      </Card>
      <Card style={{ marginTop: 16 }} size="small" title="Attacking Country">
        Successful Attack:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Gains for victor:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gain up to 10% (rounded) of two resources (land or mineral
        deposits) for which they are attacking OR
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gain up to 20% (rounded) of two tradable products (i.e., Small
        Machines, Production Equipment, Coal, People, etc.) for which they are attacking.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The actual % gained is directly related to the Margin of Victory
        (MV) up to the maximum indicated.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Losses for victor:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MPs and/or CPs (weapons and personnel) equivalent to 3 times the
        MPs of the defending force.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If no defending force, 10% (rounded) of MPs and/or Ps (weapons
        and personnel), minimum 2 MPs, and Gold as above.
        <br />
        Unsuccessful Attack:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Gains for attacking country - none.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Losses for attacking country:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total committed MPs (weapons and personnel), and Gold as above.
      </Card>
      <Card style={{ marginTop: 16 }} size="small" title="Defending Country">
        Unsuccessful Defense:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Lose up to 10% (rounded) of 2 resources (Land or Mineral Deposits) for which they were
        attacked AND MPs and/or CPs up to the attacking force's MPs, AND 1 Gold. OR
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Lose up to 20% (rounded) of 2 tradable products (i.e. Small Machines, Production
        Equipment, Coal, People, etc.) for which they were attacked and MPs and/or Cs up to the attacking force's MPs,
        AND 1 Gold.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;The actual % lost is directly related to the MV as described above.
        <br />
        Successful Defense:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Gains - keep resources and/or products. &nbsp;&nbsp;&nbsp;&nbsp;Losses - 25% (rounded)
        of MPs (minimum 2 MPs and/or CPs), and 1 Gold.
      </Card>
    </>
  );
};
