import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .ant-card {
    max-width: 360px;
    width: 100%;
    text-align: center;

    .ant-card-body {
      border: 2px solid #5b5b5b;
    }

    .year {
      text-align: center;

      img {
        padding-right: 8px;
      }
    }

    .img-earth {
      margin: 48px;
    }
  }
`;
