import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { CaretRightOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { SIMULATION_YEARS_TABLE_COLUMNS } from './data';
import { ActionColumn, SuccessButton } from './styles';
import { SimulationYearModal } from './SimulationYearModal';

export const SimulationYears = () => {
  const { simulationId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [simulationYears, setSimulationYears] = useState([]);
  const [year, setYear] = useState(null);

  const fetchSimulation = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSimulation(simulationId);
      if (data.success) {
        const { simulationYearArray } = data.result;
        simulationYearArray.forEach((year, index) => {
          year.Year = index + 1;
          year.Status = ['NOT STARTED', 'IN PROGRESS', 'COMPLETED'][year.GoalWeightingType - 1];
        });
        setSimulationYears(simulationYearArray);

        setBreadcrumbItems([
          {
            title: 'Simulations',
            path: '/main/simulations',
          },
          {
            title: data.result.simulationName,
            path: `/main/simulations/${data.result.simulationId}`,
          },
          {
            title: 'Years',
          },
        ]);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddYear = async () => {
    if (simulationYears.some(y => y.Status === 'NOT STARTED')) {
      await Swal.fire({
        title: 'Note',
        icon: 'info',
        text: 'There is a year that has not started yet. Please start that year first by clicking the green play button.',
      });
      return;
    }
    if (simulationYears.some(y => y.Status === 'IN PROGRESS')) {
      await Swal.fire({
        title: 'Note',
        icon: 'info',
        text: 'There is a year that is already in progress. Please complete that year first by clicking the green check mark button.',
      });
      return;
    }
    setYear({});
  };

  const handleEditYear = year => {
    setYear(year);
  };

  const handleCloseYearModal = () => {
    setYear(null);
  };

  const handleYearSaved = () => {
    setYear(null);
    fetchSimulation();
  };

  const handleEndYear = async year => {
    try {
      if (year.GoalWeightingType === 1) {
        if (simulationYears.find(y => y.GoalWeightingType === 2)) {
          Swal.fire({
            title: 'There is another year in progress!',
            icon: 'info',
            text: 'This year cannot be started at this time.',
          });
          return;
        }
        if (simulationYears.find(y => y.GoalWeightingType === 1 && y.Year < year.Year)) {
          Swal.fire({
            title: 'Years must be played in order!',
            icon: 'info',
            text: 'Another year before this year has not been played.',
          });
          return;
        }
      }
      const index = simulationYears.findIndex(y => y.Year === year.Year);
      const updatedYears = [...simulationYears];
      if (year.GoalWeightingType === 1) {
        updatedYears[index].StartsOn = moment().utc().format('M/D/YYYY H:m:s');
      } else if (year.GoalWeightingType === 2) {
        updatedYears[index].EndsOn = moment().utc().format('M/D/YYYY H:m:s');
      }
      updatedYears[index].GoalWeightingType += 1;
      updatedYears.forEach(y => {
        delete y.Year;
        delete y.Status;
      });
      const payload = {
        simulationYearArrayStringified: JSON.stringify(updatedYears),
      };
      await ApiService.updateSimulationYears(simulationId, payload);
      await fetchSimulation();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    // eslint-disable-next-line
  }, []);

  const simulationYearsTableColumns = [
    ...SIMULATION_YEARS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Button
            size="small"
            type="primary"
            ghost
            icon={<EditOutlined />}
            onClick={() => handleEditYear(row)}
            disabled={row.Status === 'COMPLETED'}
          />
          <Popconfirm
            title={`Are you sure to ${row.GoalWeightingType === 1 ? 'start' : 'end'} this year?`}
            onConfirm={() => handleEndYear(row)}
          >
            <SuccessButton
              size="small"
              icon={row.Status === 'NOT STARTED' ? <CaretRightOutlined /> : <CheckOutlined />}
              disabled={row.Status === 'COMPLETED'}
            />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddYear}>
        Add Year
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Years" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={simulationYearsTableColumns}
          dataSource={simulationYears}
          rowKey="Year"
          bordered
          head
        />
      </Card>
      <SimulationYearModal
        simulationId={simulationId}
        years={simulationYears}
        year={year}
        onClose={handleCloseYearModal}
        onSave={handleYearSaved}
      />
    </>
  );
};
