import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { notification, Button, Card, Dropdown, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { SCHOOLS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SchoolModal } from './SchoolModal';
import Utils from '../../../utils';

export const Schools = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState(null);

  const fetchSchools = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSchoolList();
      if (data.success) {
        setSchools(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddSchool = () => {
    setSchool({});
  };

  const handleEditSchool = school => {
    setSchool(school);
  };

  const handleCloseSchoolModal = () => {
    setSchool(null);
  };

  const handleSchoolSaved = () => {
    setSchool(null);
    fetchSchools();
  };

  const handleDeleteSchool = async school => {
    try {
      await ApiService.deleteSchool(school.schoolId);
      await fetchSchools();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  const getMenuItems = school => {
    const menuItems = [
      {
        key: 'manage-classes',
        label: <NavLink to={`/main/schools/${school.schoolId}/classes`}>Manage Classes</NavLink>,
      },
      {
        key: 'manage-students',
        label: <NavLink to={`/main/schools/${school.schoolId}/students`}>Manage Students</NavLink>,
      },
      {
        key: 'manage-teachers',
        label: <NavLink to={`/main/schools/${school.schoolId}/teachers`}>Manage Teachers</NavLink>,
      },
    ];
    if (Utils.checkPermissions('Admin of School', school.schoolId)) {
      menuItems.push({
        key: 'manage-admins',
        label: <NavLink to={`/main/schools/${school.schoolId}/admins`}>Manage Admins</NavLink>,
      });
    }
    return menuItems;
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  const schoolsTableColumns = [
    ...SCHOOLS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          {Utils.checkPermissions() && (
            <Popconfirm title="Are you sure to delete this school?" onConfirm={() => handleDeleteSchool(row)}>
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          {Utils.checkPermissions('Admin of School', row.schoolId) && (
            <Button size="small" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditSchool(row)} />
          )}
          <Dropdown menu={{ items: getMenuItems(row) }} trigger={['click']}>
            <Button size="small" icon={<MoreOutlined />} />
          </Dropdown>
        </ActionColumn>
      ),
    },
  ];
  schoolsTableColumns[0].render = (_, row) => <NavLink to={`/main/schools/${row.schoolId}`}>{row.schoolName}</NavLink>;

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddSchool}>
        Add School
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Schools" extra={Utils.checkPermissions() ? ActionBar : null}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={schoolsTableColumns}
          dataSource={schools}
          rowKey="schoolId"
          bordered
          head
        />
      </Card>
      <SchoolModal school={school} onClose={handleCloseSchoolModal} onSave={handleSchoolSaved} />
    </>
  );
};
