export const STUDENTS_TABLE_COLUMNS = [
  {
    id: 'lastName',
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    id: 'firstName',
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    id: 'username',
    title: 'Username',
    dataIndex: 'userName',
  },
  {
    id: 'classSection',
    title: 'Class Section',
    dataIndex: 'classSection',
  },
];
