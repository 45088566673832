import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import { GOALS } from '../../../../data';

const Wrapper = styled.div`
  .total {
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;

    div {
      padding: 8px;
      border-right: 1px solid #d8d8d8;
    }

    div:nth-child(2) {
      border-right: none;
      text-align: right;
    }
  }

  .objectives-table {
    margin-top: 16px;
    border: 1px solid #d8d8d8;

    .ant-row {
      border-bottom: 1px solid #d8d8d8;

      &:last-child {
        border-bottom: none;
      }

      .ant-col {
        padding: 4px 8px;
        border-right: 1px solid #d8d8d8;

        &:last-child {
          border-right: none;
        }
      }

      &:first-child {
        .ant-col {
          padding: 8px;

          &:first-child {
            font-weight: 700;
          }

          &:not(:first-child) {
            text-align: center;
          }
        }
      }

      &:not(:first-child) {
        .ant-col {
          &:not(:first-child) {
            text-align: right;
          }

          &:first-child {
            text-align: left;
          }
        }
      }

      &:last-child {
        .ant-col {
          &:first-child {
            padding-right: 16px;
            text-align: right;
          }

          &:last-child {
            font-weight: 700;
          }
        }
      }
    }

    &.social {
      .ant-row {
        &:first-child {
          background-color: #ffffca;
        }

        &:last-child {
          .ant-col:last-child {
            background-color: #ffffca;
          }
        }

        &:not(:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4)) {
          .ant-col:first-child {
            padding-left: 16px;
          }
        }
      }
    }

    &.economic {
      .ant-row {
        &:first-child {
          background-color: #b3fde5;
        }

        &:last-child {
          .ant-col:last-child {
            background-color: #b3fde5;
          }
        }

        &:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
          .ant-col:first-child {
            padding-left: 16px;
          }
        }
      }
    }

    &.environmental {
      .ant-row {
        &:first-child {
          background-color: #bcecfa;
        }

        &:last-child {
          .ant-col:last-child {
            background-color: #bcecfa;
          }
        }

        &:not(:nth-child(1), :nth-child(2), :nth-last-child(3)) {
          .ant-col:first-child {
            padding-left: 16px;
          }
        }
      }
    }
  }
`;

export const EvaluationScore = () => {
  const { calculateSimulationCountryGoals } = useSimulationContext();
  const [goals, setGoals] = useState(GOALS);

  const loadData = async () => {
    const { goals, goalRanks } = await calculateSimulationCountryGoals();

    // Set Ranks
    for (let i = 0; i < 12; i += 1) {
      goals[i].rank = goalRanks[i];
    }

    setGoals(_.cloneDeep(goals));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  if (!('value' in goals[0])) {
    return null;
  }

  const totalValue = goals.reduce((total, goal) => total + goal.actualValue ?? 0, 0).toFixed(2);
  const socialValue = goals[10].actualValue + goals[8].actualValue + goals[11].actualValue;
  const economicValue =
    goals[0].actualValue +
    goals[2].actualValue +
    goals[3].actualValue +
    goals[4].actualValue +
    goals[5].actualValue +
    goals[6].actualValue +
    goals[7].actualValue;
  const environmentalValue = goals[9].actualValue + goals[1].actualValue;

  return (
    <Wrapper>
      <p className="mt-0">
        See <b>Economic Goals</b> {'>'} <b>Economic Sectors</b> for number of different sectors completed within a
        category. If all in a category are complete then additional 50 bonus points.
      </p>

      <Row className="total">
        <Col span={20}>Grand Total</Col>
        <Col
          className={+totalValue.replace(/,/g, '') >= 0 ? 'bg-success text-success' : 'bg-error text-error'}
          span={4}
        >
          {totalValue}
        </Col>
      </Row>

      <div className="objectives-table social">
        <Row>
          <Col span={8}>Social Objectives</Col>
          <Col span={4}>Goal Rank</Col>
          <Col span={4}>Goal Weight</Col>
          <Col span={4}>Points</Col>
          <Col span={4}>Weighted Points</Col>
        </Row>
        <Row>
          <Col span={8}>Minimize Deaths</Col>
          <Col span={4}>{goals[10].rank + 1}</Col>
          <Col span={4}>{12 - goals[10].rank}</Col>
          <Col span={4}>{-goals[10].children[0].value}</Col>
          <Col span={4}>{goals[10].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Educate People: % Skilled +</Col>
          <Col span={4}>{goals[8].rank + 1}</Col>
          <Col span={4}>{12 - goals[8].rank}</Col>
          <Col span={4}>{goals[8].children[0].value}</Col>
          <Col span={4}>{goals[8].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Provide Consumer Goods</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
        </Row>
        <Row>
          <Col span={8}>Major Purchases Per Capita @ 50 PTS</Col>
          <Col span={4}>{goals[11].rank + 1}</Col>
          <Col span={4}>{12 - goals[11].rank}</Col>
          <Col span={4}>{goals[11].children[0].value}</Col>
          <Col span={4}>{goals[11].children[0].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Durable Goods Per Capita @ 20 PTS</Col>
          <Col span={4}>{goals[11].rank + 1}</Col>
          <Col span={4}>{12 - goals[11].rank}</Col>
          <Col span={4}>{goals[11].children[1].value}</Col>
          <Col span={4}>{goals[11].children[1].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Non-Durable Goods Per Capita @ 10 PTS</Col>
          <Col span={4}>{goals[11].rank + 1}</Col>
          <Col span={4}>{12 - goals[11].rank}</Col>
          <Col span={4}>{goals[11].children[2].value}</Col>
          <Col span={4}>{goals[11].children[2].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Bonus Points From Foreign Tourism Tickets @ 10 PTS</Col>
          <Col span={4}>{goals[11].rank + 1}</Col>
          <Col span={4}>{12 - goals[11].rank}</Col>
          <Col span={4}>{goals[11].children[3].value}</Col>
          <Col span={4}>{goals[11].children[3].weightedValue}</Col>
        </Row>
        <Row>
          <Col span={8}>Bonus Points From Eating Imported Specialty Food @ 10 PTS</Col>
          <Col span={4}>{goals[11].rank + 1}</Col>
          <Col span={4}>{12 - goals[11].rank}</Col>
          <Col span={4}>{goals[11].children[4].value}</Col>
          <Col span={4}>{goals[11].children[4].weightedValue}</Col>
        </Row>
        <Row>
          <Col span={20}>Social Objectives Subtotal</Col>
          <Col span={4}>{socialValue.toFixed(2)}</Col>
        </Row>
      </div>

      <div className="objectives-table economic">
        <Row>
          <Col span={8}>Economic Objectives</Col>
          <Col span={4}>Goal Rank</Col>
          <Col span={4}>Goal Weight</Col>
          <Col span={4}>Points</Col>
          <Col span={4}>Weighted Points</Col>
        </Row>
        <Row>
          <Col span={8}>GDP Per Capita</Col>
          <Col span={4}>{goals[0].rank + 1}</Col>
          <Col span={4}>{12 - goals[0].rank}</Col>
          <Col span={4}>{goals[0].children[0].value}</Col>
          <Col span={4}>{goals[0].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Economic Sectors Completed</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
        </Row>
        <Row>
          <Col span={8}>Agriculture And Mining</Col>
          <Col span={4}>{goals[2].rank + 1}</Col>
          <Col span={4}>{12 - goals[2].rank}</Col>
          <Col span={4}>{goals[2].points}</Col>
          <Col span={4}>{goals[2].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Business Infrastructure</Col>
          <Col span={4}>{goals[3].rank + 1}</Col>
          <Col span={4}>{12 - goals[3].rank}</Col>
          <Col span={4}>{goals[3].points}</Col>
          <Col span={4}>{goals[3].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Education Infrastructure</Col>
          <Col span={4}>{goals[4].rank + 1}</Col>
          <Col span={4}>{12 - goals[4].rank}</Col>
          <Col span={4}>{goals[4].points}</Col>
          <Col span={4}>{goals[4].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Health Services</Col>
          <Col span={4}>{goals[5].rank + 1}</Col>
          <Col span={4}>{12 - goals[5].rank}</Col>
          <Col span={4}>{goals[5].points}</Col>
          <Col span={4}>{goals[5].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Life Style</Col>
          <Col span={4}>{goals[6].rank + 1}</Col>
          <Col span={4}>{12 - goals[6].rank}</Col>
          <Col span={4}>{goals[6].points}</Col>
          <Col span={4}>{goals[6].value}</Col>
        </Row>
        <Row>
          <Col span={8}>Military Strength</Col>
          <Col span={4}>{goals[7].rank + 1}</Col>
          <Col span={4}>{12 - goals[7].rank}</Col>
          <Col span={4}>{goals[7].points}</Col>
          <Col span={4}>{goals[7].value}</Col>
        </Row>
        <Row>
          <Col span={20}>Economic Objectives Subtotal</Col>
          <Col span={4}>{economicValue.toFixed(2)}</Col>
        </Row>
      </div>

      <div className="objectives-table environmental">
        <Row>
          <Col span={8}>Environmental Objectives</Col>
          <Col span={4}>Goal Rank</Col>
          <Col span={4}>Goal Weight</Col>
          <Col span={4}>Points</Col>
          <Col span={4}>Weighted Points</Col>
        </Row>
        <Row>
          <Col span={8}>Resource % Depletion (-) Or Gain (+)</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
        </Row>
        <Row>
          <Col span={8}>Bauxite Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[0].value}</Col>
          <Col span={4}>{goals[1].children[0].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Coal Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[1].value}</Col>
          <Col span={4}>{goals[1].children[1].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Copper Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[2].value}</Col>
          <Col span={4}>{goals[1].children[2].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>High Tech Mineral Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[3].value}</Col>
          <Col span={4}>{goals[1].children[3].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Cotton Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[4].value}</Col>
          <Col span={4}>{goals[1].children[4].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Forest Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[5].value}</Col>
          <Col span={4}>{goals[1].children[5].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Gold Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[6].value}</Col>
          <Col span={4}>{goals[1].children[6].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Grains Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[7].value}</Col>
          <Col span={4}>{goals[1].children[7].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Grazing Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[8].value}</Col>
          <Col span={4}>{goals[1].children[8].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Iron Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[9].value}</Col>
          <Col span={4}>{goals[1].children[9].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Petroleum Deposit</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[10].value}</Col>
          <Col span={4}>{goals[1].children[10].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Riparian Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[11].value}</Col>
          <Col span={4}>{goals[1].children[11].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Tubers Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[12].value}</Col>
          <Col span={4}>{goals[1].children[12].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Specialty Foods Land</Col>
          <Col span={4}>{goals[1].rank + 1}</Col>
          <Col span={4}>{12 - goals[1].rank}</Col>
          <Col span={4}>{goals[1].children[13].value}</Col>
          <Col span={4}>{goals[1].children[13].weightedValue.toFixed(2)}</Col>
        </Row>
        <Row>
          <Col span={8}>Limit Pollution</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
          <Col span={4}>--</Col>
        </Row>
        <Row>
          <Col span={8}>Net Pollution Units (Pollution - Pollution Clean-Up)</Col>
          <Col span={4}>{goals[9].rank + 1}</Col>
          <Col span={4}>{12 - goals[9].rank}</Col>
          <Col span={4}>{goals[9].children[2].value}</Col>
          <Col span={4}>{goals[9].value}</Col>
        </Row>
        <Row>
          <Col span={20}>Environmental Objectives Subtotal</Col>
          <Col span={4}>{environmentalValue.toFixed(2)}</Col>
        </Row>
      </div>
    </Wrapper>
  );
};
