import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Select, Table } from 'antd';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { AddOfferModal } from './AddOfferModal';
import { ManageOfferModal } from './ManageOfferModal';
import { PurchaseProductModal } from './PurchaseProductModal';
import { SelectOption } from './styles';
import { AddRequestModal } from './AddRequestModal';
import { ManageRequestModal } from './ManageRequestModal';
import { FulfillRequestModal } from './FulfillRequestModal';

const getCategoryList = () => {
  const categoryList = [
    {
      value: 0,
      label: (
        <SelectOption>
          <img src="/images/pages/simulation/trade/market/all-categories.png" alt="" />
          <div>All Categories</div>
        </SelectOption>
      ),
    },
    ...DataService.getItem('resourceTypeList')
      .filter(r => r.tradable)
      .sort((a, b) => a.resourceTypeNameLocalized.localeCompare(b.resourceTypeNameLocalized))
      .map(rt => {
        const iconName = rt.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resource-types/${iconName}`;
        const item = {
          value: rt.resourceTypeId,
          label: (
            <SelectOption>
              <img src={iconUrl} alt="" />
              <div>{rt.resourceTypeNameLocalized}</div>
            </SelectOption>
          ),
        };
        return item;
      }),
  ];
  return categoryList;
};

const getOfferTableColumns = (simulationCountryId, handleManageOffer, handlePurchaseProduct, setSelectedResourceId) => {
  const countryList = DataService.getItem('countryList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'product',
      title: 'Product',
      width: 240,
      render: row => {
        const resource = resourceList.find(r => r.resourceId === row.resourceId);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resource.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'countryOffering',
      title: 'Country Offering',
      width: 200,
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdSeller);
        const flagUrl = `/images/flags/${row.sellAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center',
    },
    {
      id: 'pricePerUnit',
      title: 'Price Per Unit',
      align: 'center',
      render: row => row.unitPrice.toFixed(2),
    },
    {
      id: 'totalPrice',
      title: 'Total Price',
      align: 'center',
      render: row => (row.quantity * row.unitPrice).toFixed(2),
    },
    {
      id: 'actions',
      align: 'center',
      render: row => {
        if (row.simulationCountryIdSeller === simulationCountryId) {
          return (
            <Button style={{ width: 80 }} type="primary" size="small" danger onClick={() => handleManageOffer(row)}>
              Manage
            </Button>
          );
        }
        return (
          <Button style={{ width: 80 }} type="primary" size="small" onClick={() => handlePurchaseProduct(row)}>
            Purchase
          </Button>
        );
      },
    },
  ];

  return columns;
};

const getRequestTableColumns = (
  simulationCountryId,
  handleManageRequest,
  handleFulfillRequest,
  setSelectedResourceId,
) => {
  const countryList = DataService.getItem('countryList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'product',
      title: 'Product',
      width: 240,
      render: row => {
        const resource = resourceList.find(r => r.resourceId === row.resourceId);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resource.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'countryRequesting',
      title: 'Country Requesting',
      width: 200,
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdBuyer);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.buyAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center',
    },
    {
      id: 'pricePerUnit',
      title: 'Price Per Unit',
      align: 'center',
      render: row => row.unitPrice.toFixed(2),
    },
    {
      id: 'totalPrice',
      title: 'Total Price',
      align: 'center',
      render: row => (row.quantity * row.unitPrice).toFixed(2),
    },
    {
      id: 'actions',
      align: 'center',
      render: row => {
        if (row.simulationCountryIdBuyer === simulationCountryId) {
          return (
            <Button style={{ width: 80 }} type="primary" size="small" danger onClick={() => handleManageRequest(row)}>
              Manage
            </Button>
          );
        }
        return (
          <Button style={{ width: 80 }} type="primary" size="small" onClick={() => handleFulfillRequest(row)}>
            Fulfill
          </Button>
        );
      },
    },
  ];

  return columns;
};

export const Market = () => {
  const params = useParams();
  const { resourceList, simulationCountry, fetchSimulationCountry, setSelectedResourceId } = useSimulationContext();
  const categoryList = getCategoryList();
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [offers, setOffers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] = useState(false);
  const [isManageOfferModalOpen, setIsManageOfferModalOpen] = useState(false);
  const [isPurchaseProductModalOpen, setIsPurchaseProductModalOpen] = useState(false);
  const [offer, setOffer] = useState(null);
  const [isAddRequestModalOpen, setIsAddRequestModalOpen] = useState(false);
  const [isManageRequestModalOpen, setIsManageRequestModalOpen] = useState(false);
  const [isFulfillRequestModalOpen, setIsFulfillRequestModalOpen] = useState(false);
  const [request, setRequest] = useState(null);

  const refresh = () => {
    fetchOffers();
    fetchRequests();
  };

  const fetchOffers = async () => {
    const data = await ApiService.retrieveSimulationOfferList(simulationCountry.simulationId, {
      resourceTypeId: selectedCategoryId,
      tradable: true,
      migratable: false,
      resourceId: 0,
    });
    data.resultList.forEach(offer => {
      offer.resourceNameLocalized = resourceList.find(r => r.resourceId === offer.resourceId).resourceNameLocalized;
    });
    data.resultList.sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized));
    setOffers(data.resultList);
  };

  const fetchRequests = async () => {
    const data = await ApiService.retrieveSimulationRequestList(simulationCountry.simulationId, {
      resourceTypeId: selectedCategoryId,
      tradable: true,
      migratable: false,
      resourceId: 0,
    });
    data.resultList.forEach(request => {
      request.resourceNameLocalized = resourceList.find(r => r.resourceId === request.resourceId).resourceNameLocalized;
    });
    data.resultList.sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized));
    setRequests(data.resultList);
  };

  const handleAddOffer = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsAddOfferModalOpen(false);
  };

  const handleOpenManageOffer = selectedOffer => {
    setOffer(selectedOffer);
    setIsManageOfferModalOpen(true);
  };

  const handleUpdateOffer = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsManageOfferModalOpen(false);
  };

  const handleOpenPurchaseProduct = selectedOffer => {
    setOffer(selectedOffer);
    setIsPurchaseProductModalOpen(true);
  };

  const handlePurchaseProduct = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsPurchaseProductModalOpen(false);
  };

  const handleAddRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsAddRequestModalOpen(false);
  };

  const handleOpenManageRequest = selectedRequest => {
    setRequest(selectedRequest);
    setIsManageRequestModalOpen(true);
  };

  const handleUpdateRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsManageRequestModalOpen(false);
  };

  const handleOpenFulfillRequest = selectedRequest => {
    setRequest(selectedRequest);
    setIsFulfillRequestModalOpen(true);
  };

  const handleFulfillRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsFulfillRequestModalOpen(false);
  };

  const OFFER_TABLE_COLUMNS = useMemo(
    () =>
      getOfferTableColumns(
        +params.simulationCountryId,
        handleOpenManageOffer,
        handleOpenPurchaseProduct,
        setSelectedResourceId,
      ),
    // eslint-disable-next-line
    [params.simulationCountryId],
  );
  const REQUEST_TABLE_COLUMNS = useMemo(
    () =>
      getRequestTableColumns(
        +params.simulationCountryId,
        handleOpenManageRequest,
        handleOpenFulfillRequest,
        setSelectedResourceId,
      ),
    // eslint-disable-next-line
    [params.simulationCountryId],
  );

  useEffect(() => {
    if (simulationCountry) {
      fetchOffers();
      fetchRequests();
    }
    // eslint-disable-next-line
  }, [simulationCountry, selectedCategoryId]);

  const offerActionButtons = [
    <Button
      style={{ width: 100 }}
      type="primary"
      size="small"
      onClick={() => setIsAddOfferModalOpen(true)}
      key="add-offer"
    >
      Add Offer
    </Button>,
  ];

  const requestActionButtons = [
    <Button
      style={{ width: 100 }}
      type="primary"
      size="small"
      onClick={() => setIsAddRequestModalOpen(true)}
      key="add-request"
    >
      Add Request
    </Button>,
  ];

  return (
    <>
      <div className="d-flex justify-space-between align-center">
        <Select
          style={{ width: 210 }}
          options={categoryList}
          value={selectedCategoryId}
          onSelect={e => setSelectedCategoryId(e)}
        />
        <Button style={{ width: 100 }} type="primary" onClick={refresh}>
          Refresh
        </Button>
      </div>
      <Card size="small" style={{ marginTop: 16 }} title="Items Offered" extra={offerActionButtons}>
        <p className="mt-0">
          Items offered in trade must be tradeable and new with a full complement of annual and lifetime uses. They are
          immediately deducted from the offering country and held in escrow until purchased, at which time you receive
          the gold and the other country receives the product.
        </p>
        <Table
          size="small"
          pagination={false}
          rowKey="offerId"
          scroll={{ y: 'calc(50vh - 275px)' }}
          columns={OFFER_TABLE_COLUMNS}
          dataSource={offers}
        />
      </Card>
      <Card size="small" style={{ marginTop: 16 }} title="Items Requested" extra={requestActionButtons}>
        <p className="mt-0">
          Gold for requested items is immediately deducted from the requesting country and held in escrow until
          fulfilled, at which time you receive the product and the other country receives the gold.
        </p>
        <Table
          size="small"
          pagination={false}
          rowKey="requestId"
          scroll={{ y: 'calc(50vh - 275px)' }}
          columns={REQUEST_TABLE_COLUMNS}
          dataSource={requests}
        />
      </Card>
      <AddOfferModal
        isOpen={isAddOfferModalOpen}
        simulationCountry={simulationCountry}
        selectedCategoryId={selectedCategoryId}
        onSave={handleAddOffer}
        onClose={() => setIsAddOfferModalOpen(false)}
      />
      <ManageOfferModal
        isOpen={isManageOfferModalOpen}
        simulationCountry={simulationCountry}
        offer={offer}
        onSave={handleUpdateOffer}
        onClose={() => setIsManageOfferModalOpen(false)}
      />
      <PurchaseProductModal
        isOpen={isPurchaseProductModalOpen}
        simulationCountry={simulationCountry}
        offer={offer}
        onSave={handlePurchaseProduct}
        onClose={() => setIsPurchaseProductModalOpen(false)}
      />
      <AddRequestModal
        isOpen={isAddRequestModalOpen}
        simulationCountry={simulationCountry}
        selectedCategoryId={selectedCategoryId}
        onSave={handleAddRequest}
        onClose={() => setIsAddRequestModalOpen(false)}
      />
      <ManageRequestModal
        isOpen={isManageRequestModalOpen}
        simulationCountry={simulationCountry}
        request={request}
        onSave={handleUpdateRequest}
        onClose={() => setIsManageRequestModalOpen(false)}
      />
      <FulfillRequestModal
        isOpen={isFulfillRequestModalOpen}
        request={request}
        simulationCountry={simulationCountry}
        onSave={handleFulfillRequest}
        onClose={() => setIsFulfillRequestModalOpen(false)}
      />
    </>
  );
};
