import styled from 'styled-components';
import { Checkbox, Tooltip } from 'antd';
import { useSimulationContext } from '../../../../contexts/SimulationContext';

const SystemResourceIds = [15, 50, 65, 109, 111, 112, 133];

const tooltips = {
  24: 'Ecologist: Each use prevents the pollution shown in the production output box above.',
  26: 'Fertilizer & Pesticide: 1 unit: increase output by 50 units; increase Pollution by 5 units.',
  49: 'Pollution Control Device: Prevents Pollution. Uses remaining must be greater than or equal to production pollution above',
  50: 'Power System: Doubles output; doubles Pollution; may be used with other Optional Inputs except other Systems. Repeat Procedure below consumes only one use.',
  65: 'Transportation System: Doubles output; doubles Pollution; may be used with other Optional Inputs except other Systems. Repeat Procedure below consumes only one use.',
  133: 'Information System: Doubles output; doubles Pollution; may be used with other Optional Inputs except other Systems. Repeat Procedure below consumes only one use.',
  15: 'Complete Communications System: Quadruples output; quadruples Pollution; may be used with other Optional Inputs except other Systems. Repeat Procedure below consumes only one use.',
};

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 2px solid #1677ff;
  }
`;

export const OptionalFactor = ({ optionalFactor, index, repeatCount, disabled, checked, onChange }) => {
  const { setSelectedResourceId } = useSimulationContext();

  const isSystemResource = SystemResourceIds.includes(optionalFactor.ResourceIdUsed);
  let usesRequired = isSystemResource ? optionalFactor.UsesRequired : optionalFactor.UsesRequired * repeatCount;
  usesRequired = checked ? usesRequired : 0;
  const remainingUses = Math.max(optionalFactor.currentSimulationYearRemainingUses - usesRequired, 0);

  const handleChange = e => {
    onChange(index, e.target.checked);
  };

  return (
    <div className="d-flex align-center mb-4">
      <Tooltip title={tooltips[optionalFactor.ResourceIdUsed]}>
        <div className="resource-link" onClick={() => setSelectedResourceId(optionalFactor.ResourceIdUsed)}>
          <img width={24} src={optionalFactor.iconUrl} alt={optionalFactor.resourceNameLocalized} />
          <div className="ml-4">{optionalFactor.resourceNameLocalized}</div>
        </div>
      </Tooltip>
      <div className="flex-1 mr-8 text-right">{remainingUses} Uses Remaining This Year</div>
      <StyledCheckbox disabled={disabled} checked={checked} onChange={handleChange} />
    </div>
  );
};
