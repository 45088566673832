import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'antd';
import Swal from 'sweetalert2';
import { SIMULATION_COUNTRY_STATES } from '../../../data';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';
import { Page } from './styles';

export const CountrySummary = () => {
  const params = useParams();
  const navigate = useNavigate();
  const countryList = DataService.getItem('countryList');
  const [simulationCountry, setSimulationCountry] = useState(null);

  const fetchCountry = async () => {
    const data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    if (!data.success) {
      navigate('/portal/welcome');
    }
    setSimulationCountry(data.result);
  };

  const handleEnterCountry = () => {
    if (simulationCountry.simulationYearArrayLength === 0) {
      Swal.fire({
        title: 'No New Year Available',
        icon: 'info',
        text: 'Please Contact Your Teacher.',
      });
      return;
    }
    if (simulationCountry.simulationCountryState === SIMULATION_COUNTRY_STATES.NEW) {
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/welcome`);
    } else if (simulationCountry.simulationCountryState === SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_ANOTHER) {
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/military?war=true`);
    } else if (simulationCountry.simulationCountryState === SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_BY_ANOTHER) {
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/military?war=true`);
    } else {
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/national-briefing`);
    }
  };

  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line
  }, [params.simulationCountryId]);

  if (!simulationCountry) {
    return null;
  }

  const country = countryList.find(c => c.countryId === simulationCountry.countryId);

  return (
    <Page>
      <Card>
        <h2>{simulationCountry.countryName}</h2>
        <hr />
        <div className="year">
          <img src={`/images/flags/${country.countryCode}.png`} alt=""></img>
          <span>Year {simulationCountry.simulationCountryYear}</span>
        </div>
        <hr />
        <img className="img-earth" src="/images/pages/portal/country-summary/earth.png" alt="" />
        <div></div>
        <Button type="primary" onClick={handleEnterCountry}>
          Enter Country
        </Button>
      </Card>
    </Page>
  );
};
