import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { CLASS_SIMULATIONS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { ClassSimulationModal } from './ClassSimulationModal';

export const ClassSimulations = () => {
  const { classId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);
  const [classSimulations, setClassSimulations] = useState([]);
  const [simulation, setSimulation] = useState(false);

  const fetchClass = async () => {
    try {
      const data = await ApiService.retrieveClass(classId);
      if (data.success) {
        setCurrentClass(data.result);
        setBreadcrumbItems([
          {
            title: 'Classes',
            path: '/main/classes',
          },
          {
            title: data.result.className,
            path: `/main/classes/${data.result.classId}`,
          },
          {
            title: 'Simulations',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchClassSimulations = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveClassSimulationList(classId);
      if (data.success) {
        setClassSimulations(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddSimulation = () => {
    setSimulation({});
  };

  const handleCloseSimulationModal = () => {
    setSimulation(null);
  };

  const handleSimulationSaved = () => {
    setSimulation(null);
    fetchClassSimulations();
  };

  const handleDeleteSimulation = async simulation => {
    try {
      await ApiService.deleteSimulation(simulation.simulationId);
      await fetchClassSimulations();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchClass();
    fetchClassSimulations();
    // eslint-disable-next-line
  }, []);

  const classSimulationsTableColumns = [
    ...CLASS_SIMULATIONS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this simulation?" onConfirm={() => handleDeleteSimulation(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddSimulation}>
        Add Simulation
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Simulations" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={classSimulationsTableColumns}
          dataSource={classSimulations}
          rowKey="simulationId"
          bordered
          head
        />
      </Card>
      <ClassSimulationModal
        currentClass={currentClass}
        simulation={simulation}
        onClose={handleCloseSimulationModal}
        onSave={handleSimulationSaved}
      />
    </>
  );
};
