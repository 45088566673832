export const SIMULATIONS_TABLE_COLUMNS = [
  {
    id: 'simulationName',
    title: 'Simulation Name',
    dataIndex: 'simulationName',
    sorter: (a, b) => a.simulationName.localeCompare(b.simulationName),
  },
  {
    id: 'className',
    title: 'Class Name',
    dataIndex: 'className',
    sorter: (a, b) => a.className.localeCompare(b.className),
  },
  {
    id: 'schoolName',
    title: 'School Name',
    dataIndex: 'schoolName',
    sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
  },
  {
    id: 'simulationYearIndex',
    title: 'Current Year',
    dataIndex: 'simulationYearIndex',
    sorter: (a, b) => (a.simulationYearIndex > b.simulationYearIndex ? 1 : -1),
  },
  {
    id: 'simulationYearArrayLength',
    title: 'Total Years',
    dataIndex: 'simulationYearArrayLength',
    sorter: (a, b) => (a.simulationYearArrayLength > b.simulationYearArrayLength ? 1 : -1),
  },
];
