import { Link } from 'react-router-dom';
import { DotChartOutlined, IdcardOutlined, HomeOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons';
import SessionService from '../../services/SessionService';

export const DATA_LIST = [
  {
    title: 'Languages',
    source: 'retrieveLanguageList',
    key: 'languageList',
  },
  {
    title: 'Regions',
    source: 'retrieveRegionList',
    key: 'regionList',
  },
  {
    title: 'Countries',
    source: 'retrieveCountryList',
    key: 'countryList',
  },
  {
    title: 'States',
    source: 'retrieveStateList',
    key: 'stateList',
    payload: { countryId: 188 },
  },
  {
    title: 'School Levels',
    source: 'retrieveLevelList',
    key: 'schoolLevelList',
  },
  {
    title: 'School Sizes',
    source: 'retrieveSizeList',
    key: 'schoolSizeList',
  },
  {
    title: 'Class Terms',
    source: 'retrieveTermList',
    key: 'classTermList',
  },
  {
    title: 'Class Grades',
    source: 'retrieveGradeList',
    key: 'classGradeList',
  },
  {
    title: 'Resource Types',
    source: 'retrieveResourceTypeList',
    key: 'resourceTypeList',
  },
  {
    title: 'Resources',
    source: 'retrieveResourceList',
    key: 'resourceList',
  },
  {
    title: 'Goal Types',
    source: 'retrieveGoalTypeList',
    key: 'goalTypeList',
  },
  {
    title: 'Account Roles',
    source: 'retrieveAccountRoleList',
    key: 'accountRoleList',
  },
];

export const SIDER_MENU_ITEMS = [
  {
    key: 'my-profile',
    path: '/main/my-profile',
    icon: <IdcardOutlined />,
    label: <Link to="/main/my-profile">My Profile</Link>,
    breadcrumbs: ['My Profile'],
  },
  {
    key: 'schools',
    path: '/main/schools',
    icon: <HomeOutlined />,
    label: <Link to="/main/schools">Schools</Link>,
    breadcrumbs: ['Schools'],
  },
  {
    key: 'classes',
    path: '/main/classes',
    icon: <ReadOutlined />,
    label: <Link to="/main/classes">Classes</Link>,
    breadcrumbs: ['Classes'],
  },
  {
    key: 'simulations',
    path: '/main/simulations',
    icon: <DotChartOutlined />,
    label: <Link to="/main/simulations">Simulations</Link>,
    breadcrumbs: ['Simulations'],
  },
  {
    key: 'members',
    path: '/main/members',
    icon: <TeamOutlined />,
    label: <Link to="/main/members">Members</Link>,
    breadcrumbs: ['Members'],
  },
];

export const getDropdownMenuItems = () => {
  const username = SessionService.getUsername();

  return [
    {
      key: 'username',
      label: <label style={{ color: '#bfbfbf' }}>{username}</label>,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: 'Log Out',
    },
  ];
};
