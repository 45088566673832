import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Input, List, Row } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import { MemberModal } from '../Members/MemberModal';
import { ChangePasswordModal } from './ChangePasswordModal';

export const MemberDetails = () => {
  const { setBreadcrumbItems } = useContext(MainContext);
  const { memberId } = useParams();
  const [member, setMember] = useState(null);
  const [currentMember, setCurrentMember] = useState(null);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [memberRoles, setMemberRoles] = useState([]);

  const memberSchools = memberRoles
    .filter(mr => mr.roleName.includes('School'))
    .map((mr, index) => `${index + 1}) ${mr.referenceName} (${mr.roleName.replace(' of School', '')})`);
  const memberClasses = memberRoles
    .filter(mr => mr.roleName.includes('Class'))
    .map((mr, index) => `${index + 1}) ${mr.referenceName} (${mr.roleName.replace(' of Class', '')})`);
  const memberSimulations = memberRoles
    .filter(mr => mr.roleName.includes('Simulation'))
    .map((mr, index) => `${index + 1}) ${mr.referenceName} (${mr.roleName.replace(' of Simulation', '')})`);

  const fetchMember = async () => {
    try {
      const data = await ApiService.retrieveMember(memberId);
      if (data.success) {
        setMember(data.result);
        setBreadcrumbItems([
          {
            title: 'Members',
            path: '/main/members',
          },
          {
            title: `${data.result.firstName} ${data.result.lastName}`,
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMemberRoleList = async () => {
    const data = await ApiService.retrieveMemberRoleList(memberId);
    setMemberRoles(data.resultList);
  };

  const handleEditMember = () => {
    setCurrentMember({ ...member });
  };

  const handleCloseMemberModal = () => {
    setCurrentMember(null);
  };

  const handleMemberSaved = () => {
    handleCloseMemberModal();
    fetchMember();
  };

  const handleChangePassword = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleEditMember}>
        Edit Member
      </Button>
      <Button size="small" type="primary" onClick={handleChangePassword}>
        Change Password
      </Button>
    </>
  );

  useEffect(() => {
    fetchMember();
    fetchMemberRoleList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card size="small" title="Manage Member" extra={ActionBar}>
        <Form labelCol={{ span: 2 }} disabled>
          <Form.Item label="Username">
            <Input value={member?.userName} />
          </Form.Item>
          <Form.Item label="First Name">
            <Input value={member?.firstName} />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input value={member?.lastName} />
          </Form.Item>
          <Form.Item label="Email">
            <Input value={member?.email} />
          </Form.Item>
          <Form.Item label="Gender">
            <Input value={member?.gender} />
          </Form.Item>
          <Form.Item label="Language">
            <Input value={member?.primaryLanguageName} />
          </Form.Item>
          <Form.Item label="Home Country">
            <Input value={member?.countryName} />
          </Form.Item>
          <Form.Item label="Year of Birth">
            <Input value={member?.yearOfBirth} />
          </Form.Item>
        </Form>
      </Card>
      <Row style={{ marginTop: 24 }} gutter={24}>
        <Col span={8}>
          <List
            size="small"
            header={<b>Member Schools</b>}
            bordered
            dataSource={memberSchools}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
        <Col span={8}>
          <List
            size="small"
            header={<b>Member Classes</b>}
            bordered
            dataSource={memberClasses}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
        <Col span={8}>
          <List
            size="small"
            header={<b>Member Simulations</b>}
            bordered
            dataSource={memberSimulations}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
      </Row>
      <MemberModal member={currentMember} onClose={handleCloseMemberModal} onSave={handleMemberSaved} />
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        userId={member?.userId}
        username={member?.userName}
        onClose={handleCloseChangePasswordModal}
        onSave={handleCloseChangePasswordModal}
      />
    </>
  );
};
