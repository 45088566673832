import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal, Select } from 'antd';
import _ from 'lodash';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';

export const SchoolClassModal = ({ school, currentClass, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const yearList = _.range(2000, new Date().getFullYear() + 2)
    .map(year => ({ label: year, value: year }))
    .reverse();
  const classTermList = DataService.getItem('classTermList');
  const classGradeList = DataService.getItem('classGradeList');

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.schoolId = school.schoolId;
      payload.gradeLevel = payload.gradeId;
      delete payload.gradeId;
      delete payload.schoolName;
      await ApiService.addClass(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      schoolName: school?.schoolName,
    });
  }, [form, school, currentClass]);

  return (
    <Modal
      title="Add Class"
      open={!!currentClass}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={currentClass}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item label="School" name="schoolName">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name="className"
          rules={[
            {
              required: true,
              message: 'Class name is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Year"
          name="year"
          rules={[
            {
              required: true,
              message: 'Year is required!',
            },
          ]}
        >
          <Select placeholder="Select year" options={yearList} />
        </Form.Item>
        <Form.Item
          label="Term"
          name="termId"
          rules={[
            {
              required: true,
              message: 'Term is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a class term"
            optionFilterProp="termName"
            fieldNames={{ label: 'termName', value: 'termId' }}
            options={classTermList}
          />
        </Form.Item>
        <Form.Item
          label="Section"
          name="section"
          rules={[
            {
              required: true,
              message: 'Section is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Grade"
          name="gradeId"
          rules={[
            {
              required: true,
              message: 'Grade is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a class grade"
            optionFilterProp="gradeName"
            fieldNames={{ label: 'gradeName', value: 'gradeId' }}
            options={classGradeList}
          />
        </Form.Item>
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: 'Subject is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
