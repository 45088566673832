import SessionService from '../../services/SessionService';

export const DATA_LIST = [
  {
    title: 'Languages',
    source: 'retrieveLanguageList',
    key: 'languageList',
  },
  {
    title: 'Regions',
    source: 'retrieveRegionList',
    key: 'regionList',
  },
  {
    title: 'Countries',
    source: 'retrieveCountryList',
    key: 'countryList',
  },
  {
    title: 'States',
    source: 'retrieveStateList',
    key: 'stateList',
    payload: { countryId: 188 },
  },
  {
    title: 'School Levels',
    source: 'retrieveLevelList',
    key: 'schoolLevelList',
  },
  {
    title: 'School Sizes',
    source: 'retrieveSizeList',
    key: 'schoolSizeList',
  },
  {
    title: 'Class Terms',
    source: 'retrieveTermList',
    key: 'classTermList',
  },
  {
    title: 'Class Grades',
    source: 'retrieveGradeList',
    key: 'classGradeList',
  },
  {
    title: 'Resource Types',
    source: 'retrieveResourceTypeList',
    key: 'resourceTypeList',
  },
  {
    title: 'Resources',
    source: 'retrieveResourceList',
    key: 'resourceList',
  },
  {
    title: 'Recipes',
    source: 'retrieveRecipeList',
    key: 'recipeList',
  },
  {
    title: 'Goal Types',
    source: 'retrieveGoalTypeList',
    key: 'goalTypeList',
  },
  {
    title: 'Account Roles',
    source: 'retrieveAccountRoleList',
    key: 'accountRoleList',
  },
];

export const SIDER_MENU_ITEMS = [
  {
    key: 'world-briefing',
    icon: <img width={32} src="/images/layouts/simulation/world.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/world-briefing',
    label: 'World Briefing',
  },
  {
    key: 'national-briefing',
    icon: <img width={32} src="/images/layouts/simulation/nation.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/national-briefing',
    label: <span className="text-black">National Briefing</span>,
  },
  {
    key: 'goals',
    icon: <img width={32} src="/images/layouts/simulation/goals.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/goals',
    label: 'Goals',
  },
  {
    key: 'production',
    icon: <img width={32} src="/images/layouts/simulation/production.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/production',
    label: 'Production',
  },
  {
    key: 'trade',
    icon: <img width={32} src="/images/layouts/simulation/trade.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/trade',
    label: <span className="text-black">Trade</span>,
  },
  {
    key: 'population',
    icon: <img width={32} src="/images/layouts/simulation/population.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/population',
    label: <span className="text-black">Population</span>,
  },
  {
    key: 'military',
    icon: <img width={32} src="/images/layouts/simulation/military.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/military',
    label: <span className="text-black">Military</span>,
  },
  {
    key: 'feed-the-people',
    icon: <img width={32} src="/images/layouts/simulation/feed.png" alt="" />,
    path: '/portal/simulations/:simulationCountryId/feed-the-people',
    label: 'Feed The People',
  },
];

export const getDropdownMenuItems = () => {
  const username = SessionService.getUsername();

  return [
    {
      key: 'username',
      label: <label style={{ color: '#bfbfbf' }}>{username}</label>,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: 'Log Out',
    },
  ];
};
