import styled from 'styled-components';
import { Breadcrumb, Layout } from 'antd';

const { Content, Header, Sider } = Layout;

const bgColor = '#0f131a';

export const MainLayout = styled(Layout)({
  minHeight: '100vh',
});

export const StyledHeader = styled(Header)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSider = styled(Sider)`
  background: #fff !important;

  ul {
    box-sizing: content-box;
  }
`;

export const ContentLayout = styled(Layout)({
  padding: 24,
  background: '#fff',
});

export const StyledContent = styled(Content)({});

export const StyledBreadcrumb = styled(Breadcrumb)({});

export const Logo = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #fff;
`;

export const DataLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: ${bgColor};

  .ant-progress-status-normal .ant-progress-text {
    color: #fff;
  }

  h4 {
    color: #fff;
  }
`;
