import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { CreditsModal } from '../../components/CreditsModal';
import { HelpModal } from '../../components/HelpModal';
import { NotesModal } from '../../components/NotesModal';
import { SIMULATION_COUNTRY_STATES } from '../../data';
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
import SessionService from '../../services/SessionService';
import { DATA_LIST, SIDER_MENU_ITEMS } from './data';
import { ContentLayout, MainLayout, StyledContent, StyledSider } from './styles';
import { AppHeader } from './AppHeader';
import { DataLoader } from './DataLoader';

export const Portal = () => {
  const username = SessionService.getUsername();
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [isCreditsModalOpen, setCreditsModalOpen] = useState(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [isDataLoaded, setDataLoaded] = useState(!!DataService.getItem(DATA_LIST[DATA_LIST.length - 1].key));
  const [siderMenuItems, setSiderMenuItems] = useState(SIDER_MENU_ITEMS);

  const handleCompleteDataLoading = () => {
    setDataLoaded(true);
  };

  const fetchSimulationCountries = async () => {
    const data = await ApiService.retrieveAccountSimulationCountryList();

    siderMenuItems[1].children = data.resultList
      .filter(sc => ![SIMULATION_COUNTRY_STATES.INACTIVE].includes(sc.simulationCountryState))
      .map(sc => ({
        key: `countries-${sc.simulationCountryId}`,
        label: (
          <Link className="country" to={`/portal/countries/${sc.simulationCountryId}/summary`}>
            <h3>{sc.countryName}</h3>
            <div>Simulation: {sc.simulationName}</div>
            <div>Language: {sc.primaryLanguageName}</div>
            <div>
              Year: {sc.simulationCountryYear} of {sc.simulationYearArrayLength}
            </div>
          </Link>
        ),
      }));

    setSiderMenuItems([...siderMenuItems]);
  };

  useEffect(() => {
    fetchSimulationCountries();
    // eslint-disable-next-line
  }, []);

  if (!isDataLoaded) {
    return <DataLoader onComplete={handleCompleteDataLoading} />;
  }

  const headerMenuItems = [
    {
      key: 'help',
      label: <Link onClick={() => setHelpModalOpen(true)}>How to Play</Link>,
    },
    {
      key: 'credits',
      label: <Link onClick={() => setCreditsModalOpen(true)}>Credits</Link>,
    },
    {
      key: 'notes',
      label: <Link onClick={() => setNotesModalOpen(true)}>Notes</Link>,
    },
  ];

  return (
    <MainLayout>
      <AppHeader initial={username[0]} headerMenuItems={headerMenuItems} />
      <Layout>
        <StyledSider width={320}>
          <Menu mode="inline" items={siderMenuItems} defaultOpenKeys={['manage', 'play']} />
        </StyledSider>
        <ContentLayout>
          <StyledContent>
            <Outlet />
          </StyledContent>
        </ContentLayout>
      </Layout>
      <HelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
      <CreditsModal open={isCreditsModalOpen} onClose={() => setCreditsModalOpen(false)} />
      <NotesModal open={isNotesModalOpen} onClose={() => setNotesModalOpen(false)} />
    </MainLayout>
  );
};
