import { useCallback, useEffect, useState } from 'react';
import { Progress } from 'antd';
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
import { DATA_LIST } from './data';
import { DataLoaderContainer } from './styles';

export const DataLoader = ({ onComplete }) => {
  const [percent, setPercent] = useState(0);
  const [loadingDataTitle, setLoadingDataTitle] = useState('');

  const loadData = useCallback(async () => {
    for (let i = 0; i < DATA_LIST.length; i += 1) {
      const { title, source, key, payload } = DATA_LIST[i];
      const data = await ApiService[source](payload);
      if (key === 'countryList') {
        DataService.setItem(
          key,
          data.resultList.filter(country => !!country.countryName),
        );
      } else {
        DataService.setItem(key, data.resultList);
      }
      setPercent(Math.floor((100 * (i + 1)) / DATA_LIST.length));
      setLoadingDataTitle(title);
    }
    onComplete();
  }, [onComplete, setPercent, setLoadingDataTitle]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <DataLoaderContainer>
      <Progress type="circle" percent={percent} />
      <h4>Loading {loadingDataTitle}...</h4>
    </DataLoaderContainer>
  );
};
