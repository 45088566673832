import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { notification, Button, Card, Dropdown, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { CLASSES_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { ClassModal } from './ClassModal';
import Utils from '../../../utils';

export const Classes = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [currentClass, setCurrentClass] = useState(null);

  const fetchSchools = async () => {
    try {
      const data = await ApiService.retrieveSchoolList();
      if (data.success) {
        setSchools(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveClassList();
      if (data.success) {
        setClasses(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddClass = () => {
    setCurrentClass({});
  };

  const handleEditClass = currentClass => {
    setCurrentClass(currentClass);
  };

  const handleCloseClassModal = () => {
    setCurrentClass(null);
  };

  const handleClassSaved = () => {
    setCurrentClass(null);
    fetchClasses();
  };

  const handleDeleteClass = async currentClass => {
    try {
      await ApiService.deleteClass(currentClass.classId);
      await fetchClasses();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSchools();
    fetchClasses();
  }, []);

  const getMenuItems = classData => {
    const menuItems = [
      {
        key: 'manage-simulations',
        label: <NavLink to={`/main/classes/${classData.classId}/simulations`}>Manage Simulations</NavLink>,
      },
      {
        key: 'manage-students',
        label: <NavLink to={`/main/classes/${classData.classId}/students`}>Manage Students</NavLink>,
      },
      {
        key: 'manage-teachers',
        label: <NavLink to={`/main/classes/${classData.classId}/teachers`}>Manage Teachers</NavLink>,
      },
    ];
    return menuItems;
  };

  const classesTableColumns = [
    ...CLASSES_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          {Utils.checkPermissions('Admin of School', row.schoolId) && (
            <Button size="small" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditClass(row)} />
          )}
          {Utils.checkPermissions('Admin of School', row.schoolId) && (
            <Popconfirm title="Are you sure to delete this class?" onConfirm={() => handleDeleteClass(row)}>
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          <Dropdown menu={{ items: getMenuItems(row) }} trigger={['click']}>
            <Button size="small" icon={<MoreOutlined />} />
          </Dropdown>
        </ActionColumn>
      ),
    },
  ];
  classesTableColumns[0].render = (_, row) => <NavLink to={`/main/classes/${row.classId}`}>{row.className}</NavLink>;

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddClass}>
        Add Class
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Classes" extra={Utils.checkPermissions('Admin of School') ? ActionBar : null}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={classesTableColumns}
          dataSource={classes}
          rowKey="classId"
          bordered
          head
        />
      </Card>
      <ClassModal
        schools={schools}
        currentClass={currentClass}
        onClose={handleCloseClassModal}
        onSave={handleClassSaved}
      />
    </>
  );
};
