import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';

const getTradeTableColumns = setSelectedResourceId => {
  const countryList = DataService.getItem('countryList');
  const resourceTypeList = DataService.getItem('resourceTypeList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'year',
      title: 'Year',
      dataIndex: 'simulationYearIndex',
    },
    {
      id: 'category',
      title: 'Category',
      render: row => {
        const resourceType = resourceTypeList.find(rt => rt.resourceTypeId === row.resourceTypeId);
        const iconName = resourceType.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resource-types/${iconName}`;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img width={20} src={iconUrl} alt={resourceType.resourceTypeNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resourceType.resourceTypeNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'product',
      title: 'Product',
      render: row => {
        const resource = resourceList.find(r => r.resourceId === row.resourceId);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resource.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'seller',
      title: 'Seller',
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdSeller);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'buyer',
      title: 'Buyer',
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdBuyer);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.buyAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'quantityOffered',
      title: 'Quantity Offered',
      render: row => row.quantity.toFixed(0),
    },
    {
      id: 'quantityPurchased',
      title: 'Quantity Purchased',
      render: row => row.responseQuantity.toFixed(0),
    },
    {
      id: 'unitPrice',
      title: 'Price Per Unit',
      render: row => row.unitPrice.toFixed(2),
    },
    {
      id: 'totalPrice',
      title: 'Total Price',
      render: row => row.price.toFixed(2),
    },
  ];

  return columns;
};

export const History = () => {
  const params = useParams();
  const { setSelectedResourceId } = useSimulationContext();
  const [trades, setTrades] = useState([]);

  const TRADE_TABLE_COLUMNS = useMemo(() => getTradeTableColumns(setSelectedResourceId), [setSelectedResourceId]);

  const loadData = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    data = await ApiService.retrieveSimulationTradeList(data.result.simulationId, {
      migratable: false,
      tradable: true,
    });
    data = data.resultList ?? [];
    data.forEach((item, index) => (item.id = index));
    setTrades(data);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Table size="small" pagination={false} rowKey="id" columns={TRADE_TABLE_COLUMNS} dataSource={trades} />
    </>
  );
};
