import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { HighchartsChart, HighchartsProvider, PieSeries, Tooltip, YAxis } from 'react-jsx-highcharts';
import { Col, Row, Select, Table } from 'antd';
import Highcharts from 'highcharts';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';

const RightPanel = styled.div`
  .action-bar {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    margin-top: 24px;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 2px solid #1677ff !important;
  }
`;

export const Resources = () => {
  const params = useParams();
  const { setSelectedResourceId } = useSimulationContext();
  const resourceList = DataService.getItem('resourceList');
  const resourceTypeList = DataService.getItem('resourceTypeList');
  const [scResources, setSCResources] = useState([]);
  const [selectedResourceTypeId1, setSelectedResourceTypeId1] = useState(-1);
  const [selectedResourceTypeId2, setSelectedResourceTypeId2] = useState(resourceTypeList[0].resourceTypeId);

  const resourceTypeList1 = [
    {
      resourceTypeNameLocalized: 'All',
      resourceTypeId: -1,
    },
    ...resourceTypeList,
  ];
  const resourceTypeList2 = resourceTypeList;

  const filteredResources1 =
    selectedResourceTypeId1 === -1
      ? scResources
      : scResources.filter(r => r.resourceTypeId === selectedResourceTypeId1);
  const selectedResourceType2 = resourceTypeList.find(rt => rt.resourceTypeId === selectedResourceTypeId2);
  const filteredResources2 = scResources
    .filter(r => r.resourceTypeId === selectedResourceTypeId2 && r.qty > 0)
    .map(r => ({
      name: r.resourceNameLocalized,
      y: r.qty,
    }));

  const loadData = async () => {
    const { result: sc } = await ApiService.retrieveSimulationCountry(params.simulationCountryId);

    const result = sc.simulationCountryResourceViewList.map(scResource => {
      const resource = resourceList.find(r => r.resourceId === scResource.resourceId);
      resource.qty = scResource.remainingQuantity;
      resource.annualUses = scResource.currentSimulationYearRemainingUses;
      resource.lifetimeUses = scResource.remainingUses;
      return resource;
    });

    result.sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized));

    setSCResources(result);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const RESOURCE_COLUMNS = [
    {
      id: 'name',
      title: 'Name',
      width: 320,
      render: row => {
        const iconName = row.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={28} src={iconUrl} alt={row.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{row.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'qty',
      title: 'Qty',
      align: 'center',
      render: row => (!row.qty ? null : row.qty.toLocaleString()),
    },
    {
      id: 'annualUses',
      title: 'Annual Uses',
      align: 'center',
      render: row => (!row.annualUses ? null : row.annualUses.toFixed(0)),
    },
    {
      id: 'lifetimeUses',
      title: 'Lifetime Uses',
      align: 'center',
      render: row => (!row.lifetimeUses ? null : row.lifetimeUses.toFixed(0)),
    },
  ];

  return (
    <Row gutter={4}>
      <Col span={12}>
        <div className="d-flex justify-space-between align-center mb-8">
          <p className="mt-0 mb-0">Resources & Products currently in your country</p>
          <StyledSelect
            style={{ width: 180 }}
            listHeight={500}
            options={resourceTypeList1}
            fieldNames={{ label: 'resourceTypeNameLocalized', value: 'resourceTypeId' }}
            value={selectedResourceTypeId1}
            onChange={e => setSelectedResourceTypeId1(e)}
          />
        </div>
        <Table
          bordered
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 250px)' }}
          rowKey="resourceId"
          columns={RESOURCE_COLUMNS}
          dataSource={filteredResources1}
        />
      </Col>
      <Col span={12}>
        <RightPanel>
          <div className="action-bar">
            <StyledSelect
              style={{ width: 180 }}
              listHeight={500}
              options={resourceTypeList2}
              fieldNames={{ label: 'resourceTypeNameLocalized', value: 'resourceTypeId' }}
              value={selectedResourceTypeId2}
              onChange={e => setSelectedResourceTypeId2(e)}
            />
          </div>
          <div className="content">
            {filteredResources2.length > 0 ? (
              <HighchartsProvider Highcharts={Highcharts}>
                <HighchartsChart polar>
                  <YAxis>
                    <Tooltip format="{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>" />
                    <PieSeries
                      name={selectedResourceType2.resourceTypeNameLocalized}
                      data={filteredResources2}
                      dataLabels={{ format: '<b>{point.name}</b>: {point.percentage:.2f} %' }}
                    />
                  </YAxis>
                </HighchartsChart>
              </HighchartsProvider>
            ) : (
              <p className="text-center">No resources in this category.</p>
            )}
          </div>
        </RightPanel>
      </Col>
    </Row>
  );
};
