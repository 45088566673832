import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';
import { GOALS_TABLE_COLUMNS } from './data';

export const SimulationCountryGoals = () => {
  const { simulationId, simulationCountryId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [goalsLocked, setGoalsLocked] = useState(false);
  const [goals, setGoals] = useState([]);

  const goalTypeList = DataService.getItem('goalTypeList');

  const fetchSimulation = async () => {
    setLoading(true);
    try {
      const { result: simulationData } = await ApiService.retrieveSimulation(simulationId);
      const { resultList: countryList } = await ApiService.retrieveSimulationCountryList(simulationId);
      const countryData = countryList.find(c => c.simulationCountryId === +simulationCountryId);
      const goalsData = goalTypeList.map((gt, index) => {
        const goal = countryData.simulationCountryGoalTypeViewList.find(g => +g.goalTypeId === gt.goalTypeId);
        return {
          index,
          rank: goal?.simulationCountryGoalTypeOrderIndex ?? 0,
          name: gt.goalTypeNameLocalized,
          description: gt.goalTypeDescriptionLocalized,
        };
      });
      if (goalsData.every(g => g.rank === 0)) {
        goalsData.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        goalsData.sort((a, b) => (a.rank > b.rank ? 1 : -1));
      }
      setGoalsLocked(countryData.goalsAreLocked);
      setGoals(goalsData);
      setBreadcrumbItems([
        {
          title: 'Simulations',
          path: '/main/simulations',
        },
        {
          title: simulationData.simulationName,
          path: `/main/simulations/${simulationId}`,
        },
        {
          title: 'Countries',
          path: `/main/simulations/${simulationId}/countries`,
        },
        {
          title: countryData.countryName,
          path: `/main/simulations/${simulationId}/countries/${simulationCountryId}`,
        },
        {
          title: 'Goals',
        },
      ]);
    } catch (e) {
      //
      console.log(e);
    }
    setLoading(false);
  };

  const handleToggleGoals = async () => {
    try {
      await ApiService.updateSimulationCountryGoalsLocked(simulationCountryId, { goalsAreLocked: !goalsLocked });
      await fetchSimulation();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    // eslint-disable-next-line
  }, []);

  const ActionBar = (
    <>
      <Popconfirm
        title={`Are you sure to ${goalsLocked ? 'unlock' : 'lock'} this country's goals?`}
        onConfirm={handleToggleGoals}
      >
        <Button size="small" type="primary">
          {goalsLocked ? 'Unlock' : 'Lock'}
        </Button>
      </Popconfirm>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Manage Goals" extra={ActionBar}>
        {goalsLocked ? (
          <p className="mt-0">Goals are locked right now - countries cannot change their goals when they are locked.</p>
        ) : (
          <p className="mt-0">
            Goals are unlocked right now - countries can change their goals when they are unlocked.
          </p>
        )}
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={GOALS_TABLE_COLUMNS}
          dataSource={goals}
          rowKey="index"
          bordered
          head
        />
      </Card>
    </>
  );
};
