import { Button } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';

export const Review = ({ onChangeStep }) => {
  const { simulationCountry } = useSimulationContext();

  const handleCheck = async () => {
    const { result: simulation } = await ApiService.retrieveSimulation(simulationCountry.simulationId);
    const simulationYears = simulation.simulationYearArray;
    const isNewYearAvailable =
      simulationCountry.simulationCountryYear < simulation.simulationYearIndex &&
      simulationYears.some(y => y.GoalWeightingType === 2);
    if (!isNewYearAvailable) {
      Swal.fire({
        title: 'No new year available',
        text: 'Please contact your teacher',
        icon: 'info',
      });
    } else {
      onChangeStep(5);
    }
  };

  return (
    <div className="d-flex flex-column align-center">
      <h3>You have reached the end of the playable years</h3>
      <p>Please review the national and world briefings</p>
      <img src="/images/pages/simulation/feed/bookmark.png" alt="Bookmark" />
      <p>You will be notified when a new year begins</p>
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} onClick={() => onChangeStep(3)}>
          Back
        </Button>
        <Button style={{ width: 200 }} className="ml-16" type="primary" onClick={handleCheck}>
          Check for New Year
        </Button>
      </div>
    </div>
  );
};
