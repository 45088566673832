import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';

const LABOR_TYPES = [
  'Dependent Population',
  'Doctor',
  'Ecologist',
  'Engineer',
  'Junior Military Officer',
  'Management Level 1',
  'Management Level 2',
  'Management Level 3',
  'Management Level 4',
  'Philanthropist',
  'Retired Population',
  'Semi-Skilled Labor',
  'Senior Military Officer',
  'Skilled Labor',
  'Soldier',
  'Teacher',
  'Unskilled Labor',
];

export const PopulationChanges = ({ onChangeStep }) => {
  const params = useParams();
  const { simulation, simulationCountry, fetchSimulationCountry } = useSimulationContext();
  const resourceList = DataService.getItem('resourceList');
  const [summary, setSummary] = useState(null);
  const [laborTypes, setLaborTypes] = useState([]);

  const isNewYearAvailable = useMemo(() => {
    if (!simulation || !simulationCountry) {
      return false;
    }
    const simulationYears = simulation.simulationYearArray;
    return (
      simulationCountry.simulationCountryYear < simulation.simulationYearIndex &&
      simulationYears.some(y => y.GoalWeightingType === 2)
    );
  }, [simulation, simulationCountry]);

  const getResourceDetails = (resourceName, txnList) => {
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    let txResources = txnList.filter(
      txn =>
        txn.resourceId === resource.resourceId &&
        txn.transactionQuantity < 0 &&
        txn.description !== 'Dependent population grew up.',
    );
    const deaths = txResources.reduce((total, txr) => total + txr.transactionQuantity, 0);

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;
    return {
      ...resource,
      iconUrl,
      deaths: Math.abs(deaths),
    };
  };

  const loadData = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    const population = data.result.population;

    data = await ApiService.retrieveSimulationCountryResourceTransactionListFiltered(
      params.simulationCountryId,
      data.result.simulationCountryYear,
      41,
    );

    const childBirthCount = data.resultList
      .filter(item => item.description === 'Child Birth')
      .reduce((total, item) => total + item.transactionQuantity, 0);
    const famineDeathCount = data.resultList
      .filter(item => item.description === 'Famine Death')
      .reduce((total, item) => total + item.transactionQuantity, 0);
    const randomDeathCount = data.resultList
      .filter(item => item.description === 'Random Death')
      .reduce((total, item) => total + item.transactionQuantity, 0);
    setSummary({
      childBirthCount,
      famineDeathCount: -famineDeathCount,
      randomDeathCount: -randomDeathCount,
      population,
    });

    const laborTypeList = LABOR_TYPES.map(laborType => getResourceDetails(laborType, data.resultList));
    setLaborTypes(laborTypeList);

    if (population === 0 || population === childBirthCount) {
      const result = await Swal.fire({
        title: 'All of your population has died!',
        icon: 'info',
        html: `
          <b>Reset all simulation country resources?</b>
          <br />
          <p>
            Your entire country has died. You may choose to continue
            with your current resources and try to get people to migrate
            to your country. Or you may reset back to the resources and
            population you had at the start of the simulation.
          </p>
        `,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continue With No Pop...',
        cancelButtonText: 'Reset Resources',
      });
      if (result.isConfirmed) {
        handleChangeStep();
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
        await ApiService.resetSimulationCountryResources(params.simulationCountryId);
        await fetchSimulationCountry();
        handleChangeStep();
      }
    }
  };

  const handleResetResources = async () => {
    const result = await Swal.fire({
      title: 'Reset all simulation country resources?',
      icon: 'question',
      html: `
        <p>
          Are you sure you want to reset to the resources and population you had at the start of the simulation?
          You will be going back to the beginning and lose everything you've produced, educated, or traded.
        </p>
      `,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (result.isConfirmed) {
      await ApiService.resetSimulationCountryResources(params.simulationCountryId);
      await fetchSimulationCountry();
      handleChangeStep();
    }
  };

  const handleChangeStep = () => {
    onChangeStep(isNewYearAvailable ? 5 : 4);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p>Population Changes</p>
      <p>
        Births are automatically determined based on your country's GDP per capita, relative to the world's GDP per
        capita, and your country's fertile population. You can reduce your fertile population by using the{' '}
        <b>Population</b> button on the dashboard and clicking the <b>Reproduction</b> tab. (<b>Dependent Population</b>{' '}
        and <b>Retired Population</b> are not fertile.) There may be random births in addition. All births are shown as{' '}
        <b>Dependent Population</b>
        in your country's statistics for one year after which they become <b>Unskilled Labor</b>.
      </p>
      <p>
        Deaths may occur from famine and/or randomly from natural causes. To prevent deaths from famine, feed your
        people and provide them with water. Random deaths are unavoidable. However, you can reduce the chance of random
        deaths by providing more medical uses, increasing your country's GDP per capita, limiting pollution (your own
        country's and the world's), and providing more <b>Potable Water</b>.
      </p>
      <p>
        To see your country's up-to-date population data, use the <b>National Briefing</b> button on the dashboard,
        click the <b>Resources</b> tab, and use the drop-down menu to filter by <b>Human Resources</b>.
      </p>
      <Row gutter={16}>
        <Col span={8}>
          <Card size="small">
            <div className="d-flex justify-space-between align-center">
              <div className="d-flex align-center">
                <img height={24} src="/images/layouts/simulation/stork.png" alt="Birth" />
                <div className="ml-8">
                  Number of New Births: <b>{summary?.childBirthCount ?? 0}</b>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small">
            <div className="d-flex justify-space-between align-center">
              <div className="d-flex align-center">
                <img height={24} src="/images/layouts/simulation/grave.png" alt="Famine Deaths" />
                <div className="ml-8">
                  Number of Famine Deaths: <b>{summary?.famineDeathCount ?? 0}</b>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small">
            <div className="d-flex justify-space-between align-center">
              <div className="d-flex align-center">
                <img height={24} src="/images/layouts/simulation/grave.png" alt="Random Deaths" />
                <div className="ml-8">
                  Number of Random Deaths: <b>{summary?.randomDeathCount ?? 0}</b>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Card className="mt-16" size="small" title="Deaths by Type">
        <Row gutter={16}>
          <Col span={12}>
            {laborTypes.slice(0, 9).map(laborType => (
              <Card
                className={`mb-8 ${laborType.deaths > 0 ? 'bg-error' : ''}`}
                size="small"
                key={`death-type-${laborType.resourceId}`}
              >
                <div className="d-flex justify-space-between align-center">
                  <div className="d-flex align-center">
                    <img width={16} src={laborType.iconUrl} alt={laborType.resourceNameLocalized} />
                    <div className="ml-8">{laborType.resourceNameLocalized}</div>
                  </div>
                  <div className={laborType.deaths > 0 ? 'text-error' : ''}>{laborType.deaths}</div>
                </div>
              </Card>
            ))}
          </Col>
          <Col span={12}>
            {laborTypes.slice(9).map(laborType => (
              <Card
                className={`mb-8 ${laborType.deaths > 0 ? 'bg-error' : ''}`}
                size="small"
                key={`death-type-${laborType.resourceId}`}
              >
                <div className="d-flex justify-space-between align-center">
                  <div className="d-flex align-center">
                    <img width={16} src={laborType.iconUrl} alt={laborType.resourceNameLocalized} />
                    <div className="ml-8">{laborType.resourceNameLocalized}</div>
                  </div>
                  <div className={laborType.deaths > 0 ? 'text-error' : ''}>{laborType.deaths}</div>
                </div>
              </Card>
            ))}
          </Col>
        </Row>
      </Card>
      <Card className="mt-16" size="small">
        <div className="d-flex justify-space-between align-center">
          <div className="d-flex align-center">
            <img height={36} src="/images/layouts/simulation/population.png" alt="Population" />
            <div className="ml-8">Updated Population</div>
          </div>
          <div>{summary?.population ?? 0}</div>
        </div>
      </Card>
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200, marginRight: 16 }} danger type="primary" onClick={() => handleResetResources()}>
          Reset Resources
        </Button>
        <Button style={{ width: 200 }} type="primary" onClick={() => handleChangeStep()}>
          Next
        </Button>
      </div>
    </>
  );
};
