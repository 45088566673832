export const SCHOOL_STUDENTS_TABLE_COLUMNS = [
  {
    id: 'lastName',
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    id: 'firstName',
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    id: 'userName',
    title: 'Username',
    dataIndex: 'userName',
  },
  {
    id: 'email',
    title: 'Email',
    dataIndex: 'email',
  },
];
