import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Col, InputNumber, Row } from 'antd';
import Swal from 'sweetalert2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';

const LABOR_USES = [
  'Doctor',
  'Ecologist',
  'Engineer',
  'Junior Military Officer',
  'Management Level 1',
  'Management Level 2',
  'Management Level 3',
  'Management Level 4',
  'Philanthropist',
  'Semi-Skilled Labor',
  'Senior Military Officer',
  'Skilled Labor',
  'Soldier',
  'Unskilled Labor',
  'Teacher',
];

const MEDICAL_USES = ['Clinic', 'Pharmacy', 'Doctor', 'Hospital'];

const getResourceList = (resourceList, scResources, list) => {
  if (!scResources) return [];

  const laborUsesResources = list.map(name => {
    const resource = resourceList.find(r => r.resourceNameLocalized === name);
    const scResource = scResources.find(scr => scr.resourceId === resource.resourceId);

    return {
      resourceId: resource.resourceId,
      resourceNameLocalized: resource.resourceNameLocalized,
      currentSimulationYearRemainingUses: scResource.currentSimulationYearRemainingUses,
      uses: 0,
    };
  });

  return laborUsesResources;
};

const Page = styled.div`
  .statistics-card .ant-card-body {
    padding: 4px 16px;
  }

  .designated-card {
    border: 2px solid #5b5b5b;
  }

  .medical-uses-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
`;

export const Reproduction = () => {
  const params = useParams();
  const resourceList = DataService.getItem('resourceList');
  const [simulationCountry, setSimulationCountry] = useState(null);
  const [laborUses, setLaborUses] = useState([]);
  const [medicalUses, setMedicalUses] = useState([]);

  const totalLaborRemainingUses = laborUses.reduce(
    (sum, laborUse) => sum + laborUse.currentSimulationYearRemainingUses,
    0,
  );
  const totalMedicalRemainingUses = medicalUses.reduce(
    (sum, medicalUse) => sum + medicalUse.currentSimulationYearRemainingUses,
    0,
  );
  const totalLaborUses = laborUses.reduce((sum, laborUse) => sum + laborUse.uses, 0);
  const totalMedicalUses = medicalUses.reduce((sum, medicalUse) => sum + medicalUse.uses, 0);

  const statistics = useMemo(() => {
    const result = {
      national: 0,
      fertile: 0,
      retired: 0,
      dependent: 0,
    };

    if (!simulationCountry) {
      return result;
    }

    result.national = simulationCountry.population;
    result.fertile = Math.round(simulationCountry.population * simulationCountry.fertility);

    const retiredResource = resourceList.find(r => r.resourceNameLocalized === 'Retired Population');
    const dependentResource = resourceList.find(r => r.resourceNameLocalized === 'Dependent Population');

    result.retired = simulationCountry.simulationCountryResourceViewList.find(
      scr => scr.resourceId === retiredResource.resourceId,
    ).remainingQuantity;
    result.dependent = simulationCountry.simulationCountryResourceViewList.find(
      scr => scr.resourceId === dependentResource.resourceId,
    ).remainingQuantity;

    return result;
    // eslint-disable-next-line
  }, [simulationCountry]);

  const loadData = async () => {
    const data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    setSimulationCountry(data.result);

    const scResources = data.result.simulationCountryResourceViewList;
    setLaborUses(getResourceList(resourceList, scResources, LABOR_USES));
    setMedicalUses(getResourceList(resourceList, scResources, MEDICAL_USES));
  };

  const handleChangeLaborUse = index => e => {
    const newLaborUses = [...laborUses];
    newLaborUses[index].uses = Math.floor(+e);
    setLaborUses(newLaborUses);
  };

  const handleChangeMedicalUse = index => e => {
    const newMedicalUses = [...medicalUses];
    newMedicalUses[index].uses = Math.floor(+e);
    setMedicalUses(newMedicalUses);
  };

  const handlePlan = async () => {
    const result = await Swal.fire({
      title: '<h6 style="margin: 0;">Please verify the family planning initiative detailed below.<h6>',
      icon: 'question',
      html: `
        <div style="display: flex; justify-content: center;">
          <div style="min-width: 240px;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <div style="margin-left: 4px;">Labor Uses:</div>
              </div>
              <div>${totalLaborUses}</div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <div style="margin-left: 4px;">Medical Uses:</div>
              </div>
              <div>${totalMedicalUses}</div>
            </div>
            <hr />
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <div style="margin-left: 4px;">Total Family Plans:</div>
              </div>
              <div>${totalMedicalUses}</div>
            </div>
          </div>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });
    if (result.isConfirmed) {
      const payload = {
        humanObjectListString: JSON.stringify(
          laborUses
            .filter(lu => lu.uses > 0)
            .map(lu => ({
              resourceId: lu.resourceId,
              uses: lu.uses,
            })),
        ),
        healthObjectListString: JSON.stringify(
          medicalUses
            .filter(mu => mu.uses > 0)
            .map(mu => ({
              resourceId: mu.resourceId,
              uses: mu.uses,
            })),
        ),
      };
      await ApiService.updateSimulationCountryFertility(simulationCountry.simulationCountryId, payload);

      loadData();
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Overview of Reproduction" size="small">
            Population growth as ‘new births’ is a function of your country’s GDP per capita and its fertile population.
            The ‘new birth’ rate can be lowered by decreasing your country’s fertile population using various population
            control methods and/or by increasing your country’s GDP per capita through economic production. You may
            immediately lower your population’s growth rate, that is, the number of ‘new births’, by reducing the
            fertile population. To reduce your fertile population, anyone in your country may utilize the family
            planning advice of a doctor or other appropriate personnel assumed to be in a dispensary, clinic, or
            hospital. The production function of the family planning advice is XY = 1; that is, 1 labor use times 1
            medical use (1 x 1 = 1) will reduce your country’s fertile population figure by 1. If your population is
            reduced enough, your ‘new birth’ rate will be lowered.
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Population Statistics" size="small">
            <Card className="statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>National Population</b>
                <div>{statistics.national}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Number Fertile</b>
                <div>{statistics.fertile}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Retired Population</b>
                <div>{statistics.retired}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Dependent Population</b>
                <div>{statistics.dependent}</div>
              </div>
            </Card>
          </Card>
        </Col>
      </Row>
      <Row className="mt-16" gutter={16}>
        <Col span={16}>
          <Card title={`Labor Uses (${totalLaborRemainingUses})`} size="small">
            <Row gutter={16}>
              <Col span={12}>
                {laborUses.slice(0, 8).map((laborUse, index) => (
                  <div className="d-flex justify-space-between align-center mb-8" key={`lu-${index}`}>
                    <div>
                      <b>{laborUse.resourceNameLocalized} </b>
                      <span className={laborUse.currentSimulationYearRemainingUses > 0 ? 'text-success' : 'text-error'}>
                        ({laborUse.currentSimulationYearRemainingUses})
                      </span>
                    </div>
                    <InputNumber
                      value={laborUse.uses}
                      min={0}
                      max={laborUse.currentSimulationYearRemainingUses}
                      onChange={handleChangeLaborUse(index)}
                      disabled={laborUse.currentSimulationYearRemainingUses === 0}
                    />
                  </div>
                ))}
              </Col>
              <Col span={12}>
                {laborUses.slice(8).map((laborUse, index) => (
                  <div className="d-flex justify-space-between align-center mb-8" key={`lu-${index + 8}`}>
                    <div>
                      <b>{laborUse.resourceNameLocalized} </b>
                      <span className={laborUse.currentSimulationYearRemainingUses > 0 ? 'text-success' : 'text-error'}>
                        ({laborUse.currentSimulationYearRemainingUses})
                      </span>
                    </div>
                    <InputNumber
                      value={laborUse.uses}
                      min={0}
                      max={laborUse.currentSimulationYearRemainingUses}
                      onChange={handleChangeLaborUse(index + 8)}
                      disabled={laborUse.currentSimulationYearRemainingUses === 0}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>
                <Card className={totalLaborUses > 0 ? 'designated-card bg-warning' : 'designated-card'} size="small">
                  <div className="d-flex justify-space-between align-center">
                    <div>Designated Labor Uses</div>
                    <div>{totalLaborUses}</div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card className="medical-uses-card" title={`Medical Uses (${totalMedicalRemainingUses})`} size="small">
            <div>
              {medicalUses.map((medicalUse, index) => (
                <div className="d-flex justify-space-between align-center mb-8" key={`mu-${index}`}>
                  <div>
                    <b>{medicalUse.resourceNameLocalized} </b>
                    <span className={medicalUse.currentSimulationYearRemainingUses > 0 ? 'text-success' : 'text-error'}>
                      ({medicalUse.currentSimulationYearRemainingUses})
                    </span>
                  </div>
                  <InputNumber
                    value={medicalUse.uses}
                    min={0}
                    max={medicalUse.currentSimulationYearRemainingUses}
                    onChange={handleChangeMedicalUse(index)}
                    disabled={medicalUse.currentSimulationYearRemainingUses === 0}
                  />
                </div>
              ))}
            </div>
            <Card className={totalMedicalUses > 0 ? 'designated-card bg-warning' : 'designated-card'} size="small">
              <div className="d-flex justify-space-between align-center">
                <div>Designated Medical Uses</div>
                <div>{totalMedicalUses}</div>
              </div>
            </Card>
          </Card>
        </Col>
      </Row>
      <div className="d-flex justify-center mt-16">
        <Button
          style={{ width: 200 }}
          type="primary"
          onClick={handlePlan}
          disabled={!totalLaborUses || totalLaborUses !== totalMedicalUses}
        >
          Plan
        </Button>
      </div>
    </Page>
  );
};
