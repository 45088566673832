import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { CLASS_STUDENTS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { ClassStudentModal } from './ClassStudentModal';

export const ClassStudents = () => {
  const { classId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);
  const [members, setMembers] = useState([]);
  const [classStudents, setClassStudents] = useState([]);
  const [student, setStudent] = useState(false);

  const fetchClass = async () => {
    try {
      const data = await ApiService.retrieveClass(classId);
      if (data.success) {
        setCurrentClass(data.result);
        setBreadcrumbItems([
          {
            title: 'Classes',
            path: '/main/classes',
          },
          {
            title: data.result.className,
            path: `/main/classes/${data.result.classId}`,
          },
          {
            title: 'Students',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMembers = async () => {
    try {
      const data = await ApiService.retrieveMemberList(classId);
      if (data.success) {
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchClassStudents = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveClassStudentList(classId);
      if (data.success) {
        setClassStudents(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddStudent = () => {
    setStudent({});
  };

  const handleCloseStudentModal = () => {
    setStudent(null);
  };

  const handleStudentSaved = () => {
    setStudent(null);
    fetchClassStudents();
  };

  const handleDeleteStudent = async student => {
    try {
      await ApiService.deleteClassStudent(currentClass.classId, student.userId);
      await fetchClassStudents();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchClass();
    fetchMembers();
    fetchClassStudents();
    // eslint-disable-next-line
  }, []);

  const classStudentsTableColumns = [
    ...CLASS_STUDENTS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this student?" onConfirm={() => handleDeleteStudent(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddStudent}>
        Add Student
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Students" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={classStudentsTableColumns}
          dataSource={classStudents}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <ClassStudentModal
        currentClass={currentClass}
        members={members}
        student={student}
        onClose={handleCloseStudentModal}
        onSave={handleStudentSaved}
      />
    </>
  );
};
