import { NavLink } from 'react-router-dom';
import Utils from '../../../utils';

export const getSimulationCountriesTableColumns = simulationId => {
  return [
    {
      id: 'regionName',
      title: 'Region',
      dataIndex: 'regionName',
      sorter: (a, b) => {
        const aRegionName = a.regionName;
        const bRegionName = b.regionName;
        const aPrimaryPart = aRegionName.includes(' ') ? aRegionName.split(' ')[1] : aRegionName;
        const bPrimaryPart = bRegionName.includes(' ') ? bRegionName.split(' ')[1] : bRegionName;
        if (aPrimaryPart !== bPrimaryPart) {
          return aPrimaryPart.localeCompare(bPrimaryPart);
        }
        const aSecondaryPart = aRegionName.split(' ')[0];
        const bSecondaryPart = bRegionName.split(' ')[0];
        return aSecondaryPart.localeCompare(bSecondaryPart);
      },
    },
    {
      id: 'countryName',
      title: 'Country',
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      render: row => {
        return (
          <NavLink to={`/main/simulations/${simulationId}/countries/${row.simulationCountryId}`}>
            <img src={row.flagUrl} alt={row.countryName} />
            <span style={{ paddingLeft: 4 }}>{row.countryName}</span>
          </NavLink>
        );
      },
    },
    {
      id: 'simulationCountryYear',
      title: 'Current Year',
      dataIndex: 'simulationCountryYear',
    },
    {
      id: 'simulationCountryState',
      title: 'Current State',
      render: row => Utils.getSimulationCountryStateById(row.simulationCountryState),
    },
    {
      id: 'studentName',
      title: 'Student Name',
      dataIndex: 'studentName',
    },
    {
      id: 'classSection',
      title: 'Class Section',
      dataIndex: 'classSection',
    },
  ];
};
