import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import { SimulationModal } from '../Simulations/SimulationModal';

export const SimulationDetails = () => {
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useContext(MainContext);
  const { simulationId } = useParams();
  const [simulation, setSimulation] = useState(null);
  const [currentSimulation, setCurrentSimulation] = useState(null);
  const [classes, setClasses] = useState([]);

  const fetchClasses = async () => {
    try {
      const data = await ApiService.retrieveClassList();
      if (data.success) {
        setClasses(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchSimulation = async () => {
    try {
      const data = await ApiService.retrieveSimulation(simulationId);
      if (data.success) {
        setSimulation(data.result);
        setBreadcrumbItems([
          {
            title: 'Simulations',
            path: '/main/simulations',
          },
          {
            title: data.result.simulationName,
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const handleEditSimulation = () => {
    setCurrentSimulation({ ...simulation });
  };

  const handleCloseSimulationModal = () => {
    setCurrentSimulation(null);
  };

  const handleSimulationSaved = () => {
    handleCloseSimulationModal();
    fetchSimulation();
  };

  const handleManageCountries = () => {
    navigate(`/main/simulations/${simulationId}/countries`);
  };

  const handleManageStudents = () => {
    navigate(`/main/simulations/${simulationId}/students`);
  };

  const handleManageYears = () => {
    navigate(`/main/simulations/${simulationId}/years`);
  };

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleEditSimulation}>
        Edit Simulation
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageCountries}>
        Countries
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageStudents}>
        Students
      </Button>
      <Button size="small" type="primary" onClick={handleManageYears}>
        Years & Events
      </Button>
    </>
  );

  useEffect(() => {
    fetchClasses();
    fetchSimulation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card size="small" title="Manage Simulation" extra={ActionBar}>
        <Form labelCol={{ span: 4 }} disabled>
          <Form.Item label="Simulation Name">
            <Input value={simulation?.simulationName} />
          </Form.Item>
          <Form.Item label="Class Name">
            <Input value={simulation?.className} />
          </Form.Item>
          <Form.Item label="School Name">
            <Input value={simulation?.schoolName} />
          </Form.Item>
          <Form.Item label="Number of Countries">
            <Input value={simulation?.numberOfSimulationCountries} />
          </Form.Item>
          <Form.Item label="Current Year in Simulation">
            <Input value={simulation?.simulationYearIndex} />
          </Form.Item>
          <Form.Item label="Total Years in Simulation">
            <Input value={simulation?.simulationYearArrayLength} />
          </Form.Item>
        </Form>
      </Card>
      <SimulationModal
        classes={classes}
        simulation={currentSimulation}
        onClose={handleCloseSimulationModal}
        onSave={handleSimulationSaved}
      />
    </>
  );
};
