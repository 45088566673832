import { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import { SIMULATION_COUNTRY_STATES } from '../../../../data';
import { Feed } from './Feed';
import { Overview } from './Overview';
import { PopulationChanges } from './PopulationChanges';
import { Review } from './Review';
import { NewYearAvailable } from './NewYearAvailable';

export const FeedThePeople = () => {
  const { simulationCountry } = useSimulationContext();
  const [step, setStep] = useState(0);

  const handleChangeStep = newStep => {
    setStep(newStep);
  };

  useEffect(() => {
    if (
      [SIMULATION_COUNTRY_STATES.END_YEAR, SIMULATION_COUNTRY_STATES.END_SIMULATION].includes(
        simulationCountry?.simulationCountryState,
      )
    ) {
      setStep(3);
    } else {
      setStep(1);
    }
  }, [simulationCountry]);

  if (!simulationCountry) {
    return null;
  }

  return (
    <>
      <Card size="small" title="Feed The People">
        {step === 1 && <Overview onChangeStep={handleChangeStep} />}
        {step === 2 && <Feed onChangeStep={handleChangeStep} />}
        {step === 3 && <PopulationChanges onChangeStep={handleChangeStep} />}
        {step === 4 && <Review onChangeStep={handleChangeStep} />}
        {step === 5 && <NewYearAvailable />}
      </Card>
    </>
  );
};
