export default class DataService {
  static getItem(key) {
    const data = sessionStorage.getItem(key);
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  static setItem(key, value) {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }
    if (typeof value === 'string') {
      sessionStorage.setItem(key, value);
      return;
    }
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}
