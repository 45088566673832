import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import ApiService from '../../../../services/ApiService';
import Utils from '../../../../utils';

const KEY_DATA_COLUMNS = [
  {
    id: 'year',
    title: 'Year',
    dataIndex: 'simulationCountryYear',
  },
  {
    id: 'wealth',
    title: 'National Wealth',
    render: row => row.wealth.toFixed(0),
  },
  {
    id: 'gdp',
    title: 'GDP',
    render: row => row.gdp.toFixed(0),
  },
  {
    id: 'population',
    title: 'Population',
    render: row => row.population.toFixed(0),
  },
  {
    id: 'gpc',
    title: 'GDP Per Capita',
    render: row => row.gpc.toFixed(0),
  },
  {
    id: 'water',
    title: 'Water Resources',
    render: row => row.water.toFixed(0),
  },
  {
    id: 'resources',
    title: 'Resources',
    render: row => row.resources.toFixed(0),
  },
  {
    id: 'pollution',
    title: 'Pollution',
    render: row => row.pollution.toFixed(0),
  },
  {
    id: 'deaths',
    title: 'Deaths',
    render: row => Math.abs(row.deceasedPopulation).toFixed(0),
  },
  {
    id: 'military',
    title: 'Military Points',
    render: row => row.military.toFixed(0),
  },
  {
    id: 'gold',
    title: 'Gold',
    render: row => row.gold.toFixed(0),
  },
  {
    id: 'score',
    title: 'Score',
    render: row => row.simulationCountryScore.toFixed(0),
  },
];

export const KeyDataByYear = () => {
  const params = useParams();
  const [snapshots, setSnapshots] = useState([]);

  const loadData = async () => {
    const data = await ApiService.retrieveSimulationCountrySnapshotList(params.simulationCountryId);
    data.resultList.forEach(sc => {
      const resourceValues = Utils.calculateResourceValues(sc.simulationCountryResourceViewList);
      sc.wealth = resourceValues.totalWealth;
      sc.water = resourceValues.totalWater;
      sc.resources = resourceValues.totalNatural;
      sc.military = resourceValues.militaryPower;
    });
    setSnapshots(data.resultList);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return <Table rowKey="simulationCountryYear" columns={KEY_DATA_COLUMNS} dataSource={snapshots} />;
};
