export const SCHOOL_CLASSES_TABLE_COLUMNS = [
  {
    id: 'className',
    title: 'Name',
    dataIndex: 'className',
  },
  {
    id: 'year',
    title: 'Year',
    dataIndex: 'year',
  },
  {
    id: 'termName',
    title: 'Term',
    dataIndex: 'termName',
  },
  {
    id: 'section',
    title: 'Section',
    dataIndex: 'section',
  },
  {
    id: 'subject',
    title: 'Subject',
    dataIndex: 'subject',
  },
  {
    id: 'gradeName',
    title: 'Grade',
    dataIndex: 'gradeName',
  },
  {
    id: 'numberOfStudents',
    title: 'Students',
    dataIndex: 'numberOfStudents',
  },
  {
    id: 'numberOfTeachers',
    title: 'Teachers',
    dataIndex: 'numberOfTeachers',
  },
];
