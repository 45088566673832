import styled from 'styled-components';
import { Card } from 'antd';

export const Page = styled(Card)``;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`;
