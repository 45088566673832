import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal, Select } from 'antd';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';

export const SchoolModal = ({ school, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const isNew = !school?.schoolId;

  const countryList = DataService.getItem('countryList');
  const stateList = DataService.getItem('stateList');
  const schoolLevelList = DataService.getItem('schoolLevelList');
  const schoolSizeList = DataService.getItem('schoolSizeList');
  const usCountryId = countryList.find(country => country.countryName === 'United States of America').countryId;

  const handleFormValuesChange = changedValues => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === 'countryId') {
      const countryId = changedValues[fieldName];
      setCountryId(countryId);
      form.setFieldValue('arbitraryStateName', '');
      form.setFieldValue('stateId', countryId === usCountryId ? stateList[0].stateId : 0);
    }
  };

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = {
        ...values,
        primaryLanguageId: 1,
      };
      if (payload.arbitraryStateName) {
        payload.stateId = 0;
      } else {
        payload.arbitraryStateName = '';
      }
      if (isNew) {
        await ApiService.addSchool(payload);
      } else {
        await ApiService.updateSchool(school.schoolId, payload);
      }
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (school?.schoolId) {
      form.setFieldsValue(school);
      setCountryId(school.countryId);
      if (school.countryId === usCountryId) {
        form.setFieldValue('arbitraryStateName', '');
      } else {
        form.setFieldValue('arbitraryStateName', school.stateName);
      }
    } else {
      form.resetFields();
      setCountryId(0);
      form.setFieldValue('arbitraryStateName', '');
    }
  }, [form, school, usCountryId]);

  return (
    <Modal
      title={isNew ? 'Add School' : 'Edit School'}
      open={!!school}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          {isNew ? 'Add' : 'Save'}
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={school}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          label="Name"
          name="schoolName"
          rules={[
            {
              required: true,
              message: 'School name is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Level"
          name="levelId"
          rules={[
            {
              required: true,
              message: 'School level is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select school level"
            optionFilterProp="levelName"
            fieldNames={{ label: 'levelName', value: 'levelId' }}
            options={schoolLevelList}
          />
        </Form.Item>
        <Form.Item
          label="Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: 'Country is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select school country"
            optionFilterProp="countryName"
            fieldNames={{ label: 'countryName', value: 'countryId' }}
            options={countryList}
          />
        </Form.Item>
        {countryId === usCountryId && (
          <Form.Item
            label="State / Province"
            name="stateId"
            rules={[
              {
                required: true,
                message: 'State is required!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select state"
              optionFilterProp="stateName"
              fieldNames={{ label: 'stateName', value: 'stateId' }}
              options={stateList}
            />
          </Form.Item>
        )}
        {countryId !== usCountryId && (
          <Form.Item
            label="State / Province"
            name="arbitraryStateName"
            rules={[
              {
                required: true,
                message: 'State is required!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: 'City is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Enrollment"
          name="sizeId"
          rules={[
            {
              required: true,
              message: 'School size is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select school enrollment"
            optionFilterProp="sizeName"
            fieldNames={{ label: 'sizeName', value: 'sizeId' }}
            options={schoolSizeList}
          />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
