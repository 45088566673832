import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal, Select } from 'antd';
import ApiService from '../../../services/ApiService';

export const SimulationModal = ({ classes, simulation, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const isNew = !simulation?.simulationId;

  const onFinish = async values => {
    setSubmitting(true);
    try {
      let payload = { ...values };
      if (isNew) {
        await ApiService.addSimulation(payload);
      } else {
        payload = {
          ...payload,
          allowUserInitiatedMilitaryEvents: true,
        };
        await ApiService.updateSimulation(simulation.simulationId, payload);
      }
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (simulation?.simulationId) {
      form.setFieldsValue(simulation);
    } else {
      form.resetFields();
    }
  }, [form, simulation]);

  return (
    <Modal
      title={isNew ? 'Add Simulation' : 'Edit Simulation'}
      open={!!simulation}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          {isNew ? 'Add' : 'Save'}
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={simulation}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Simulation Name"
          name="simulationName"
          rules={[
            {
              required: true,
              message: 'Simulation name is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Simulation Class"
          name="classId"
          rules={[
            {
              required: true,
              message: 'Class is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a class"
            optionFilterProp="className"
            fieldNames={{ label: 'className', value: 'classId' }}
            options={classes}
          />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
