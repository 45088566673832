import { useEffect, useState } from 'react';
import { notification, Checkbox, Button, Form, Modal } from 'antd';
import Swal from 'sweetalert2';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

export const FulfillRequestModal = ({ isOpen, request, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const resource = DataService.getItem('resourceList').find(r => r.resourceId === request?.resourceId);
  const iconName = resource?.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const totalPrice = (values.requestResponseQuantity ?? 0) * (request?.unitPrice ?? 0);

  const scResource = simulationCountry?.simulationCountryResourceViewList.find(
    scr => scr.resourceId === request?.resourceId,
  );

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.requestId = request.requestId;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.sellAnonymous = !!payload.sellAnonymous;
      const { message } = await ApiService.addSimulationRequestResponse(payload);
      if (message === 'buyer does not have enough gold') {
        Swal.fire({
          icon: 'error',
          text: 'The buyer does not have enough gold to purchase the items.',
        });
      }
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Fulfill Request"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="fulfill-request-save"
          type="primary"
          loading={isSubmitting}
          disabled={!values.requestResponseQuantity}
          onClick={() => form.submit()}
        >
          Fulfill Request
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <p>Do you want to fulfill part or all of this request?</p>
        <p>There are {request?.quantity} units requested.</p>
        <p>There are {scResource?.remainingQuantity} units available.</p>
        <SelectOption style={{ marginBottom: 16 }}>
          {resource && <img src={iconUrl} alt={iconName} />}
          <span>{resource?.resourceNameLocalized}</span>
        </SelectOption>
        <hr />
        <Form.Item
          label="Quantity to Sell"
          name="requestResponseQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
          ]}
        >
          <InputNumber2
            min={scResource?.remainingQuantity > 0 ? 1 : 0}
            max={scResource?.remainingQuantity ?? 0}
            onChange={e => setValues({ requestResponseQuantity: e })}
          />
        </Form.Item>
        <Form.Item name="sellAnonymous" valuePropName="checked">
          <Checkbox>Sell Anonymously</Checkbox>
        </Form.Item>
        <hr />
        Price Per Unit: {request?.unitPrice?.toFixed(2)}
        <hr />
        Total Selling Price: {totalPrice.toFixed(2)}
        <hr />
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit" disabled={!values.requestResponseQuantity}></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
