import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';
import { MainContext } from '../../../layouts/Main/Context';
import { getSimulationCountriesTableColumns } from './data';
import { ActionColumn } from './styles';
import { SimulationCountryModal } from './SimulationCountryModal';

export const SimulationCountries = () => {
  const regionList = DataService.getItem('regionList');
  const countryList = DataService.getItem('countryList');
  const { simulationId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [simulationCountries, setSimulationCountries] = useState([]);
  const [country, setCountry] = useState(null);

  const fetchSimulation = async () => {
    try {
      const data = await ApiService.retrieveSimulation(simulationId);
      if (data.success) {
        setBreadcrumbItems([
          {
            title: 'Simulations',
            path: '/main/simulations',
          },
          {
            title: data.result.simulationName,
            path: `/main/simulations/${data.result.simulationId}`,
          },
          {
            title: 'Countries',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchSimulationCountries = async () => {
    setLoading(true);
    try {
      let data = await ApiService.retrieveSimulationCountryList(simulationId);
      const countries = data.resultList;
      data = await ApiService.retrieveSimulationCountryMemberList(simulationId);
      data.resultList.forEach(row => {
        const country = countries.find(country => country.simulationCountryId === row.simulationCountryId);
        if (country) {
          country.studentName = `${row.firstName} ${row.lastName}`;
          country.classSection = row.classSection;
        }
      });
      countries.sort((a, b) => a.countryName.localeCompare(b.countryName));
      countries.forEach(sc => {
        const country = countryList.find(c => c.countryId === sc.countryId);
        const region = regionList.find(r => r.regionId === country.regionId);
        const flagUrl = `/images/flags/${country.countryCode}.png`;
        sc.regionName = region.regionName;
        sc.countryName = country.countryName;
        sc.flagUrl = flagUrl;
      });
      setSimulationCountries(countries);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddCountry = () => {
    setCountry({});
  };

  const handleLockGoalsForAllCountries = async () => {
    const result = await Swal.fire({
      title: 'Do you want to proceed?',
      icon: 'question',
      text: 'Are you sure you want to lock goals for all simulation countries?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });
    if (result.isConfirmed) {
      await ApiService.updateSimulationCountriesGoalsLocked(simulationId, { goalsAreLocked: true });

      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: 'Goals have been locked for all simulation countries.',
      });
    }
  };

  const handleUnlockGoalsForAllCountries = async () => {
    const result = await Swal.fire({
      title: 'Do you want to proceed?',
      icon: 'question',
      text: 'Are you sure you want to unlock goals for all simulation countries?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });
    if (result.isConfirmed) {
      await ApiService.updateSimulationCountriesGoalsLocked(simulationId, { goalsAreLocked: false });

      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: 'Goals have been unlocked for all simulation countries.',
      });
    }
  };

  const handleCloseCountryModal = () => {
    setCountry(null);
  };

  const handleCountrySaved = () => {
    setCountry(null);
    fetchSimulationCountries();
  };

  const handleDeleteCountry = async country => {
    try {
      await ApiService.deleteSimulationCountry(country.simulationCountryId);
      await fetchSimulationCountries();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    fetchSimulationCountries();
    // eslint-disable-next-line
  }, []);

  const simulationCountriesTableColumns = [
    ...getSimulationCountriesTableColumns(simulationId),
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this country?" onConfirm={() => handleDeleteCountry(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button className="mr-8" size="small" danger type="primary" onClick={handleLockGoalsForAllCountries}>
        Lock Goals For All Countries
      </Button>
      <Button className="mr-8" size="small" type="primary" onClick={handleUnlockGoalsForAllCountries}>
        Unlock Goals For All Countries
      </Button>
      <Button size="small" type="primary" onClick={handleAddCountry}>
        Add Country
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Countries" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={simulationCountriesTableColumns}
          dataSource={simulationCountries}
          rowKey="simulationCountryId"
          bordered
          head
        />
      </Card>
      <SimulationCountryModal
        simulationId={simulationId}
        country={country}
        onClose={handleCloseCountryModal}
        onSave={handleCountrySaved}
      />
    </>
  );
};
