export const GOALS_TABLE_COLUMNS = [
  {
    id: 'rank',
    title: 'Rank',
    dataIndex: 'rank',
    width: 60,
  },
  {
    id: 'name',
    title: 'Goal Name',
    dataIndex: 'name',
    width: 250,
  },
  {
    id: 'description',
    title: 'Description',
    dataIndex: 'description',
  },
];
