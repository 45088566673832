import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import Utils from '../../../utils';
import { SCHOOL_CLASSES_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SchoolClassModal } from './SchoolClassModal';

export const SchoolClasses = () => {
  const { schoolId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [currentClass, setCurrentClass] = useState(false);

  const fetchSchool = async () => {
    try {
      const data = await ApiService.retrieveSchool(schoolId);
      if (data.success) {
        setSchool(data.result);
        setBreadcrumbItems([
          {
            title: 'Schools',
            path: '/main/schools',
          },
          {
            title: data.result.schoolName,
            path: `/main/schools/${data.result.schoolId}`,
          },
          {
            title: 'Classes',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchSchoolClasses = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSchoolClassList(schoolId);
      setSchoolClasses(data.resultList);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddClass = () => {
    setCurrentClass({});
  };

  const handleCloseClassModal = () => {
    setCurrentClass(null);
  };

  const handleClassSaved = () => {
    setCurrentClass(null);
    fetchSchoolClasses();
  };

  const handleDeleteClass = async currentClass => {
    try {
      await ApiService.deleteClass(currentClass.classId);
      await fetchSchoolClasses();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSchool();
    fetchSchoolClasses();
    // eslint-disable-next-line
  }, []);

  const schoolClassesTableColumns = [...SCHOOL_CLASSES_TABLE_COLUMNS];
  if (Utils.checkPermissions('Admin of School', school?.schoolId)) {
    schoolClassesTableColumns.push({
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this class?" onConfirm={() => handleDeleteClass(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    });
  }

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddClass}>
        Add Class
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card
        size="small"
        title="Classes"
        extra={Utils.checkPermissions('Admin of School', school?.schoolId) ? ActionBar : null}
      >
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={schoolClassesTableColumns}
          dataSource={schoolClasses}
          rowKey="classId"
          bordered
          head
        />
      </Card>
      <SchoolClassModal
        school={school}
        currentClass={currentClass}
        onClose={handleCloseClassModal}
        onSave={handleClassSaved}
      />
    </>
  );
};
