import SessionService from './SessionService';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const baseUrl = 'http://localhost:8000';
// const baseUrl = 'https://api.simpolicon.org';

export default class ApiService {
  static getOptions(payload) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      // credentials: 'include',
    };
    const accessToken = SessionService.getAccessToken();
    if (accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`;
    }
    return options;
  }

  static post(endpoint, payload = {}, headers = {}) {
    const options = this.getOptions(payload);
    options.headers = {
      ...options.headers,
      ...headers,
    };

    return fetch(`${baseUrl}${endpoint}`, options).then(res => res.json());
  }

  static postAjax(endpoint, payload = {}, headers = {}) {
    return this.post(`/Ajax${endpoint}`, payload, headers);
  }

  static postRole(endpoint, payload = {}, headers = {}) {
    return this.post(`/Role${endpoint}`, payload, headers);
  }

  static postSimulation(endpoint, payload = {}, headers = {}) {
    return this.post(`/Simulation${endpoint}`, payload, headers);
  }

  // Public API

  static retrieveLanguageList() {
    return this.postAjax('/RetrieveLanguageList');
  }

  static retrieveRegionList() {
    return this.postAjax('/RetrieveRegionList');
  }

  static retrieveCountryList() {
    return this.postAjax('/RetrieveCountryViewList');
  }

  static retrieveStateList(payload) {
    return this.postAjax('/RetrieveStateList', payload);
  }

  static retrieveLevelList() {
    return this.postAjax('/RetrieveLevelList');
  }

  static retrieveSizeList() {
    return this.postAjax('/RetrieveSizeList');
  }

  static retrieveTermList() {
    return this.postAjax('/RetrieveTermList');
  }

  static retrieveGradeList() {
    return this.postAjax('/RetrieveGradeList');
  }

  static retrieveResourceTypeList() {
    return this.postAjax('/RetrieveResourceTypeLocalizationViewList', { languageId: 1 });
  }

  static retrieveResourceList() {
    return this.postAjax('/RetrieveResourceLocalizationViewList', { languageId: 1 });
  }

  static retrieveRecipeList() {
    return this.postAjax('/RetrieveRecipeViewList');
  }

  static retrieveGoalTypeList() {
    return this.postAjax('/RetrieveGoalTypeLocalizationViewList', { languageId: 1 });
  }

  static retrieveEventGroupList() {
    return this.postAjax('/RetrieveEventGroupList');
  }

  static retrieveEventGroupItemList() {
    return this.postAjax('/RetrieveEventGroupItemViewList');
  }

  // Auth API

  static login(payload) {
    return this.postAjax('/LogIn', payload);
  }

  // Account API

  static retrieveAccountView() {
    return this.postAjax('/RetrieveAccountView');
  }

  static updateAccount(payload) {
    return this.postAjax('/UpdateAccount', payload);
  }

  static updateAccountPassword(payload) {
    return this.postAjax('/UpdateAccountPassword', payload);
  }

  static retrieveAccountRoleList() {
    return this.postRole('/RetrieveAccountRoleViewList');
  }

  // Admin API

  // School API
  static retrieveSchoolList() {
    return this.postAjax('/RetrieveSchoolViewList');
  }

  static retrieveSchool(schoolId) {
    return this.postAjax('/RetrieveSchoolView', { schoolId });
  }

  static addSchool(payload) {
    return this.postAjax('/AddSchool', payload);
  }

  static updateSchool(schoolId, payload) {
    return this.postAjax('/UpdateSchool', { schoolId, ...payload });
  }

  static deleteSchool(schoolId) {
    return this.postAjax('/DeleteSchool', { schoolId });
  }

  static retrieveSchoolClassList(schoolId) {
    return this.postAjax('/RetrieveClassViewListOfSchool', { schoolId });
  }

  static retrieveSchoolStudentList(schoolId) {
    return this.postRole('/RetrieveSchoolStudents', { schoolId });
  }

  static addSchoolStudent(schoolId, userId) {
    return this.postRole('/AddSchoolStudent', { schoolId, userId });
  }

  static deleteSchoolStudent(schoolId, userId) {
    return this.postRole('/DeleteSchoolStudent', { schoolId, userId });
  }

  static retrieveSchoolTeacherList(schoolId) {
    return this.postRole('/RetrieveSchoolTeachers', { schoolId });
  }

  static addSchoolTeacher(schoolId, userId) {
    return this.postRole('/AddSchoolTeacher', { schoolId, userId });
  }

  static deleteSchoolTeacher(schoolId, userId) {
    return this.postRole('/DeleteSchoolTeacher', { schoolId, userId });
  }

  static retrieveSchoolAdminList(schoolId) {
    return this.postRole('/RetrieveSchoolAdmins', { schoolId });
  }

  static addSchoolAdmin(schoolId, userId) {
    return this.postRole('/AddSchoolAdmin', { schoolId, userId });
  }

  static deleteSchoolAdmin(schoolId, userId) {
    return this.postRole('/DeleteSchoolAdmin', { schoolId, userId });
  }

  // Class API
  static retrieveClassList() {
    return this.postAjax('/RetrieveClassViewList');
  }

  static retrieveClass(classId) {
    return this.postAjax('/RetrieveClassView', { classId });
  }

  static addClass(payload) {
    return this.postAjax('/AddClass', payload);
  }

  static updateClass(classId, payload) {
    return this.postAjax('/UpdateClass', { classId, ...payload });
  }

  static deleteClass(classId) {
    return this.postAjax('/DeleteClass', { classId });
  }

  static retrieveClassSimulationList(classId) {
    return this.postSimulation('/RetrieveSimulationViewListOfClass', { classId });
  }

  static retrieveClassStudentList(classId) {
    return this.postRole('/RetrieveClassStudents', { classId });
  }

  static addClassStudent(classId, userId) {
    return this.postRole('/AddClassStudent', { classId, userId });
  }

  static deleteClassStudent(classId, userId) {
    return this.postRole('/DeleteClassStudent', { classId, userId });
  }

  static retrieveClassTeacherList(classId) {
    return this.postRole('/RetrieveClassTeachers', { classId });
  }

  static addClassTeacher(classId, userId) {
    return this.postRole('/AddClassTeacher', { classId, userId });
  }

  static deleteClassTeacher(classId, userId) {
    return this.postRole('/DeleteClassTeacher', { classId, userId });
  }

  // Simulations
  static retrieveSimulationList() {
    return this.postSimulation('/RetrieveSimulationViewList');
  }

  static retrieveSimulation(simulationId) {
    return this.postSimulation('/RetrieveSimulationView', { simulationId });
  }

  static addSimulation(payload) {
    return this.postSimulation('/AddSimulation', payload);
  }

  static updateSimulation(simulationId, payload) {
    return this.postSimulation('/UpdateSimulation', { simulationId, ...payload });
  }

  static deleteSimulation(simulationId) {
    return this.postSimulation('/DeleteSimulation', { simulationId });
  }

  static retrieveSimulationCountryList(simulationId) {
    return this.postSimulation('/RetrieveSimulationCountryViewList', { simulationId });
  }

  static retrieveSimulationCountryListFast(simulationId) {
    return this.postSimulation('/RetrieveSimulationCountryViewListFast', { simulationId });
  }

  static retrieveSimulationCountry(simulationCountryId) {
    return this.postSimulation('/RetrieveSimulationCountryView', { simulationCountryId });
  }

  static retrieveSimulationCountrySnapshotList(simulationCountryId) {
    return this.postSimulation('/RetrieveSimulationCountrySnapshotList', { simulationCountryId });
  }

  static addSimulationCountrySnapshot(simulationCountryId) {
    return this.postSimulation('/AddSimulationCountrySnapshot', { simulationCountryId });
  }

  static addSimulationCountry(simulationId, payload) {
    return this.postSimulation('/AddSimulationCountry', { simulationId, ...payload });
  }

  static deleteSimulationCountry(simulationCountryId) {
    return this.postSimulation('/DeleteSimulationCountry', { simulationCountryId });
  }

  static retrieveSimulationCountryMemberList(simulationId) {
    return this.postSimulation('/RetrieveSimulationCountryUserViewListOfSimulation', { simulationId });
  }

  static addSimulationCountryStudent(simulationId, payload) {
    return this.postSimulation('/AddNewUserToSimulationCountry', { simulationId, ...payload });
  }

  static addExistingSimulationCountryStudent(simulationId, payload) {
    return this.postSimulation('/AddSimulationCountryUser', { simulationId, ...payload });
  }

  static deleteSimulationCountryStudent(simulationCountryUserId) {
    return this.postSimulation('/DeleteSimulationCountryUser', { simulationCountryUserId });
  }

  static retrieveSimulationStudentList(simulationId) {
    return this.postSimulation('/RetrieveStudentsOfSimulation', { simulationId });
  }

  static deleteSimulationStudent(simulationId, userId) {
    return this.postSimulation('/DeleteStudentFromSimulation', { simulationId, userId });
  }

  static retrieveSimulationCountryStudentList(simulationId) {
    return this.postSimulation('/RetrieveSimulationCountryUserViewListOfSimulation', { simulationId });
  }

  static retrieveSimulationCountryResourceList(simulationCountryId) {
    return this.postSimulation('/RetrieveSimulationCountryResourceViewList', { simulationCountryId });
  }

  static updateSimulationCountryResource(simulationCountryId, payload) {
    return this.postSimulation('/ChangeSimulationCountryResource', { simulationCountryId, ...payload });
  }

  static resetSimulationCountryResources(simulationCountryId) {
    return this.postSimulation('/ResetSimulationCountryResources', { simulationCountryId });
  }

  static updateSimulationCountryScore(simulationCountryId, simulationCountryScore) {
    return this.postSimulation('/UpdateSimulationCountryScore', { simulationCountryId, simulationCountryScore });
  }

  static updateSimulationCountryGoalsLocked(simulationCountryId, payload) {
    return this.postSimulation('/UpdateSimulationCountryGoalsAreLocked', { simulationCountryId, ...payload });
  }

  static updateSimulationCountriesGoalsLocked(simulationId, payload) {
    return this.postSimulation('/UpdateSimulationCountriesGoalsAreLocked', { simulationId, ...payload });
  }

  static updateSimulationYears(simulationId, payload) {
    return this.postAjax('/UpdateSimulationYearArray', { simulationId, ...payload });
  }

  static retrieveSimulationCountryResourceTransactionList(
    simulationCountryId,
    simulationCountryYear,
    simulationCountryState,
  ) {
    return this.postSimulation('/RetrieveSimulationCountryResourceTransactionList', {
      simulationCountryId,
      simulationCountryYear,
      simulationCountryState,
    });
  }

  static retrieveSimulationCountryResourceTransactionListFiltered(
    simulationCountryId,
    simulationCountryYear,
    simulationCountryState,
  ) {
    return this.postSimulation('/RetrieveSimulationCountryResourceTransactionListFiltered', {
      simulationCountryId,
      simulationCountryYear,
      simulationCountryState,
    });
  }

  static addSimulationCountryRecipeUse(simulationCountryId, numberOfAttempts, recipeObjectString) {
    return this.postSimulation('/AddSimulationCountryRecipeUse', {
      simulationCountryId,
      numberOfAttempts,
      recipeObjectString,
    });
  }

  static retrieveSimulationOfferList(simulationId, payload) {
    return this.postSimulation('/RetrieveOfferListOfSimulation', { simulationId, ...payload });
  }

  static addSimulationOffer(payload) {
    return this.postSimulation('/AddOffer', payload);
  }

  static addSimulationOfferResponse(payload) {
    return this.postSimulation('/AddOfferResponse', payload);
  }

  static retrieveSimulationRequestList(simulationId, payload) {
    return this.postSimulation('/RetrieveRequestListOfSimulation', { simulationId, ...payload });
  }

  static addSimulationRequest(payload) {
    return this.postSimulation('/AddRequest', payload);
  }

  static addSimulationRequestResponse(payload) {
    return this.postSimulation('/AddRequestResponse', payload);
  }

  static retrieveSimulationTradeList(simulationId, payload) {
    return this.postSimulation('/RetrieveTradeViewListOfSimulation', { simulationId, ...payload });
  }

  static updateSimulationCountryFertility(simulationCountryId, payload) {
    return this.postSimulation('/FertilityControlSimulationCountry', { simulationCountryId, ...payload });
  }

  static addSimulationCountryWar(payload) {
    return this.postSimulation('/AddWar', payload);
  }

  static advanceSimulationCountry(simulationCountryId, payload) {
    return this.postSimulation('/AdvanceSimulationCountry', { simulationCountryId, ...payload });
  }

  static undoMedicalEvent(simulationCountryId, payload) {
    return this.postSimulation('/UndoMedicalEvent', { simulationCountryId, ...payload });
  }

  // Member API
  static retrieveMemberList() {
    return this.postAjax('/RetrieveMemberList');
  }

  static retrieveMember(userId) {
    return this.postAjax('/RetrieveUserView', { userId });
  }

  static retrieveMemberRoleList(userId) {
    return this.postRole('/RetrieveUserRoleViewList', { userId });
  }

  static addMember(payload) {
    return this.postAjax('/AddUser', payload);
  }

  static updateMember(userId, payload) {
    return this.postAjax('/UpdateUser', { userId, ...payload });
  }

  static updateMemberPassword(payload) {
    return this.postAjax('/UpdateUserPassword', payload);
  }

  static deleteMember(userId) {
    return this.postAjax('/DeleteUser', { userId });
  }

  // Player API
  static retrieveAccountSimulationCountryList() {
    return this.postSimulation('/RetrieveSimulationCountryUserViewListOfAccount');
  }

  static updateSimulationCountryGoalTypeArray(simulationCountryId, stringifiedGoalTypeIdList) {
    return this.postSimulation('/UpdateSimulationCountryGoalTypeArray', {
      simulationCountryId,
      stringifiedGoalTypeIdList,
    });
  }
}
