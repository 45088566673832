import { Button } from 'antd';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

export const Overview = ({ onChangeStep }) => {
  useEffect(() => {
    Swal.fire({
      title: "Don't feed and provide water for your people until you're ready to end the year.",
      icon: 'info',
    });
  }, []);

  return (
    <>
      <p>Overview</p>
      <p>
        Your simulation year ends when you either feed your people or the time for the year expires as indicated in the
        black header above. Only World Briefing, National Briefing, and Goals buttons will be active until a new year is
        announced.
      </p>
      <p>
        At the end of each year, your population will be affected by births and deaths in such a way as to represent the
        real world.
      </p>
      <p>
        Births are automatically determined based on your country's GDP per capita, relative to the world's GDP per
        capita and your country's fertile population (total population less dependents, retired population, and
        non-reproductive population who have received population growth rate advice during the year now ending). There
        may be random births in addition. All births are shown as Dependent Population in your country's statistics for
        1 year after which they become Unskilled Labor.
      </p>
      <p>Deaths may occur from famine and/or randomly from natural causes.</p>
      <p>
        To prevent deaths from famine, every person needs 50 carbohydrate units (from <b>Bread</b> and/or <b>Tubers</b>
        ), 50 protein units (from <b>Fish</b> and/or <b>Meat</b>), 50 water units (from <b>Potable Water</b> and/or{' '}
        <b>Non-potable Water</b>).
      </p>
      <p>
        If your country has enough food and <b>Potable Water</b> for everyone, no one will die from famine although some
        people may die randomly from natural causes. These random deaths are influenced by the number of your country's
        medical uses available per capita (from pharmacies, clinics, hospitals, and doctors), your country's GDP per
        capita relative to the world's GDP per capita, your country's use of <b>Potable</b> and <b>Non-Potable Water</b>
        , and your country's and the world's polluition levels.
      </p>
      <p>
        If you don't have enough food and water for all of your people, some will die from famine in addition to the
        random deaths from natural causes mentioned earlier.
      </p>
      <p>
        If you don't have enough food and water for anyone, everyone in your country will die, your country will be
        reset to "Year 1", and you must begin again. Alternatively, you may elect to continue without any population and
        hope other countries will send you needed laborers.
      </p>

      <p>
        After you have fed your people, the data for your country and the world will be available using the{' '}
        <b>National Briefing</b> and <b>World Briefing</b> buttons on the dashboard. The data will change automatically
        as each country finishes its year. (Your other dashboard tabs will be inactive until the next year begins.) Be
        sure to analyze the data carefully as you make your economic plans for the upcoming year(s).
      </p>
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} type="primary" onClick={() => onChangeStep(2)}>
          Continue
        </Button>
      </div>
    </>
  );
};
