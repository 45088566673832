import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Form, Input } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';
import Utils from '../../../utils';

const StyledCard = styled(Card)`
  .ant-card-body {
    max-height: calc(100vh - 190px);
    overflow-y: auto;
  }
`;

export const SimulationCountryDetails = () => {
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useContext(MainContext);
  const { simulationId, simulationCountryId } = useParams();
  const [simulationCountry, setSimulationCountry] = useState(null);

  const resourceTypeList = DataService.getItem('resourceTypeList');
  const resourceList = DataService.getItem('resourceList');

  const fetchSimulation = async () => {
    try {
      const { result: simulationData } = await ApiService.retrieveSimulation(simulationId);
      const { resultList: countryList } = await ApiService.retrieveSimulationCountryList(simulationId);
      const countryData = countryList.find(c => c.simulationCountryId === +simulationCountryId);
      setSimulationCountry(countryData);
      setBreadcrumbItems([
        {
          title: 'Simulations',
          path: '/main/simulations',
        },
        {
          title: simulationData.simulationName,
          path: `/main/simulations/${simulationId}`,
        },
        {
          title: 'Countries',
          path: `/main/simulations/${simulationId}/countries`,
        },
        {
          title: countryData.countryName,
        },
      ]);
    } catch (e) {
      //
    }
  };

  const getResourceValue = resourceName => {
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    const resourceObject = simulationCountry?.simulationCountryResourceViewList.find(
      r => r.resourceId === resource.resourceId,
    );
    return resourceObject?.remainingQuantity ?? 0;
  };

  const getResourceTypeValue = resourceTypeName => {
    const resourceType = resourceTypeList.find(rt => rt.resourceTypeNameLocalized === resourceTypeName);
    const resources = resourceList.filter(r => r.resourceTypeId === resourceType.resourceTypeId);
    const value = resources.reduce((total, resource) => total + getResourceValue(resource.resourceNameLocalized), 0);
    return value;
  };

  const handleManageStudents = () => {
    navigate(`/main/simulations/${simulationId}/countries/${simulationCountryId}/students`);
  };

  const handleManageResources = () => {
    navigate(`/main/simulations/${simulationId}/countries/${simulationCountryId}/resources`);
  };

  const handleManageGoals = () => {
    navigate(`/main/simulations/${simulationId}/countries/${simulationCountryId}/goals`);
  };

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageStudents}>
        Students
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageResources}>
        Resources
      </Button>
      <Button size="small" type="primary" onClick={handleManageGoals}>
        Goals
      </Button>
    </>
  );

  useEffect(() => {
    fetchSimulation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <StyledCard size="small" title="Manage Simulation Country" extra={ActionBar}>
        <Form labelCol={{ span: 4 }} disabled>
          <Form.Item label="Country">
            <Input value={simulationCountry?.countryName} />
          </Form.Item>
          <Form.Item label="Country Name">
            <Input value={simulationCountry?.countryName} />
          </Form.Item>
          <Form.Item label="Current Year">
            <Input value={simulationCountry?.simulationCountryYear} />
          </Form.Item>
          <Form.Item label="Current State">
            <Input value={Utils.getSimulationCountryStateById(simulationCountry?.simulationCountryState)} />
          </Form.Item>
          <Form.Item label="Goals Set">
            <Input value={simulationCountry?.goalsAreLocked ? 'Goals Locked' : 'Goals Unlocked'} />
          </Form.Item>
          <Form.Item label="National Wealth">
            <Input value={simulationCountry?.gdp} />
          </Form.Item>
          <Form.Item label="Gross Domestic Product (GDP)">
            <Input value={simulationCountry?.gdp} />
          </Form.Item>
          <Form.Item label="Population">
            <Input value={simulationCountry?.population} />
          </Form.Item>
          <Form.Item label="GDP Per Capita">
            <Input value={simulationCountry?.gpc?.toFixed(2)} />
          </Form.Item>
          <Form.Item label="Water Resources">
            <Input value={getResourceTypeValue('Water Resources')} />
          </Form.Item>
          <Form.Item label="Land and Mineral Resources">
            <Input value={getResourceTypeValue('Agricultural Land')} />
          </Form.Item>
          <Form.Item label="Pollution">
            <Input value={simulationCountry?.pollution} />
          </Form.Item>
          <Form.Item label="Fertility">
            <Input value={`${(simulationCountry?.fertility ?? 0) * 100}%`} />
          </Form.Item>
          <Form.Item label="Migrated Population">
            <Input value={simulationCountry?.migratedPopulation} />
          </Form.Item>
          <Form.Item label="Deaths">
            <Input value={simulationCountry?.deceasedPopulation} />
          </Form.Item>
          <Form.Item label="Military">
            <Input value={getResourceTypeValue('Military')} />
          </Form.Item>
          <Form.Item label="Gold">
            <Input value={getResourceValue('Gold')} />
          </Form.Item>
          <Form.Item label="Score">
            <Input value={simulationCountry?.simulationCountryScore?.toFixed(2)} />
          </Form.Item>
        </Form>
      </StyledCard>
    </>
  );
};
