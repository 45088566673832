import { Page } from './styles';

export const Welcome = () => (
  <Page>
    <img className="img-logo" src="/images/pages/portal/welcome/logo.png" alt="" />
    <p>
      Your goal is to create a sustainable well-balanced country by meeting social, economic, and environmental
      objectives.
    </p>
    <img src="/images/pages/portal/welcome/earth.png" alt="" />
    <p>To get started, select your country on the left.</p>
  </Page>
);
