import { useEffect, useState } from 'react';
import { notification, Button, Checkbox, DatePicker, Form, Modal, Slider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import ApiService from '../../../services/ApiService';
import { VCRow } from './styles';

const { RangePicker } = DatePicker;

const initialYear = {
  isFERandom: false,
  feProbability: 0,
  isDERandom: false,
  deProbability: 0,
};

export const SimulationYearModal = ({ simulationId, years, year, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({ ...initialYear });
  const [minDate, setMinDate] = useState(dayjs());
  const [maxDate, setMaxDate] = useState(null);

  const handleFormValueChange = field => e => {
    if (['isFERandom', 'isDERandom'].includes(field)) {
      setFormData({
        ...formData,
        [field]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [field]: e,
      });
    }
  };

  const onFinish = async values => {
    setSubmitting(true);
    try {
      if (!year?.Year) {
        const newYear = {
          StartsOn: moment(values.dateRange[0].toDate()).utc().format('M/D/YYYY H:m:s'),
          EndsOn: moment(values.dateRange[1].toDate()).utc().format('M/D/YYYY H:m:s'),
          MaximumTransactions: 0,
          GoalWeightingType: 1,
          FortuitousEventsRandomAndInvisible: formData.isFERandom,
          FortuitousEventsProbability: formData.isFERandom ? 0 : formData.feProbability / 100,
          DisastrousEventsRandomAndInvisible: formData.isDERandom,
          DisastrousEventsProbability: formData.isDERandom ? 0 : formData.deProbability / 100,
          MilitaryEventsRandomAndInvisible: false,
          MilitaryEventsProbability: 0,
        };
        const updatedYears = [...years, newYear];
        updatedYears.forEach(y => {
          delete y.Year;
          delete y.Status;
        });
        const payload = {
          simulationYearArrayStringified: JSON.stringify(updatedYears),
        };
        await ApiService.updateSimulationYears(simulationId, payload);
      } else {
        const index = years.findIndex(y => y.Year === year.Year);
        let updatedYear = years[index];
        updatedYear = {
          ...updatedYear,
          StartsOn: moment(values.dateRange[0].toDate()).utc().format('M/D/YYYY H:m:s'),
          EndsOn: moment(values.dateRange[1].toDate()).utc().format('M/D/YYYY H:m:s'),
          FortuitousEventsRandomAndInvisible: formData.isFERandom,
          FortuitousEventsProbability: formData.isFERandom ? 0 : formData.feProbability / 100,
          DisastrousEventsRandomAndInvisible: formData.isDERandom,
          DisastrousEventsProbability: formData.isDERandom ? 0 : formData.deProbability / 100,
        };
        const updatedYears = [...years];
        updatedYears[index] = updatedYear;
        updatedYears.forEach(y => {
          delete y.Year;
          delete y.Status;
        });
        const payload = {
          simulationYearArrayStringified: JSON.stringify(updatedYears),
        };
        await ApiService.updateSimulationYears(simulationId, payload);
      }
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
    setFormData({ ...initialYear });
    if (year?.Year) {
      const startDate = moment.utc(year.StartsOn, 'M/D/YYYY H:m:s').local().toDate();
      const endDate = moment.utc(year.EndsOn, 'M/D/YYYY H:m:s').local().toDate();
      form.setFieldValue('dateRange', [dayjs(startDate), dayjs(endDate)]);

      setFormData({
        isFERandom: year.FortuitousEventsRandomAndInvisible,
        feProbability: Math.round(year.FortuitousEventsProbability * 100),
        isDERandom: year.DisastrousEventsRandomAndInvisible,
        deProbability: Math.round(year.DisastrousEventsProbability * 100),
      });
    }
  }, [form, year]);

  useEffect(() => {
    if (year?.Year) {
      const startDate = moment.utc(year.StartsOn, 'M/D/YYYY H:m:s').local().toDate();
      const endDate = moment.utc(year.EndsOn, 'M/D/YYYY H:m:s').local().toDate();
      setMinDate(dayjs(startDate));
      setMaxDate(dayjs(endDate));
    } else if (years.length > 0) {
      const lastYear = years[years.length - 1];
      const startDate = moment.utc(lastYear.EndsOn, 'M/D/YYYY H:m:s').local().toDate();
      setMinDate(dayjs(startDate));
      setMaxDate(null);
    }
  }, [years, year]);

  return (
    <Modal
      title="Add Year"
      open={!!year}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          {!year?.Year ? 'Add' : 'Save'}
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={year}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Year Time Range"
          name="dateRange"
          rules={[
            {
              required: true,
              message: 'Year Time Range is required!',
            },
          ]}
        >
          <RangePicker showTime minDate={minDate} maxDate={maxDate} />
        </Form.Item>
        <Form.Item label="Fortuitous Events">
          <VCRow>
            <Checkbox checked={formData.isFERandom} onChange={handleFormValueChange('isFERandom')}>
              Randomize
            </Checkbox>
            <Slider
              value={formData.feProbability}
              onChange={handleFormValueChange('feProbability')}
              disabled={formData.isFERandom}
            />
            <label>{formData.feProbability}%</label>
          </VCRow>
        </Form.Item>
        <Form.Item label="Disastrous Events">
          <VCRow>
            <Checkbox checked={formData.isDERandom} onChange={handleFormValueChange('isDERandom')}>
              Randomize
            </Checkbox>
            <Slider
              value={formData.deProbability}
              onChange={handleFormValueChange('deProbability')}
              disabled={formData.isDERandom}
            />
            <label>{formData.deProbability}%</label>
          </VCRow>
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
