import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import { GOALS } from '../../../../data';

const Goal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 8px 16px 8px 8px;
  width: 100%;
  font-size: 16;
  font-weight: 700;
  background-color: #ffffca;
`;

const GoalItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 0 16px;
  width: 100%;
  background-color: #bcecfa;
`;

export const EconomicGoals = () => {
  const { calculateSimulationCountryGoals } = useSimulationContext();
  const [goals, setGoals] = useState(GOALS);

  const totalValue = goals.reduce((total, goal) => total + goal.actualValue ?? 0, 0).toFixed(2);

  const loadData = async () => {
    const { goals, goalRanks } = await calculateSimulationCountryGoals();

    const newGoals = [];
    for (let i = 0; i < 12; i += 1) {
      const index = goalRanks.findIndex(goalRank => goalRank === i);
      newGoals.push(goals[index]);
    }

    setGoals(newGoals);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p className="mt-0">
        Score is calculated by adding the number of non zero items times the goal weight assigned (see <b>Goals</b> or{' '}
        <b>Evaluation & Score</b> for the assigned weights).
      </p>
      {goals.map(goal => (
        <div key={goal.title}>
          <Goal>
            <div>{goal.title}</div>
            <div>{goal.value}</div>
          </Goal>
          {goal.children.map(item => (
            <GoalItem style={{ paddingLeft: 16, width: '100%' }} key={item.title}>
              <div>{item.title}</div>
              <div>{item.value}</div>
            </GoalItem>
          ))}
        </div>
      ))}
      <Goal className={+totalValue.replace(/,/g, '') >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
        <div>Total Score</div>
        <div>{totalValue}</div>
      </Goal>
    </>
  );
};
