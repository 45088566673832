import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal } from 'antd';
import ApiService from '../../../services/ApiService';

export const ChangePasswordModal = ({ open, userId, username, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const onFinish = async values => {
    setSubmitting(true);
    try {
      if (values.newPassword !== values.verifyPassword) {
        api.open({
          message: 'Sorry...',
          description: 'New password should match with verify password',
        });
        setSubmitting(false);
        return;
      }
      const payload = {
        userId,
        userName: username,
        password: values.newPassword,
      };
      await ApiService.updateMemberPassword(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Modal
      title="Change Password"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {contextHolder}
      <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={false} autoComplete="off">
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'New password is required!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Verify Password"
          name="verifyPassword"
          rules={[
            {
              required: true,
              message: 'Verify password is required!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
