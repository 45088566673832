import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Select, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';
import { MainContext } from '../../../layouts/Main/Context';
import { RESOURCES_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SimulationCountryResourceModal } from './SimulationCountryResourceModal';

export const SimulationCountryResources = () => {
  const { simulationId, simulationCountryId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [resource, setResource] = useState(null);
  const [selectedResourceTypeId, setSelectedResourceTypeId] = useState(-1);

  const resourceTemplateList = DataService.getItem('resourceList');
  const resourceTypeList = [
    {
      resourceTypeNameLocalized: 'All',
      resourceTypeId: -1,
    },
    ...DataService.getItem('resourceTypeList'),
  ];

  const filteredSCResources =
    selectedResourceTypeId === -1 ? resources : resources.filter(r => r.resourceTypeId === selectedResourceTypeId);

  const fetchSimulation = async () => {
    try {
      const { result: simulationData } = await ApiService.retrieveSimulation(simulationId);
      const { resultList: countryList } = await ApiService.retrieveSimulationCountryList(simulationId);
      const countryData = countryList.find(c => c.simulationCountryId === +simulationCountryId);
      setBreadcrumbItems([
        {
          title: 'Simulations',
          path: '/main/simulations',
        },
        {
          title: simulationData.simulationName,
          path: `/main/simulations/${simulationId}`,
        },
        {
          title: 'Countries',
          path: `/main/simulations/${simulationId}/countries`,
        },
        {
          title: countryData.countryName,
          path: `/main/simulations/${simulationId}/countries/${simulationCountryId}`,
        },
        {
          title: 'Resources',
        },
      ]);
    } catch (e) {
      //
    }
  };

  const fetchSimulationCountryResources = async () => {
    setLoading(true);
    try {
      let { resultList: resourceList } = await ApiService.retrieveSimulationCountryResourceList(simulationCountryId);
      resourceList.forEach(resource => {
        const resourceTemplate = resourceTemplateList.find(rt => rt.resourceId === resource.resourceId);
        resource.resourceNameLocalized = resourceTemplate.resourceNameLocalized;
        resource.iconUrl = resourceTemplate.iconUrl;
        resource.usesPerItemPerYear = resourceTemplate.usesPerItemPerYear;
        resource.usesPerItem = resourceTemplate.usesPerItem;
        resource.resourceTypeId = resourceTemplate.resourceTypeId;
      });
      resourceList = resourceList.filter(resource => resource.iconUrl);
      resourceList.sort((a, b) => (a.resourceNameLocalized > b.resourceNameLocalized ? 1 : -1));
      setResources(resourceList);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleEditResource = resource => {
    setResource(resource);
  };

  const handleCloseResourceModal = () => {
    setResource(null);
  };

  const handleResourceSaved = () => {
    setResource(null);
    fetchSimulationCountryResources();
  };

  const handleResetResources = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure to reset this country's resources?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      });
      if (result.isConfirmed) {
        await ApiService.resetSimulationCountryResources(simulationCountryId);
        await fetchSimulationCountryResources();
      }
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    fetchSimulationCountryResources();
    // eslint-disable-next-line
  }, []);

  const resourceTableColumns = [
    ...RESOURCES_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Button size="small" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditResource(row)} />
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" type="primary" danger onClick={handleResetResources}>
        Reset All Resources
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Manage Resources" extra={ActionBar}>
        <div className="d-flex justify-space-between align-center mb-8">
          <p className="mt-0 mb-0">
            Click individual item to change current quantity or reset all resources at once to starting condition.
          </p>
          <Select
            style={{ width: 180 }}
            options={resourceTypeList}
            fieldNames={{ label: 'resourceTypeNameLocalized', value: 'resourceTypeId' }}
            value={selectedResourceTypeId}
            onChange={e => setSelectedResourceTypeId(e)}
          />
        </div>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 300px)' }}
          loading={isLoading}
          columns={resourceTableColumns}
          dataSource={filteredSCResources}
          rowKey="resourceId"
          bordered
          head
        />
      </Card>
      <SimulationCountryResourceModal
        simulationCountryId={simulationCountryId}
        resource={resource}
        onClose={handleCloseResourceModal}
        onSave={handleResourceSaved}
      />
    </>
  );
};
