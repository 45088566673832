import { useEffect, useState } from 'react';
import { notification, Checkbox, Button, Form, Modal } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

export const AdmitMigrantsModal = ({ isOpen, offer, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const resource = DataService.getItem('resourceList').find(r => r.resourceId === offer?.resourceId);
  const iconName = resource?.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const totalPrice = (values.offerResponseQuantity ?? 0) * (offer?.unitPrice ?? 0);

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.offerId = offer.offerId;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.buyAnonymous = !!payload.buyAnonymous;
      await ApiService.addSimulationOfferResponse(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Admit Migrants"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="admit-migrants-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Pay Wage and Admit
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <p>
          Please verify the number of migrants and the asking wage per person before admitting them into your country.
        </p>
        <p>There are {offer?.quantity} workers willing to emigrate.</p>
        <SelectOption style={{ marginBottom: 16 }}>
          {resource && <img src={iconUrl} alt={iconName} />}
          <span>{resource?.resourceNameLocalized}</span>
        </SelectOption>
        <hr />
        <Form.Item
          label="Quantity to Hire"
          name="offerResponseQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
          ]}
        >
          <InputNumber2 min={1} max={offer?.quantity ?? 1} onChange={e => setValues({ offerResponseQuantity: e })} />
        </Form.Item>
        <Form.Item name="buyAnonymous" valuePropName="checked">
          <Checkbox>Hire Anonymously</Checkbox>
        </Form.Item>
        <hr />
        Asking Wage Per Person: {offer?.unitPrice}
        <hr />
        Total Migration Costs: {totalPrice}
        <hr />
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
