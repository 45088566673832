import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { SIMULATION_STUDENTS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SimulationNewStudentModal } from './SimulationNewStudentModal';
import { SimulationExistingStudentModal } from './SimulationExistingStudentModal';

export const SimulationStudents = () => {
  const { simulationId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [classStudents, setClassStudents] = useState([]);
  const [simulationCountries, setSimulationCountries] = useState([]);
  const [simulationStudents, setSimulationStudents] = useState([]);
  const [newStudent, setNewStudent] = useState(null);
  const [existingStudent, setExistingStudent] = useState(null);

  const fetchSimulation = async () => {
    try {
      const data = await ApiService.retrieveSimulation(simulationId);
      setSimulation(data.result);
      if (data.success) {
        setBreadcrumbItems([
          {
            title: 'Simulations',
            path: '/main/simulations',
          },
          {
            title: data.result.simulationName,
            path: `/main/simulations/${simulationId}`,
          },
          {
            title: 'Students',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchSimulationStudents = async () => {
    setLoading(true);
    try {
      let data = await ApiService.retrieveSimulationStudentList(simulationId);
      const students = data.resultList;
      data = await ApiService.retrieveSimulationCountryMemberList(simulationId);
      data.resultList.forEach(row => {
        const student = students.find(student => student.userId === row.userId);
        if (student) {
          student.countryName = row.countryName;
          student.classSection = row.classSection;
        }
      });
      setSimulationStudents(students);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const fetchSimulationCountries = async () => {
    try {
      const data = await ApiService.retrieveSimulationCountryListFast(simulationId);
      setSimulationCountries(data.resultList);
    } catch (e) {
      //
    }
  };

  const fetchClassStudents = async () => {
    try {
      const data = await ApiService.retrieveClassStudentList(simulation.classId);
      setClassStudents(data.resultList);
    } catch (e) {
      //
    }
  };

  const handleAddNewStudent = () => {
    setNewStudent({});
  };

  const handleCloseNewStudentModal = () => {
    setNewStudent(null);
  };

  const handleNewStudentSaved = () => {
    setNewStudent(null);
    fetchSimulationStudents();
  };

  const handleAddExistingStudent = () => {
    setExistingStudent({});
  };

  const handleCloseExistingStudentModal = () => {
    setExistingStudent(null);
  };

  const handleExistingStudentSaved = () => {
    setExistingStudent(null);
    fetchSimulationStudents();
  };

  const handleDeleteStudent = async student => {
    try {
      await ApiService.deleteSimulationStudent(simulationId, student.userId);
      await fetchSimulationStudents();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    fetchSimulationStudents();
    fetchSimulationCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (simulation) {
      fetchClassStudents();
    }
    // eslint-disable-next-line
  }, [simulation]);

  const simulationStudentsTableColumns = [
    ...SIMULATION_STUDENTS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this student?" onConfirm={() => handleDeleteStudent(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleAddNewStudent}>
        Add New Student
      </Button>
      <Button size="small" type="primary" onClick={handleAddExistingStudent}>
        Add Existing Student
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Students" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={simulationStudentsTableColumns}
          dataSource={simulationStudents}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <SimulationNewStudentModal
        simulationId={simulationId}
        simulationCountries={simulationCountries}
        student={newStudent}
        onClose={handleCloseNewStudentModal}
        onSave={handleNewStudentSaved}
      />
      <SimulationExistingStudentModal
        simulationId={simulationId}
        simulationCountries={simulationCountries}
        classStudents={classStudents}
        student={existingStudent}
        onClose={handleCloseExistingStudentModal}
        onSave={handleExistingStudentSaved}
      />
    </>
  );
};
