import { useEffect, useState } from 'react';
import { notification, Button, Form, Modal } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

export const ManageRequestModal = ({ isOpen, request, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const resource = DataService.getItem('resourceList').find(r => r.resourceId === request?.resourceId);
  const iconName = resource?.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.requestId = request.requestId;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.sellAnonymous = false;
      await ApiService.addSimulationRequestResponse(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Manage Request"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="manage-request-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Update Request
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <p>
          This is your country's request. You can reduce or remove this request. Please verify the quantity to remove
          from the existing request. Reducing all units will remove the request from the market.
        </p>
        <p>There are {request?.quantity} units available.</p>
        <SelectOption style={{ marginBottom: 16 }}>
          {resource && <img src={iconUrl} alt={iconName} />}
          <span>{resource?.resourceNameLocalized}</span>
        </SelectOption>
        <hr />
        <Form.Item
          label="Quantity to Remove"
          name="requestResponseQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
            {
              validator: (_, value) => {
                const max = request?.quantity ?? 1;
                if (value > max) {
                  return Promise.reject(new Error(`The maximum quantity to remove is ${max} for this request.`));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber2 min={1} max={request?.quantity ?? 1} />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
