import { useEffect, useState } from 'react';
import { notification, Checkbox, Button, Form, Modal } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

export const PurchaseProductModal = ({ isOpen, offer, simulationCountry, onClose, onSave }) => {
  const { getSimulationCountryResourceValueByName } = useSimulationContext();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const resource = DataService.getItem('resourceList').find(r => r.resourceId === offer?.resourceId);
  const iconName = resource?.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const totalPrice = (values.offerResponseQuantity ?? 0) * (offer?.unitPrice ?? 0);

  const maxQuantity = Math.min(
    offer?.quantity ?? 0,
    Math.floor(getSimulationCountryResourceValueByName('Gold', 'remainingTradableQuantity') / (offer?.unitPrice ?? 1)),
  );

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.offerId = offer.offerId;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.buyAnonymous = !!payload.buyAnonymous;
      await ApiService.addSimulationOfferResponse(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Purchase Product"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="purchase-product-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Purchase
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <p>Please verify the quantity and price before purchasing.</p>
        <p>There are {offer?.quantity} units available.</p>
        <SelectOption style={{ marginBottom: 16 }}>
          {resource && <img src={iconUrl} alt={iconName} />}
          <span>{resource?.resourceNameLocalized}</span>
        </SelectOption>
        <hr />
        <Form.Item
          label="Quantity to Buy"
          name="offerResponseQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
          ]}
        >
          <InputNumber2 min={1} max={maxQuantity} onChange={e => setValues({ offerResponseQuantity: e })} />
        </Form.Item>
        <Form.Item name="buyAnonymous" valuePropName="checked">
          <Checkbox>Purchase Anonymously</Checkbox>
        </Form.Item>
        <hr />
        Price Per Unit: {offer?.unitPrice.toFixed(2)}
        <hr />
        Total Purchase Price: {totalPrice.toFixed(2)}
        <hr />
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
