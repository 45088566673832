import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { SCHOOL_ADMINS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SchoolAdminModal } from './SchoolAdminModal';

export const SchoolAdmins = () => {
  const { schoolId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [members, setMembers] = useState([]);
  const [schoolAdmins, setSchoolAdmins] = useState([]);
  const [admin, setAdmin] = useState(false);

  const fetchSchool = async () => {
    try {
      const data = await ApiService.retrieveSchool(schoolId);
      if (data.success) {
        setSchool(data.result);
        setBreadcrumbItems([
          {
            title: 'Schools',
            path: '/main/schools',
          },
          {
            title: data.result.schoolName,
            path: `/main/schools/${data.result.schoolId}`,
          },
          {
            title: 'Admins',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMembers = async () => {
    try {
      const data = await ApiService.retrieveMemberList(schoolId);
      if (data.success) {
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchSchoolAdmins = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSchoolAdminList(schoolId);
      if (data.success) {
        setSchoolAdmins(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddAdmin = () => {
    setAdmin({});
  };

  const handleCloseAdminModal = () => {
    setAdmin(null);
  };

  const handleAdminSaved = () => {
    setAdmin(null);
    fetchSchoolAdmins();
  };

  const handleDeleteAdmin = async admin => {
    try {
      await ApiService.deleteSchoolAdmin(school.schoolId, admin.userId);
      await fetchSchoolAdmins();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSchool();
    fetchMembers();
    fetchSchoolAdmins();
    // eslint-disable-next-line
  }, []);

  const schoolAdminsTableColumns = [
    ...SCHOOL_ADMINS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this admin?" onConfirm={() => handleDeleteAdmin(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddAdmin}>
        Add Admin
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Admins" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={schoolAdminsTableColumns}
          dataSource={schoolAdmins}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <SchoolAdminModal
        school={school}
        members={members}
        admin={admin}
        onClose={handleCloseAdminModal}
        onSave={handleAdminSaved}
      />
    </>
  );
};
