import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { SCHOOL_STUDENTS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SchoolStudentModal } from './SchoolStudentModal';

export const SchoolStudents = () => {
  const { schoolId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [members, setMembers] = useState([]);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [student, setStudent] = useState(false);

  const fetchSchool = async () => {
    try {
      const data = await ApiService.retrieveSchool(schoolId);
      if (data.success) {
        setSchool(data.result);
        setBreadcrumbItems([
          {
            title: 'Schools',
            path: '/main/schools',
          },
          {
            title: data.result.schoolName,
            path: `/main/schools/${data.result.schoolId}`,
          },
          {
            title: 'Students',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMembers = async () => {
    try {
      const data = await ApiService.retrieveMemberList(schoolId);
      if (data.success) {
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchSchoolStudents = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSchoolStudentList(schoolId);
      if (data.success) {
        setSchoolStudents(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddStudent = () => {
    setStudent({});
  };

  const handleCloseStudentModal = () => {
    setStudent(null);
  };

  const handleStudentSaved = () => {
    setStudent(null);
    fetchSchoolStudents();
  };

  const handleDeleteStudent = async student => {
    try {
      await ApiService.deleteSchoolStudent(school.schoolId, student.userId);
      await fetchSchoolStudents();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSchool();
    fetchMembers();
    fetchSchoolStudents();
    // eslint-disable-next-line
  }, []);

  const schoolStudentsTableColumns = [...SCHOOL_STUDENTS_TABLE_COLUMNS];
  schoolStudentsTableColumns.push({
    id: 'actions',
    title: '',
    render: row => (
      <ActionColumn>
        <Popconfirm title="Are you sure to delete this student?" onConfirm={() => handleDeleteStudent(row)}>
          <Button size="small" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </ActionColumn>
    ),
  });

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddStudent}>
        Add Student
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Students" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={schoolStudentsTableColumns}
          dataSource={schoolStudents}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <SchoolStudentModal
        school={school}
        members={members}
        student={student}
        onClose={handleCloseStudentModal}
        onSave={handleStudentSaved}
      />
    </>
  );
};
