import { Modal } from 'antd';
import { useSimulationContext } from '../../contexts/SimulationContext';

export const ResourceDetailsModal = () => {
  const { resourceList, selectedResourceId, setSelectedResourceId } = useSimulationContext();
  const open = !!selectedResourceId;

  const resource = resourceList.find(r => r.resourceId === selectedResourceId);

  if (!resource) {
    return null;
  }

  const iconName = resource.iconUrl.split('/').pop();
  const iconUrl = `/images/icons/resources/${iconName}`;
  const description = resource.resourceDescriptionLocalized
    .replaceAll('<TextFlow xmlns="http://ns.adobe.com/textLayout/2008" fontSize="12">', '<div>')
    .replaceAll('</TextFlow>', '</div>')
    .replaceAll('<span fontWeight="bold">', '<b>')
    .replaceAll('</span>', '</b>');

  const title = (
    <div className="d-flex align-center">
      <h2 className="mt-0 mb-0">{resource.resourceNameLocalized}</h2>
      <img className="ml-16" src={iconUrl} width={56} alt="" />
    </div>
  );

  return (
    <Modal width="60%" title={title} footer={null} open={open} onCancel={() => setSelectedResourceId(null)}>
      <div className="mt-16" dangerouslySetInnerHTML={{ __html: description }} />
    </Modal>
  );
};
