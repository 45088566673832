import { useState } from 'react';
import styled from 'styled-components';
import { Select, Table } from 'antd';
import DataService from '../../../../services/DataService';
import { useSimulationContext } from '../../../../contexts/SimulationContext';

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 2px solid #1677ff !important;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff;
  }
`;

export const MasterList = () => {
  const { setSelectedResourceId } = useSimulationContext();
  const resourceTypeList = [
    {
      resourceTypeNameLocalized: 'All',
      resourceTypeId: -1,
    },
    ...DataService.getItem('resourceTypeList'),
  ];
  const resourceList = DataService.getItem('resourceList').filter(resource => resource.iconUrl);
  const [selectedResourceTypeId, setSelectedResourceTypeId] = useState(-1);

  const filteredResources =
    selectedResourceTypeId === -1
      ? resourceList
      : resourceList.filter(r => r.resourceTypeId === selectedResourceTypeId);

  const RESOURCE_COLUMNS = [
    {
      id: 'name',
      title: 'Name',
      render: row => {
        const iconName = row.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={28} src={iconUrl} alt={row.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{row.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'annualUses',
      title: 'Annual Uses',
      render: row => row.usesPerItemPerYear.toFixed(0),
      align: 'center',
    },
    {
      id: 'lifetimeUses',
      title: 'Lifetime Uses',
      render: row => row.usesPerItem.toFixed(0),
      align: 'center',
    },
    {
      id: 'value',
      title: 'Value',
      render: row => row.ngv.toFixed(0),
      align: 'center',
    },
  ];

  return (
    <>
      <div className="d-flex justify-space-between align-center mb-8">
        <p className="mt-0 mb-0">Master list of all resources & products in Simpolicon</p>
        <StyledSelect
          style={{ width: 180 }}
          listHeight={500}
          options={resourceTypeList}
          fieldNames={{ label: 'resourceTypeNameLocalized', value: 'resourceTypeId' }}
          value={selectedResourceTypeId}
          onChange={e => setSelectedResourceTypeId(e)}
        />
      </div>
      <StyledTable
        bordered
        size="small"
        pagination={false}
        scroll={{ y: 'calc(100vh - 240px)' }}
        rowKey="resourceId"
        columns={RESOURCE_COLUMNS}
        dataSource={filteredResources}
      />
    </>
  );
};
