import { useState } from 'react';
import styled from 'styled-components';
import { Card, Tabs } from 'antd';
import { Overview } from './Overview';
import { RulesOfWar } from './RulesOfWar';
import { History } from './History';

const TAB_ITEMS = [
  {
    key: 'overview',
    label: 'Overview',
  },
  {
    key: 'rules-of-war',
    label: 'Rules of War',
  },
  {
    key: 'history',
    label: 'History',
  },
];

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: #fff;
  }

  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   color: #333 !important;
  // }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

export const Military = () => {
  const [activeKey, setActiveKey] = useState(TAB_ITEMS[0].key);

  const tabs = (
    <StyledTabs
      hideAdd
      type="card"
      size="small"
      items={TAB_ITEMS}
      activeKey={activeKey}
      onChange={key => setActiveKey(key)}
    />
  );

  return (
    <StyledCard size="small" title="Military" extra={tabs}>
      {activeKey === TAB_ITEMS[0].key && <Overview />}
      {activeKey === TAB_ITEMS[1].key && <RulesOfWar />}
      {activeKey === TAB_ITEMS[2].key && <History />}
    </StyledCard>
  );
};
