import { useEffect, useState } from 'react';
import { notification, Button, Checkbox, Form, Modal, Select } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import Swal from 'sweetalert2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

const getProductList = simulationCountry => {
  let resourceList = DataService.getItem('resourceList').filter(r => r.magratable);
  const productList = resourceList
    .map(r => {
      const scResource = simulationCountry?.simulationCountryResourceViewList.find(
        scr => scr.resourceId === r.resourceId,
      );
      return {
        ...r,
        ...scResource,
      };
    })
    .map(r => {
      const iconName = r.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resources/${iconName}`;

      return {
        value: r.resourceId,
        label: (
          <SelectOption>
            <img src={iconUrl} alt="" />
            <div>
              {r.resourceNameLocalized} (Quantity: {r.remainingTradableQuantity})
            </div>
          </SelectOption>
        ),
      };
    });

  return productList;
};

export const AddRequestModal = ({ isOpen, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const productList = getProductList(simulationCountry);
  const goldPerUnit = values.totalPrice > 0 && values.quantity > 0 ? values.totalPrice / values.quantity : 0;
  const unitPerGold = values.totalPrice > 0 && values.quantity > 0 ? values.quantity / values.totalPrice : 0;

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.requestQuantity = +payload.requestQuantity;
      payload.unitPrice = goldPerUnit;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.buyAnonymous = !!payload.buyAnonymous;
      payload.minimumQuantity = +payload.requestQuantity;
      delete payload.totalPrice;
      const data = await ApiService.addSimulationRequest(payload);
      if (!data.success && data.message === 'funds not enough') {
        await Swal.fire({
          title: 'Request Labor Error',
          icon: 'error',
          titleText: 'Insufficient Funds To Make This Request...',
          text: 'You do not own enough gold to make this request. Please lower the amount of gold and try again.',
        });
      } else {
        onSave();
      }
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Request Labor"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="add-request-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add Labor Request
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Labor Type"
          name="resourceId"
          rules={[
            {
              required: true,
              message: 'Labor type is required!',
            },
          ]}
        >
          <Select options={productList} onChange={e => setValues({ ...values, resourceId: e })} />
        </Form.Item>
        <Form.Item
          label="Workers Needed (How many people would you like to hire?)"
          name="requestQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
          ]}
        >
          <InputNumber2 min={1} onChange={e => setValues({ ...values, quantity: e })} />
        </Form.Item>
        <Form.Item
          label="Total Wages (How much gold are you willing to pay?)"
          name="totalPrice"
          rules={[
            {
              required: true,
              message: 'Total price is required!',
            },
          ]}
        >
          <InputNumber2 min={1} onChange={e => setValues({ ...values, totalPrice: e })} />
        </Form.Item>
        <Form.Item name="buyAnonymous" valuePropName="checked">
          <Checkbox>Request Anonymously</Checkbox>
        </Form.Item>
        <hr />
        <div>Gold Wage Per Worker: {goldPerUnit.toFixed(2)}</div>
        <hr />
        <div>Workers Per One Gold: {unitPerGold.toFixed(2)}</div>
        <hr />
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
