export const MEMBERS_TABLE_COLUMNS = [
  {
    id: 'lastName',
    title: 'Last Name',
    dataIndex: 'lastName',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    id: 'firstName',
    title: 'First Name',
    dataIndex: 'firstName',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    id: 'userName',
    title: 'Username',
    dataIndex: 'userName',
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    id: 'gender',
    title: 'Gender',
    dataIndex: 'gender',
    sorter: (a, b) => a.gender.localeCompare(b.gender),
  },
  {
    id: 'primaryLanguageName',
    title: 'Language',
    dataIndex: 'primaryLanguageName',
    sorter: (a, b) => a.primaryLanguageName.localeCompare(b.primaryLanguageName),
  },
  {
    id: 'countryName',
    title: 'Home Country',
    dataIndex: 'countryName',
    sorter: (a, b) => a.countryName.localeCompare(b.countryName),
  },
  {
    id: 'yearOfBirth',
    title: 'Year of Birth',
    dataIndex: 'yearOfBirth',
    sorter: (a, b) => (a.yearOfBirth > b.yearOfBirth ? 1 : -1),
  },
];
