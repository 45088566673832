import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Plan } from './Plan';
import { Report } from './Report';
import { Summary } from './Summary';

export const Overview = () => {
  const location = useLocation();
  const isWarStarted = location.search === '?war=true';
  const [step, setStep] = useState(isWarStarted ? 3 : 1);

  return (
    <>
      {step === 1 && <Summary onPlanInvasion={() => setStep(2)} />}
      {step === 2 && <Plan onCancel={() => setStep(1)} onDeclareWar={() => setStep(3)} />}
      {step === 3 && <Report />}
    </>
  );
};
