import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-logo {
    width: 75%;
  }
`;
