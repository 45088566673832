import cookie from 'js-cookie';

export default class SessionService {
  static getUserId() {
    return cookie.get('user_id');
  }

  static getUsername() {
    return cookie.get('username');
  }

  static getAccessToken() {
    return cookie.get('access_token');
  }

  static getServerCookie() {
    return cookie.get('.ASPXAUTH');
  }

  static isLoggedIn() {
    return !!this.getUserId();
  }

  static setUserId(userId) {
    cookie.set('user_id', userId);
  }

  static setUsername(username) {
    cookie.set('username', username);
  }

  static setAccessToken(accessToken) {
    cookie.set('access_token', accessToken);
  }

  static logout() {
    cookie.remove('user_id');
    cookie.remove('username');
    cookie.remove('access_token');
    window.location.reload();
  }
}
