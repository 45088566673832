import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import Utils from '../../../utils';
import { SCHOOL_TEACHERS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SchoolTeacherModal } from './SchoolTeacherModal';

export const SchoolTeachers = () => {
  const { schoolId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [members, setMembers] = useState([]);
  const [schoolTeachers, setSchoolTeachers] = useState([]);
  const [teacher, setTeacher] = useState(false);

  const fetchSchool = async () => {
    try {
      const data = await ApiService.retrieveSchool(schoolId);
      if (data.success) {
        setSchool(data.result);
        setBreadcrumbItems([
          {
            title: 'Schools',
            path: '/main/schools',
          },
          {
            title: data.result.schoolName,
            path: `/main/schools/${data.result.schoolId}`,
          },
          {
            title: 'Teachers',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMembers = async () => {
    try {
      const data = await ApiService.retrieveMemberList(schoolId);
      if (data.success) {
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchSchoolTeachers = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSchoolTeacherList(schoolId);
      if (data.success) {
        setSchoolTeachers(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddTeacher = () => {
    setTeacher({});
  };

  const handleCloseTeacherModal = () => {
    setTeacher(null);
  };

  const handleTeacherSaved = () => {
    setTeacher(null);
    fetchSchoolTeachers();
  };

  const handleDeleteTeacher = async teacher => {
    try {
      await ApiService.deleteSchoolTeacher(school.schoolId, teacher.userId);
      await fetchSchoolTeachers();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSchool();
    fetchMembers();
    fetchSchoolTeachers();
    // eslint-disable-next-line
  }, []);

  const schoolTeachersTableColumns = [...SCHOOL_TEACHERS_TABLE_COLUMNS];
  if (Utils.checkPermissions('Admin of School', school?.schoolId)) {
    schoolTeachersTableColumns.push({
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this teacher?" onConfirm={() => handleDeleteTeacher(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    });
  }

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddTeacher}>
        Add Teacher
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card
        size="small"
        title="Teachers"
        extra={Utils.checkPermissions('Admin of School', school?.schoolId) ? ActionBar : null}
      >
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={schoolTeachersTableColumns}
          dataSource={schoolTeachers}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <SchoolTeacherModal
        school={school}
        members={members}
        teacher={teacher}
        onClose={handleCloseTeacherModal}
        onSave={handleTeacherSaved}
      />
    </>
  );
};
