import { useEffect, useState } from 'react';
import { notification, Button, Checkbox, Form, Modal, Select } from 'antd';
import InputNumber2 from '../../../../../components/InputNumber2/InputNumber2';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SelectOption } from './styles';

const getProductList = simulationCountry => {
  let resourceList = DataService.getItem('resourceList').filter(r => r.magratable);
  const productList = resourceList
    .map(r => {
      const scResource = simulationCountry?.simulationCountryResourceViewList.find(
        scr => scr.resourceId === r.resourceId,
      );
      return {
        ...r,
        ...scResource,
      };
    })
    .filter(r => r.remainingTradableQuantity > 0)
    .map(r => {
      const iconName = r.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resources/${iconName}`;

      return {
        value: r.resourceId,
        label: (
          <SelectOption>
            <img src={iconUrl} alt="" />
            <div>
              {r.resourceNameLocalized} (Quantity: {r.remainingTradableQuantity})
            </div>
          </SelectOption>
        ),
      };
    });

  return productList;
};

export const AddOfferModal = ({ isOpen, simulationCountry, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const productList = getProductList(simulationCountry);
  const goldPerUnit = values.totalPrice > 0 && values.quantity > 0 ? values.totalPrice / values.quantity : 0;
  const unitPerGold = values.totalPrice > 0 && values.quantity > 0 ? values.quantity / values.totalPrice : 0;

  const scResource = simulationCountry?.simulationCountryResourceViewList.find(
    scr => scr.resourceId === values.resourceId,
  );
  const tradableQuantity = scResource?.remainingTradableQuantity ?? 1;

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      payload.offerQuantity = +payload.offerQuantity;
      payload.unitPrice = goldPerUnit;
      payload.simulationCountryId = simulationCountry.simulationCountryId;
      payload.sellAnonymous = !!payload.sellAnonymous;
      payload.minimumQuantity = +payload.offerQuantity;
      delete payload.totalPrice;
      await ApiService.addSimulationOffer(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  return (
    <Modal
      title="Offer Labor"
      open={!!isOpen}
      onCancel={onClose}
      footer={[
        <Button key="add-offer-save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          List Offer
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={{}}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Labor Type"
          name="resourceId"
          rules={[
            {
              required: true,
              message: 'Labor type is required!',
            },
          ]}
        >
          <Select options={productList} onChange={e => setValues({ ...values, resourceId: e })} />
        </Form.Item>
        <Form.Item
          label="Number (How many people would you like to migrate?)"
          name="offerQuantity"
          rules={[
            {
              required: true,
              message: 'Qauntity is required!',
            },
            {
              validator: (_, value) => {
                if (value > tradableQuantity) {
                  return Promise.reject(
                    new Error(`The maximum migratable quantity is ${tradableQuantity} for this item.`),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber2 min={1} max={tradableQuantity} onChange={e => setValues({ ...values, quantity: e })} />
        </Form.Item>
        <Form.Item
          label="Total Wages (How much gold would you like to get?)"
          name="totalPrice"
          rules={[
            {
              required: true,
              message: 'Total price is required!',
            },
          ]}
        >
          <InputNumber2 min={1} onChange={e => setValues({ ...values, totalPrice: e })} />
        </Form.Item>
        <Form.Item name="sellAnonymous" valuePropName="checked">
          <Checkbox>List Anonymously</Checkbox>
        </Form.Item>
        <hr />
        <div>Gold Wage Per Worker: {goldPerUnit.toFixed(2)}</div>
        <hr />
        <div>Workers Per One Gold: {unitPerGold.toFixed(2)}</div>
        <hr />
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
