export const RESOURCES_TABLE_COLUMNS = [
  {
    id: 'resource',
    title: 'Resource',
    render: row => {
      const iconName = row.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resources/${iconName}`;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img width={20} src={iconUrl} alt={row.resourceNameLocalized} />
          <span style={{ paddingLeft: 8 }}>{row.resourceNameLocalized}</span>
        </div>
      );
    },
  },
  {
    id: 'remainingQuantity',
    title: 'Current Quantity',
    dataIndex: 'remainingQuantity',
  },
  {
    id: 'currentSimulationYearRemainingUses',
    title: 'Remaining Uses (Annual)',
    dataIndex: 'currentSimulationYearRemainingUses',
  },
  {
    id: 'remainingUses',
    title: 'Remaining Uses (Lifetime)',
    dataIndex: 'remainingUses',
  },
];
