// eslint-disable-next-line
Number.prototype._toFixed = Number.prototype.toFixed;

// eslint-disable-next-line
Number.prototype.toFixed = function (digits) {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal', // Other options include 'currency' and 'percent'
    maximumFractionDigits: digits, // Maximum number of fraction digits
  });
  return numberFormatter.format(this);
};
