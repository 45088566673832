import styled from 'styled-components';
import { Breadcrumb, Layout } from 'antd';

const { Content, Header, Sider } = Layout;

const bgColor = '#0f131a';

export const MainLayout = styled(Layout)({
  minHeight: '100vh',
});

export const StyledHeader = styled(Header)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSider = styled(Sider)`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 70px);
  background: #fff !important;

  ul {
    box-sizing: content-box;

    .ant-menu-item {
      height: inherit;

      &-selected {
        color: red;
      }
    }

    .country {
      display: block;
      padding: 8px 0 12px;

      h3 {
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 20px;
        font-size: 16px;
      }

      div {
        line-height: 16px;
        font-size: 12px;
      }
    }
  }
`;

export const ContentLayout = styled(Layout)({
  padding: 24,
});

export const StyledContent = styled(Content)({
  padding: 24,
  backgroundColor: '#fff',
});

export const StyledBreadcrumb = styled(Breadcrumb)({
  marginBottom: 16,
});

export const Logo = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #fff;
`;

export const DataLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: ${bgColor};

  .ant-progress-status-normal .ant-progress-text {
    color: #fff;
  }

  h4 {
    color: #fff;
  }
`;
