import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Table } from 'antd';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';

export const TransactionHistory = () => {
  const params = useParams();
  const { setSelectedResourceId, simulationCountry } = useSimulationContext();
  const resourceTypeList = [
    {
      resourceTypeNameLocalized: 'All',
      resourceTypeId: -1,
    },
    ...DataService.getItem('resourceTypeList'),
  ];
  const resourceList = DataService.getItem('resourceList');
  const [selectedResourceTypeId, setSelectedResourceTypeId] = useState(-1);
  const [txnHistory, setTxnHistory] = useState([]);

  const resourceIds = resourceList
    .filter(resource => resource.resourceTypeId === selectedResourceTypeId)
    .map(resource => resource.resourceId);
  const filteredTxnHistory =
    selectedResourceTypeId === -1 ? txnHistory : txnHistory.filter(txn => resourceIds.includes(txn.resourceId));

  const loadData = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    data = await ApiService.retrieveSimulationCountryResourceTransactionList(
      params.simulationCountryId,
      data.result.simulationCountryYear,
      -1,
    );
    data.resultList = data.resultList.filter(
      txn => txn.description !== 'hidden' && txn.description !== 'Remove Resource that has been used.',
    );
    data.resultList.forEach((txn, index) => {
      const resource = resourceList.find(r => r.resourceId === txn.resourceId);
      txn.resourceName = resource.resourceNameLocalized;
      txn.id = index;

      if (txn.simulationCountryState === 255) {
        txn.simulationCountryYear = simulationCountry.simulationCountryYear;
      }
    });
    data.resultList.sort((a, b) => (+a.simulationCountryYear > +b.simulationCountryYear ? 1 : -1));
    setTxnHistory(data.resultList);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const TABLE_COLUMNS = [
    {
      id: 'year',
      title: 'Year',
      dataIndex: 'simulationCountryYear',
      sorter: (a, b) => (+a.simulationCountryYear > +b.simulationCountryYear ? 1 : -1),
    },
    {
      id: 'product',
      title: 'Product',
      render: row => (
        <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
          {row.resourceName}
        </div>
      ),
    },
    {
      id: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      id: 'transactionQuantity',
      title: 'Transaction Quantity',
      render: row => row.transactionQuantity.toFixed(2),
    },
    {
      id: 'transactionUses',
      title: 'Transaction Uses',
      dataIndex: 'transactionUses',
    },
  ];

  return (
    <>
      <div className="d-flex justify-space-between align-center">
        <p className="mt-0 mb-0">Your resource and product transactions</p>
        <Select
          style={{ width: 180 }}
          options={resourceTypeList}
          fieldNames={{ label: 'resourceTypeNameLocalized', value: 'resourceTypeId' }}
          value={selectedResourceTypeId}
          onChange={e => setSelectedResourceTypeId(e)}
        />
      </div>
      <Table
        size="small"
        pagination={false}
        scroll={{ y: 'calc(100vh - 240px)' }}
        rowKey="id"
        columns={TABLE_COLUMNS}
        dataSource={filteredTxnHistory}
      />
    </>
  );
};
