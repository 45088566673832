import { Button } from 'antd';
import styled from 'styled-components';

export const ActionColumn = styled.div`
  display: flex;

  button {
    margin: 0 4px;
  }
`;

export const SuccessButton = styled(Button)`
  color: #52c41a;
  border-color: #52c41a;

  &:hover {
    color: #52c41a !important;
    border-color: #52c41a !important;
  }
`;

export const VCRow = styled.div`
  display: flex;
  align-items: center;

  .ant-slider {
    margin: 0 16px;
    flex: 1;
  }
`;
