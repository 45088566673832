import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import { ClassModal } from '../Classes/ClassModal';
import Utils from '../../../utils';

export const ClassDetails = () => {
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useContext(MainContext);
  const { classId } = useParams();
  const [classData, setClassData] = useState(null);
  const [currentClass, setCurrentClass] = useState(null);

  const fetchClass = async () => {
    try {
      const data = await ApiService.retrieveClass(classId);
      if (data.success) {
        setClassData(data.result);
        setBreadcrumbItems([
          {
            title: 'Classes',
            path: '/main/classes',
          },
          {
            title: data.result.className,
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const handleEditClass = () => {
    setCurrentClass({ ...classData });
  };

  const handleCloseClassModal = () => {
    setCurrentClass(null);
  };

  const handleClassSaved = () => {
    handleCloseClassModal();
    fetchClass();
  };

  const handleManageSimulations = () => {
    navigate(`/main/classes/${classId}/simulations`);
  };

  const handleManageStudents = () => {
    navigate(`/main/classes/${classId}/students`);
  };

  const handleManageTeachers = () => {
    navigate(`/main/classes/${classId}/teachers`);
  };

  const ActionBar = (
    <>
      {Utils.checkPermissions('Admin of School', classData) && (
        <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleEditClass}>
          Edit Class
        </Button>
      )}
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageSimulations}>
        Simulations
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageStudents}>
        Students
      </Button>
      <Button size="small" type="primary" onClick={handleManageTeachers}>
        Teachers
      </Button>
    </>
  );

  useEffect(() => {
    fetchClass();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card size="small" title="Manage Class" extra={ActionBar}>
        <Form labelCol={{ span: 2 }} disabled>
          <Form.Item label="Name">
            <Input value={classData?.className} />
          </Form.Item>
          <Form.Item label="School">
            <Input value={classData?.schoolName} />
          </Form.Item>
          <Form.Item label="Year">
            <Input value={classData?.year} />
          </Form.Item>
          <Form.Item label="Term">
            <Input value={classData?.termName} />
          </Form.Item>
          <Form.Item label="Section">
            <Input value={classData?.section} />
          </Form.Item>
          <Form.Item label="Grade">
            <Input value={classData?.gradeName} />
          </Form.Item>
          <Form.Item label="Subject">
            <Input value={classData?.subject} />
          </Form.Item>
        </Form>
      </Card>
      <ClassModal currentClass={currentClass} onClose={handleCloseClassModal} onSave={handleClassSaved} />
    </>
  );
};
