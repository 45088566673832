import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';

const WEAPONS = ['Light Weapon', 'Medium Weapon', 'Heavy Weapon'];
const HUMANS = ['Soldier', 'Junior Military Officer', 'Senior Military Officer'];
const MULTIPLIERS = [2, 6, 18];

const getResourceList = (resourceList, simulationCountry, list, isAttacker = true) => {
  if (!simulationCountry) return [];

  const scResources = simulationCountry.simulationCountryResourceViewList;
  const lastWar =
    simulationCountry.simulationCountryWarObjectList[simulationCountry.simulationCountryWarObjectList.length - 1];
  const warResources = isAttacker ? lastWar.resourceTransactionArrayAttacker : lastWar.resourceTransactionArrayAttacked;

  const resources = list.map(key => {
    const resource = resourceList.find(r => r.resourceNameLocalized === key || r.resourceId === key);
    const scResource = scResources.find(scr => scr.resourceId === resource.resourceId);
    const warResource = warResources.find(wr => wr.ResourceId === resource.resourceId);

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;

    return {
      resourceId: resource.resourceId,
      resourceNameLocalized: resource.resourceNameLocalized,
      iconUrl,
      remainingQuantity: scResource.remainingQuantity,
      transactionUses: warResource?.TransactionUses ?? 0,
      transactionQuantity: warResource?.TransactionQuantity ?? 0,
    };
  });

  return resources;
};

export const Report = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { simulationCountry } = useSimulationContext();
  const resourceList = DataService.getItem('resourceList');
  const [oppositeCountry, setOppositeCountry] = useState(null);

  const lastWar = simulationCountry
    ? simulationCountry.simulationCountryWarObjectList[simulationCountry.simulationCountryWarObjectList.length - 1]
    : null;
  const isAttacker = useMemo(() => {
    if (!lastWar) return true;
    return lastWar.simulationCountryIdAttackedAnother === +params.simulationCountryId;
    // eslint-disable-next-line
  }, [lastWar]);
  const aWeapons = getResourceList(resourceList, simulationCountry, WEAPONS, isAttacker);
  const aHumans = getResourceList(resourceList, simulationCountry, HUMANS, isAttacker);
  const attackingForce = useMemo(() => {
    if (!lastWar) return 0;
    return lastWar.lightPowerQuantity * 2 + lastWar.mediumPowerQuantity * 6 + lastWar.heavyPowerQuantity * 18;
  }, [lastWar]);
  const cost = isAttacker ? Math.max(2, attackingForce * 0.1) : 1;
  const won = (isAttacker && lastWar?.warResult > 0) || (!isAttacker && lastWar?.warResult < 0);

  const dWeapons = getResourceList(resourceList, isAttacker ? oppositeCountry : simulationCountry, WEAPONS);
  const dHumans = getResourceList(resourceList, isAttacker ? oppositeCountry : simulationCountry, HUMANS);
  const defendingForce = dWeapons.reduce(
    (sum, _, index) =>
      sum + Math.min(dWeapons[index].remainingQuantity, dHumans[index].remainingQuantity) * MULTIPLIERS[index],
    0,
  );

  const gainLoss = useMemo(() => {
    if (!lastWar) return [];
    const resourceIds = [lastWar.resourceIdDemand1, lastWar.resourceIdDemand2];
    return getResourceList(resourceList, simulationCountry, resourceIds);
    // eslint-disable-next-line
  }, [simulationCountry, lastWar]);

  const loadData = async () => {
    const lastWar =
      simulationCountry.simulationCountryWarObjectList[simulationCountry.simulationCountryWarObjectList.length - 1];
    const oppositeSCId =
      lastWar.simulationCountryIdAttackedAnother === +params.simulationCountryId
        ? lastWar.simulationCountryIdAttackedByAnother
        : lastWar.simulationCountryIdAttackedAnother;
    const data = await ApiService.retrieveSimulationCountry(oppositeSCId);
    setOppositeCountry(data.result);
  };

  const handleDismiss = () => {
    navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/national-briefing`);
  };

  useEffect(() => {
    if (simulationCountry) {
      loadData();
    }
    // eslint-disable-next-line
  }, [simulationCountry]);

  if (!simulationCountry || !oppositeCountry) return null;

  return (
    <>
      <Card title="War Report" size="small">
        <table>
          <tbody>
            <tr>
              <td>Event:</td>
              <td>
                Your country {isAttacker ? 'attacked' : 'was invaded by'} {oppositeCountry.countryName} (Military Points{' '}
                {attackingForce})
              </td>
            </tr>
            <tr>
              <td>Result:</td>
              <td>You {won ? 'won' : 'lost'} the war</td>
            </tr>
            <tr>
              <td>Attacking Force:</td>
              <td>{attackingForce}</td>
            </tr>
            <tr>
              <td>Defending Force:</td>
              <td>{defendingForce}</td>
            </tr>
            <tr>
              <td>Margin of Victory:</td>
              <td>{isAttacker ? attackingForce - defendingForce * 3 : 0}</td>
            </tr>
            <tr>
              <td>Cost:</td>
              <td>{cost.toFixed(1)} Gold</td>
            </tr>
          </tbody>
        </table>
      </Card>
      <Card className="mt-16" title={`Products or Resources ${isAttacker ? 'Gained' : 'Lost'}`} size="small">
        <Row className="mb-8" gutter={16}>
          {gainLoss.map(gl => (
            <Col span={12} key={`resource-${gl.resourceId}`}>
              <Card>
                <div className="d-flex justify-space-between align-center">
                  <img width={64} src={gl.iconUrl} alt={gl.resourceNameLocalized} />
                  <div className="text-right">
                    <div>
                      <b>{gl.resourceNameLocalized}</b>
                    </div>
                    <div style={{ fontSize: 24 }} className={won ? 'text-success' : 'text-error'}>
                      {won ? gl.transactionQuantity : -gl.transactionQuantity}
                    </div>
                    <div style={{ fontSize: 14 }} className="text-secondary">
                      Units {won ? 'Gained' : 'Lost'}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
      <Card className="mt-16" title="Military Losses" size="small">
        {aWeapons.map((_, index) => (
          <Row className="mb-8" gutter={16} key={`military-${index}`}>
            <Col span={12}>
              <Card>
                <div className="d-flex justify-space-between align-center">
                  <img width={64} src={aHumans[index].iconUrl} alt={aHumans[index].resourceNameLocalized} />
                  <div className="text-right">
                    <div>
                      <b>{aHumans[index].resourceNameLocalized}</b>
                    </div>
                    <div style={{ fontSize: 24 }} className="text-error">
                      {isAttacker ? aHumans[index].transactionUses : aHumans[index].transactionQuantity}
                    </div>
                    <div style={{ fontSize: 14 }} className="text-secondary">
                      {aHumans[index].remainingQuantity} Units Remain
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <div className="d-flex justify-space-between align-center">
                  <img width={64} src={aWeapons[index].iconUrl} alt={aWeapons[index].resourceNameLocalized} />
                  <div className="text-right">
                    <div>
                      <b>{aWeapons[index].resourceNameLocalized}</b>
                    </div>
                    <div style={{ fontSize: 24 }} className="text-error">
                      {isAttacker ? aWeapons[index].transactionUses : aWeapons[index].transactionQuantity}
                    </div>
                    <div style={{ fontSize: 14 }} className="text-secondary">
                      {aWeapons[index].remainingQuantity} Units Remain
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        ))}
      </Card>
      <div style={{ margin: 32 }} className="d-flex justify-center">
        <Button style={{ width: 200 }} type="primary" onClick={handleDismiss}>
          Dismiss War Report
        </Button>
      </div>
    </>
  );
};
