import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal, Select } from 'antd';
import ApiService from '../../../services/ApiService';

export const SimulationCountryExistingStudentModal = ({
  simulationId,
  simulationCountryId,
  classStudents,
  student,
  onClose,
  onSave,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const classStudentOptions = classStudents.map(student => ({
    label: `${student.lastName}, ${student.firstName} (${student.userName})`,
    value: student.userId,
  }));

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values, countryId: simulationCountryId };
      await ApiService.addExistingSimulationCountryStudent(simulationId, payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, student]);

  return (
    <Modal
      title="Add Student to Simulation Country"
      open={!!student}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add
        </Button>,
      ]}
    >
      {contextHolder}
      <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={false} autoComplete="off">
        <Form.Item
          label="Student"
          name="userId"
          rules={[
            {
              required: true,
              message: 'Student is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select student"
            optionFilterProp="label"
            fieldNames={{ label: 'label', value: 'value' }}
            options={classStudentOptions}
          />
        </Form.Item>
        <Form.Item label="Class Section" name="classSection">
          <Input />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
