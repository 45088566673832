import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, InputNumber, Modal } from 'antd';
import ApiService from '../../../services/ApiService';

export const SimulationCountryResourceModal = ({ simulationCountryId, resource, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const iconName = resource?.iconUrl?.split('/')?.pop();
  const iconUrl = `/images/icons/resources/${iconName}`;

  const handleFormValueChange = e => {
    if ('currentQuantity' in e) {
      const projectedQuantity = +e.currentQuantity;
      form.setFieldValue('projectedQuantity', projectedQuantity);
      form.setFieldValue('projectedAnnualUses', projectedQuantity * resource.usesPerItemPerYear);
      form.setFieldValue('projectedLifetimeUses', projectedQuantity * resource.usesPerItem);
    }
  };

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = {
        resourceId: resource.resourceId,
        usesToAdd: 0,
        quantityToAdd: values.currentQuantity - resource.remainingQuantity,
      };
      await ApiService.updateSimulationCountryResource(simulationCountryId, payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (resource) {
      form.setFieldValue('currentQuantity', resource.remainingQuantity);
      form.setFieldValue('projectedQuantity', resource.remainingQuantity);
      form.setFieldValue('projectedAnnualUses', resource.currentSimulationYearRemainingUses);
      form.setFieldValue('projectedLifetimeUses', resource.remainingUses);
    }
  }, [form, resource]);

  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Edit Resource - </span>
      <img style={{ marginLeft: 8 }} width={20} src={iconUrl} alt={resource?.resourceNameLocalized} />
      <span style={{ paddingLeft: 8 }}>{resource?.resourceNameLocalized}</span>
    </div>
  );

  return (
    <Modal
      title={title}
      open={!!resource}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={resource}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
        onValuesChange={handleFormValueChange}
      >
        <Form.Item label={`New Quantity`} name="currentQuantity">
          <InputNumber className="w-100" min={0} />
        </Form.Item>
        {/* <Form.Item label="Projected Quantity" name="projectedQuantity">
          <Input disabled />
        </Form.Item> */}
        <Form.Item label="Projected Annual Uses" name="projectedAnnualUses">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Projected Lifetime Uses" name="projectedLifetimeUses">
          <Input disabled />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
