import { useEffect, useState } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { Auth } from './layouts/Auth';
import { Main } from './layouts/Main';
import { Portal } from './layouts/Portal';
import { Simulation } from './layouts/Simulation';

import { Login } from './pages/Auth/Login';
import { MyProfile } from './pages/Main/MyProfile';
import { Schools } from './pages/Main/Schools';
import { SchoolDetails } from './pages/Main/SchoolDetails';
import { SchoolClasses } from './pages/Main/SchoolClasses';
import { SchoolStudents } from './pages/Main/SchoolStudents';
import { SchoolTeachers } from './pages/Main/SchoolTeachers';
import { SchoolAdmins } from './pages/Main/SchoolAdmins';
import { Classes } from './pages/Main/Classes';
import { ClassDetails } from './pages/Main/ClassDetails';
import { ClassSimulations } from './pages/Main/ClassSimulations';
import { ClassStudents } from './pages/Main/ClassStudents';
import { ClassTeachers } from './pages/Main/ClassTeachers';
import { Simulations } from './pages/Main/Simulations';
import { SimulationDetails } from './pages/Main/SimulationDetails';
import { SimulationCountries } from './pages/Main/SimulationCountries';
import { SimulationCountryDetails } from './pages/Main/SimulationCountryDetails';
import { SimulationCountryStudents } from './pages/Main/SimulationCountryStudents';
import { SimulationCountryResources } from './pages/Main/SimulationCountryResources';
import { SimulationCountryGoals } from './pages/Main/SimulationCountryGoals';
import { SimulationStudents } from './pages/Main/SimulationStudents';
import { SimulationYears } from './pages/Main/SimulationYears';
import { Members } from './pages/Main/Members';
import { MemberDetails } from './pages/Main/MemberDetails';
import { Welcome } from './pages/Portal/Welcome';
import { CountrySummary } from './pages/Portal/CountrySummary';
import { Welcome as SimulationWelcome } from './pages/Portal/Simulation/Welcome';
import { WorldBriefing as SimulationWorldBriefing } from './pages/Portal/Simulation/WorldBriefing';
import { NationalBriefing as SimulationNationalBriefing } from './pages/Portal/Simulation/NationalBriefing';
import { Goals as SimulationGoals } from './pages/Portal/Simulation/Goals';
import { Production as SimulationProduction } from './pages/Portal/Simulation/Production';
import { Trade as SimulationTrade } from './pages/Portal/Simulation/Trade';
import { Population as SimulationPopulation } from './pages/Portal/Simulation/Population';
import { Military as SimulationMilitary } from './pages/Portal/Simulation/Military';
import { FeedThePeople as SimulationFeedThePeople } from './pages/Portal/Simulation/FeedThePeople';

import ApiService from './services/ApiService';
import SessionService from './services/SessionService';

function App() {
  const isLoggedIn = SessionService.isLoggedIn();
  const [isLoading, setIsLoading] = useState(false);
  const [isStudent, setIsStudent] = useState(false);

  const fetchAccountRoles = async () => {
    setIsLoading(true);
    const { resultList } = await ApiService.retrieveAccountRoleList();
    setIsStudent(
      resultList.every(role =>
        ['Student of School', 'Student of Class', 'Participant of Simulation', 'Admin of Simulation'].includes(
          role.roleName,
        ),
      ),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchAccountRoles();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="/auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route index element={<Navigate to="login" replace />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    );
  }

  if (isLoading) {
    return null;
  }

  if (!isStudent) {
    return (
      <Routes>
        <Route path="/main" element={<Main />}>
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="schools" element={<Schools />} />
          <Route path="schools/:schoolId" element={<SchoolDetails />} />
          <Route path="schools/:schoolId/classes" element={<SchoolClasses />} />
          <Route path="schools/:schoolId/students" element={<SchoolStudents />} />
          <Route path="schools/:schoolId/teachers" element={<SchoolTeachers />} />
          <Route path="schools/:schoolId/admins" element={<SchoolAdmins />} />
          <Route path="classes" element={<Classes />} />
          <Route path="classes/:classId" element={<ClassDetails />} />
          <Route path="classes/:classId/simulations" element={<ClassSimulations />} />
          <Route path="classes/:classId/students" element={<ClassStudents />} />
          <Route path="classes/:classId/teachers" element={<ClassTeachers />} />
          <Route path="simulations" element={<Simulations />} />
          <Route path="simulations/:simulationId" element={<SimulationDetails />} />
          <Route path="simulations/:simulationId/countries" element={<SimulationCountries />} />
          <Route
            path="simulations/:simulationId/countries/:simulationCountryId"
            element={<SimulationCountryDetails />}
          />
          <Route
            path="simulations/:simulationId/countries/:simulationCountryId/students"
            element={<SimulationCountryStudents />}
          />
          <Route
            path="simulations/:simulationId/countries/:simulationCountryId/resources"
            element={<SimulationCountryResources />}
          />
          <Route
            path="simulations/:simulationId/countries/:simulationCountryId/goals"
            element={<SimulationCountryGoals />}
          />
          <Route path="simulations/:simulationId/students" element={<SimulationStudents />} />
          <Route path="simulations/:simulationId/years" element={<SimulationYears />} />
          <Route path="members" element={<Members />} />
          <Route path="members/:memberId" element={<MemberDetails />} />
          <Route index element={<Navigate to="my-profile" replace />} />
          <Route path="*" element={<Navigate to="my-profile" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/main/my-profile" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/portal" element={<Portal />}>
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="countries/:simulationCountryId/summary" element={<CountrySummary />} />
      </Route>
      <Route path="/portal/simulations/:simulationCountryId" element={<Simulation />}>
        <Route path="welcome" element={<SimulationWelcome />} />
        <Route path="world-briefing" element={<SimulationWorldBriefing />} />
        <Route path="national-briefing" element={<SimulationNationalBriefing />} />
        <Route path="goals" element={<SimulationGoals />} />
        <Route path="production" element={<SimulationProduction />} />
        <Route path="trade" element={<SimulationTrade />} />
        <Route path="population" element={<SimulationPopulation />} />
        <Route path="military" element={<SimulationMilitary />} />
        <Route path="feed-the-people" element={<SimulationFeedThePeople />} />
      </Route>
      <Route path="*" element={<Navigate to="/portal/welcome" replace />} />
    </Routes>
  );
}

export default App;
