import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { SIMULATIONS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SimulationModal } from './SimulationModal';

export const Simulations = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [simulations, setSimulations] = useState([]);
  const [simulation, setSimulation] = useState(null);

  const fetchClasses = async () => {
    try {
      const data = await ApiService.retrieveClassList();
      if (data.success) {
        setClasses(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchSimulations = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSimulationList();
      if (data.success) {
        setSimulations(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddSimulation = () => {
    setSimulation({});
  };

  const handleEditSimulation = simulation => {
    setSimulation(simulation);
  };

  const handleCloseSimulationModal = () => {
    setSimulation(null);
  };

  const handleSimulationSaved = () => {
    setSimulation(null);
    fetchSimulations();
  };

  const handleDeleteSimulation = async simulation => {
    try {
      await ApiService.deleteSimulation(simulation.simulationId);
      await fetchSimulations();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchSimulations();
  }, []);

  const simulationsTableColumns = [
    ...SIMULATIONS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Button size="small" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditSimulation(row)} />
          <Popconfirm title="Are you sure to delete this simulation?" onConfirm={() => handleDeleteSimulation(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];
  simulationsTableColumns[0].render = (_, row) => (
    <NavLink to={`/main/simulations/${row.simulationId}`}>{row.simulationName}</NavLink>
  );

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddSimulation}>
        Add Simulation
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Simulations" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={simulationsTableColumns}
          dataSource={simulations}
          rowKey="simulationId"
          bordered
          head
        />
      </Card>
      <SimulationModal
        classes={classes}
        simulation={simulation}
        onClose={handleCloseSimulationModal}
        onSave={handleSimulationSaved}
      />
    </>
  );
};
