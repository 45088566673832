import _ from 'lodash';

export const LANGUAGE_LIST = [
  {
    label: 'English',
    value: 1,
  },
  {
    label: 'Español',
    value: 3,
  },
];

export const GENDER_LIST = [
  {
    label: 'Not Specified',
    value: 0,
  },
  {
    label: 'Male',
    value: -1,
  },
  {
    label: 'Female',
    value: 1,
  },
];

export const DOB_YEAR_LIST = (() => {
  const currentYear = new Date().getFullYear();
  return _.range(1900, currentYear + 1)
    .map(year => ({ label: year, value: year }))
    .reverse();
})();

export const SIMULATION_COUNTRY_STATES = {
  INACTIVE: 0,
  NEW: 11,
  NEW_YEAR: 21,
  IN_YEAR: 31,
  IN_YEAR_ATTACKED_BY_ANOTHER: 32,
  IN_YEAR_ATTACKED_ANOTHER: 33,
  END_YEAR: 41,
  END_SIMULATION: 51,
};

export const SIMULATION_COUNTRY_STATE_LIST = {
  0: 'Inactive',
  11: 'New',
  21: 'New Year',
  31: 'In Year',
  32: 'In year attacked by another',
  33: 'In year attacked another',
  41: 'End of Year',
  51: 'End of Start',
};

export const EVENT_DETAILS_MAP = {
  1: {
    title: 'Drought in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Drought_96.png',
    result: 'Rice crop withered',
  },
  2: {
    title: 'Drought in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Drought_96.png',
    result: 'Tubers Land useless',
  },
  3: {
    title: 'Freezing weather in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Freezing_Weather_96.png',
    result: 'Grazing Land useless',
  },
  4: {
    title: 'Freezing weather in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Freezing_Weather_96.png',
    result: 'Cotton Land hit',
  },
  55: {
    title: 'Flooding in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Flooding_96.png',
    result: 'Recreational Land useless',
  },
  56: {
    title: 'Flooding in part of your country',
    iconUrl: '/images/pages/simulation/welcome/Flooding_96.png',
    result: 'Specialty Foods Land useless',
  },
  5: {
    title: 'Natural Oil Seepage in water resources',
    iconUrl: '/images/pages/simulation/welcome/Oil_Seepage_96.png',
    result: 'Riparian Land polluted until further notice',
  },
  6: {
    title: 'Outbreak of hoof and mouth disease in neighboring country',
    iconUrl: '/images/pages/simulation/welcome/Hoof_And_Mouth_Outbreak_96.png',
    result: 'Livestock infected',
  },
  7: {
    title: 'Infestation of destructive forest moth in neighboring country',
    iconUrl: '/images/pages/simulation/welcome/Destructive_Forest_Moth_96.png',
    result: 'Forest Land destroyed by these insects',
  },
  38: {
    title: 'Hurricane',
    iconUrl: '/images/pages/simulation/welcome/Hurricane_96.png',
    result: 'Equipment and buildings affected',
  },
  57: {
    title: 'Typhoon',
    iconUrl: '/images/pages/simulation/welcome/Typhoon_96.png',
    result: 'Equipment and buildings affected',
  },
  58: {
    title: 'Earthquake',
    iconUrl: '/images/pages/simulation/welcome/Earthquake_96.png',
    result: 'Equipment and buildings affected',
  },
  59: {
    title: 'Volcanic eruption',
    iconUrl: '/images/pages/simulation/welcome/Volcanic_Eruption_96.png',
    result: 'Equipment and buildings affected',
  },
  60: {
    title: 'Tornado',
    iconUrl: '/images/pages/simulation/welcome/Tornado_96.png',
    result: 'Equipment and buildings affected',
  },
  39: {
    title: 'Cholera epidemic',
    iconUrl: '/images/pages/simulation/welcome/Cholera_Epidemic_96.png',
    result: 'Inoculate your population',
  },
  61: {
    title: 'Outbreak of influenza',
    iconUrl: '/images/pages/simulation/welcome/Influenza_Outbreak_96.png',
    result: 'Seek prompt medical advice',
  },
  62: {
    title: 'Measles spreading',
    iconUrl: '/images/pages/simulation/welcome/Measles_96.png',
    result: 'Give preventive vaccinations',
  },
  63: {
    title: 'Intestinal parasites weaken the people',
    iconUrl: '/images/pages/simulation/welcome/Intestinal_Parasite_96.png',
    result: 'Use curative medicines',
  },
  64: {
    title: 'Malaria on the rise',
    iconUrl: '/images/pages/simulation/welcome/Malaria_96.png',
    result: 'Distribute does of anti-malarial medicines',
  },
  22: {
    title: 'Heavy rain exposes mineral vein',
    result: 'Bauxite Deposit found',
  },
  23: {
    title: 'Earth movement reveals dark ore',
    result: 'Coal Deposit found',
  },
  24: {
    title: 'Mineral ore found in remote region',
    result: 'Copper Deposit found',
  },
  25: {
    title: 'Change in climate has positive effect on land use',
    result: 'Cotton Land available',
  },
  26: {
    title: 'Natural reseeding results in useful resource',
    result: 'Forest Land available',
  },
  27: {
    title: 'Mineral flakes and nuggets discovered in mountain streams',
    result: 'Gold Deposit found',
  },
  28: {
    title: 'New breed adapts well to previously unusable shrub land',
    result: 'Grazing Land available',
  },
  29: {
    title: 'Magnetic irregularities suggest hidden mineral',
    result: 'Iron Deposit found',
  },
  30: {
    title: 'Geologic formation indicates energy reserves',
    result: 'Petroleum Deposit found',
  },
  31: {
    title: 'Hybrid plant successful in wet or dry fields',
    result: 'Tubers Land available',
  },
  32: {
    title: 'Change in ocean current brings rich nutrients',
    result: 'Riparian Land available',
  },
  33: {
    title: 'River changes course; fertile flood plain now accessible',
    result: 'Wheat Land available',
  },
  52: {
    title: 'Renewed emphasis on outdoor activities generates interest in remote regions',
    result: 'Recreational Land available',
  },
  53: {
    title: 'National promotion on developing luxury exports',
    result: 'Specialty Foods Land available',
  },
  54: {
    title: 'World wide interest in electronics puts premium on rare minerals',
    result: 'High Tech Mineral Deposit available',
  },
};

export const GOALS = [
  {
    title: 'Achieve High GDP per Capita',
    children: [
      {
        title: 'Current GDP Per Capita',
      },
    ],
  },
  {
    title: 'Conserve Natural Resources',
    children: [
      {
        title: 'Bauxite Deposit % Depleted',
      },
      {
        title: 'Coal Deposit % Depleted',
      },
      {
        title: 'Cotton Land % Depleted',
      },
      {
        title: 'Forest Land % Depleted',
      },
      {
        title: 'Gold Deposit % Depleted',
      },
      {
        title: 'Grazing Land % Depleted',
      },
      {
        title: 'Iron Deposit % Depleted',
      },
      {
        title: 'Petroleum Deposit % Depleted',
      },
      {
        title: 'Tubers Land % Depleted',
      },
      {
        title: 'Riparian Land % Depleted',
      },
      {
        title: 'Copper Deposit % Depleted',
      },
      {
        title: 'Grains Land % Depleted',
      },
      {
        title: 'Specialty Foods Land % Depleted',
      },
      {
        title: 'High Tech Mineral Deposit % Depleted',
      },
    ],
  },
  {
    title: 'Develop Agriculture and Mining',
    children: [
      {
        title: 'Mining Equipment',
      },
      {
        title: 'Ecologist',
      },
      {
        title: 'Agricultural Equipment',
      },
    ],
  },
  {
    title: 'Develop Business Infrastructure',
    children: [
      {
        title: 'Complete Communications System',
      },
      {
        title: 'Construction Equipment',
      },
      {
        title: 'Production Equipment',
      },
      {
        title: 'Engineer',
      },
      {
        title: 'Management Level 1',
      },
      {
        title: 'Management Level 2',
      },
      {
        title: 'Management Level 3',
      },
      {
        title: 'Management Level 4',
      },
    ],
  },
  {
    title: 'Develop Education Infrastructure',
    children: [
      {
        title: 'University',
      },
      {
        title: 'Secondary School',
      },
      {
        title: 'Primary School',
      },
      {
        title: 'Teacher',
      },
    ],
  },
  {
    title: 'Develop Health Services',
    children: [
      {
        title: 'Hospital',
      },
      {
        title: 'Clinic',
      },
      {
        title: 'Pharmacy',
      },
      {
        title: 'Doctor',
      },
    ],
  },
  {
    title: 'Develop Life Style',
    children: [
      {
        title: 'Beautification of Land',
      },
      {
        title: 'National Park',
      },
      {
        title: 'Cultural Facility',
      },
      {
        title: 'Philanthropist',
      },
    ],
  },
  {
    title: 'Develop Military Strength',
    children: [
      {
        title: 'Heavy Weapon',
      },
      {
        title: 'Senior Military Officer',
      },
      {
        title: 'Medium Weapon',
      },
      {
        title: 'Junior Military Officer',
      },
      {
        title: 'Light Weapon',
      },
      {
        title: 'Soldier',
      },
    ],
  },
  {
    title: 'Educate People',
    children: [
      {
        title: 'Total Number of People Skilled or Professional as Percent of Population',
      },
    ],
  },
  {
    title: 'Limit Pollution',
    children: [
      {
        title: 'Pollution',
      },
      {
        title: 'Pollution Clean-Up',
      },
      {
        title: 'Net Pollution',
      },
    ],
  },
  {
    title: 'Minimize Deaths',
    children: [
      {
        title: 'Total Number of Deaths',
      },
    ],
  },
  {
    title: 'Provide Consumer Goods',
    children: [
      {
        title: 'Total Major Consumer Purchases Per Capita @ 50 PTS',
      },
      {
        title: 'Total Durable Consumer Goods Per Capita @ 20 PTS',
      },
      {
        title: 'Total Non-Durable Consumer Good Per Capita @ 10 PTS',
      },
      {
        title: 'Bonus Points From Foreign Tourism Tickets @ 10 PTS',
      },
      {
        title: 'Bonus Points From Eating Imported Specialty Foods @ 10 PTS',
      },
    ],
  },
];
