import { useEffect, useState } from 'react';
import { notification, Button, Form, Input, Modal } from 'antd';
import ApiService from '../../../services/ApiService';

export const ClassSimulationModal = ({ currentClass, simulation, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = {
        classId: currentClass.classId,
        simulationName: values.simulationName,
      };
      await ApiService.addSimulation(payload);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, simulation]);

  return (
    <Modal
      title="Add Simulation"
      open={!!simulation}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={simulation}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Simulation Name"
          name="simulationName"
          rules={[
            {
              required: true,
              message: 'Simulation name is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Simulation Class">
          <Input value={currentClass?.className} disabled />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
