import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card } from 'antd';
import { Reorder } from 'framer-motion';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import { SIMULATION_COUNTRY_STATES } from '../../../../data';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';

const StyledCard = styled(Card)`
  &.locked {
    opacity: 50%;
    pointer-events: none;
  }

  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Page = styled.div`
  .goal {
    display: flex;
    margin-bottom: 8px;
    border: 1px solid #333;

    .goal-index {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      background-color: #333;
      color: #fff;
      font-size: 20px;
    }

    .goal-details {
      padding: 4px 8px;
    }

    .goal-title {
      margin: 0 0 6px;
      line-height: 1;
    }

    .goal-description {
      margin: 0;
      line-height: 1;
      font-size: 12px;
    }
  }
`;

export const Goals = () => {
  const { simulationCountry, fetchSimulationCountry } = useSimulationContext();
  const goalTypeList = DataService.getItem('goalTypeList');
  const [goals, setGoals] = useState([]);
  const goalsAreLocked = simulationCountry?.goalsAreLocked;

  const loadGoals = async () => {
    if (simulationCountry.simulationCountryGoalTypeViewList.length > 0) {
      const goalList = simulationCountry.simulationCountryGoalTypeViewList.map(goal =>
        goalTypeList.find(g => g.goalTypeId === +goal.goalTypeId),
      );
      setGoals(goalList);
    } else {
      setGoals([...goalTypeList]);
    }
  };

  const handleSave = async () => {
    const result = await Swal.fire({
      title: 'Do you want to save this goal ranking?',
      icon: 'question',
      html: `
        <p style="text-align: left;">
          You have specified the following goal ranking:
        </p>
        <div style="padding: 8px 4px; border: 1px solid #ccc; background: #eee; color: #888; text-align: left;">
          ${goals
            .map(
              (goal, index) => `
            <div>${(index + 1).toString().padStart(2, '0')} - ${goal.goalTypeNameLocalized}</div>
          `,
            )
            .join('\n')}
        </div>
        <div style="margin-top: 8px; color: red;">
          The first goal(#1) should be the most important.
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Save',
    });
    if (result.isConfirmed) {
      const goalTypeIds = JSON.stringify(goals.map(goal => goal.goalTypeId.toString()));
      await ApiService.updateSimulationCountryGoalTypeArray(simulationCountry.simulationCountryId, goalTypeIds);
      if (simulationCountry.simulationCountryState === SIMULATION_COUNTRY_STATES.NEW_YEAR) {
        await ApiService.advanceSimulationCountry(simulationCountry.simulationCountryId, {
          carbohydrateObjectListString: '',
          proteinObjectListString: '',
          waterObjectListString: '',
          specialObjectListString: '',
        });
      }
      await fetchSimulationCountry();
      Swal.fire({
        icon: 'info',
        title: 'Next Order of Business',
        html: `
          <div style="text-align: left;">
            <p>1. Review Naional Briefing / Resources and Products.</p>
            <p>2. Begin Production based on your goals and priorities.</p>
            <p>3. Concentrate first on getting food and water for your people.</p>
            <p>
              From there you're on your own.
              For more suggestions go to the blue How To Play button at the top right.<br />
            </p>
            <p style="text-align: center;">Good Luck!</p>
          </div>
        `,
      });
    }
  };

  useEffect(() => {
    if (simulationCountry) {
      loadGoals();
    }
    // eslint-disable-next-line
  }, [simulationCountry]);

  return (
    <StyledCard className={goalsAreLocked ? 'locked' : ''} size="small" title="Goals">
      <Page>
        <p>
          <b>MAIN GOAL:</b> To have a balanced, thriving, and sustainable country by achieving the General National
          Objectives and by creating and maintaining at least one of each item within every Specific Economic Sector.
          See 'Evaluation & Score' and 'Economic Goals' tabs within National Briefing on the dashboard.
        </p>
        <p>
          DRAG THE GOALS UP AND DOWN TO DECIDE THEIR PRIORITY. ONCE SAVED THE GOALS ARE LOCKED UNTIL YOUR TEACHER
          UNLOCKS THEM AT WHICH TIME YOU MAY RESET THEIR PRIORITY.
        </p>
        <Reorder.Group style={{ paddingLeft: 0, listStyle: 'none' }} axis="y" values={goals} onReorder={setGoals}>
          {goals.map((goal, index) => (
            <Reorder.Item key={goal.goalTypeId} value={goal}>
              <div className="goal">
                <div className="goal-index">{index + 1}</div>
                <div className="goal-details">
                  <p className="goal-title">
                    <b>{goal.goalTypeNameLocalized}</b>
                  </p>
                  <p className="goal-description">{goal.goalTypeDescriptionLocalized}</p>
                </div>
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </Page>
      <div className="d-flex justify-center">
        <Button style={{ marginBottom: 16, width: 240 }} type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </StyledCard>
  );
};
