import { useState } from 'react';
import styled from 'styled-components';
import { Card, Tabs } from 'antd';
import { Nutrition } from './Nutrition';
import { Reproduction } from './Reproduction';
import { Migration } from './Migration';
import { History } from './History';

const TAB_ITEMS = [
  {
    key: 'nutrition',
    label: 'Nutrition',
  },
  {
    key: 'reproduction',
    label: 'Reproduction',
  },
  {
    key: 'migration',
    label: 'Migration',
  },
  {
    key: 'history',
    label: 'History',
  },
];

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: #fff;
  }

  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   color: #333 !important;
  // }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

export const Population = () => {
  const [activeKey, setActiveKey] = useState(TAB_ITEMS[0].key);

  const tabs = (
    <StyledTabs
      hideAdd
      type="card"
      size="small"
      items={TAB_ITEMS}
      activeKey={activeKey}
      onChange={key => setActiveKey(key)}
    />
  );

  return (
    <StyledCard size="small" title="Population" extra={tabs}>
      {activeKey === TAB_ITEMS[0].key && <Nutrition />}
      {activeKey === TAB_ITEMS[1].key && <Reproduction />}
      {activeKey === TAB_ITEMS[2].key && <Migration />}
      {activeKey === TAB_ITEMS[3].key && <History />}
    </StyledCard>
  );
};
