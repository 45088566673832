import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MEMBERS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { MemberModal } from './MemberModal';
import Utils from '../../../utils';

export const Members = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(null);

  const fetchMembers = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveMemberList();
      if (data.success) {
        data.resultList.sort((a, b) => a.lastName.localeCompare(b.lastName));
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddMember = () => {
    setMember({});
  };

  const handleEditMember = member => {
    setMember(member);
  };

  const handleCloseMemberModal = () => {
    setMember(null);
  };

  const handleMemberSaved = () => {
    setMember(null);
    fetchMembers();
  };

  const handleDeleteMember = async member => {
    try {
      await ApiService.deleteMember(member.userId);
      await fetchMembers();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const membersTableColumns = [
    ...MEMBERS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Button size="small" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditMember(row)} />
          {Utils.checkPermissions('Admin of School') && (
            <Popconfirm title="Are you sure to delete this member?" onConfirm={() => handleDeleteMember(row)}>
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </ActionColumn>
      ),
    },
  ];
  membersTableColumns[2].render = (_, row) => <NavLink to={`/main/members/${row.userId}`}>{row.userName}</NavLink>;

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddMember}>
        Add Member
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Members" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={membersTableColumns}
          dataSource={members}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <MemberModal member={member} onClose={handleCloseMemberModal} onSave={handleMemberSaved} />
    </>
  );
};
