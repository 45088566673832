import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import DataService from '../../../../../services/DataService';
import Utils from '../../../../../utils';

const WEAPONS = ['Light Weapon', 'Medium Weapon', 'Heavy Weapon'];
const HUMANS = ['Soldier', 'Junior Military Officer', 'Senior Military Officer'];
const MULTIPLIERS = [2, 6, 18];

const getResourceList = (resourceList, scResources, list) => {
  if (!scResources) return [];

  const resources = list.map(name => {
    const resource = resourceList.find(r => r.resourceNameLocalized === name);
    const scResource = scResources.find(scr => scr.resourceId === resource.resourceId);

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;

    return {
      resourceId: resource.resourceId,
      resourceNameLocalized: resource.resourceNameLocalized,
      iconUrl,
      remainingQuantity: WEAPONS.includes(resource.resourceNameLocalized)
        ? scResource.currentSimulationYearRemainingUses
        : scResource.remainingUsefulQuantity,
    };
  });

  return resources;
};

export const Summary = ({ onPlanInvasion }) => {
  const { simulationCountry } = useSimulationContext();
  const navigate = useNavigate();
  const params = useParams();
  const resourceList = DataService.getItem('resourceList');

  const weapons = getResourceList(resourceList, simulationCountry?.simulationCountryResourceViewList, WEAPONS);
  const humans = getResourceList(resourceList, simulationCountry?.simulationCountryResourceViewList, HUMANS);

  const total = weapons.reduce(
    (sum, _, index) =>
      sum + Math.min(weapons[index].remainingQuantity, humans[index].remainingQuantity) * MULTIPLIERS[index],
    0,
  );

  const handlePlanInvasion = () => {
    const { totalGold } = Utils.calculateResourceValues(simulationCountry.simulationCountryResourceViewList);
    if (totalGold < 2) {
      Swal.fire({
        title: 'Insufficient Gold to Invade',
        icon: 'error',
        html: `<span style="color: red;">Your country does not have enough gold(2) to plan the invasion.</span>`,
      });
      return;
    }
    onPlanInvasion();
  };

  const handleMaintainDefense = () => {
    navigate(`/portal/simulations/${params.simulationCountryId}/national-briefing`);
  };

  if (!simulationCountry) return null;

  return (
    <>
      <p>Military Points (Actual equipment or personnel, or their uses, whichever is less)</p>
      {weapons.map((_, index) => (
        <Row className="mb-8" gutter={16} key={index}>
          <Col span={8}>
            <Card>
              <div className="d-flex justify-space-between align-center">
                <img width={64} src={weapons[index].iconUrl} alt={weapons[index].resourceNameLocalized} />
                <div>
                  <div>{weapons[index].resourceNameLocalized}</div>
                  <div style={{ fontSize: 24 }} className="text-right">
                    {weapons[index].remainingQuantity}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <div className="d-flex justify-space-between align-center">
                <img width={64} src={humans[index].iconUrl} alt={humans[0].resourceNameLocalized} />
                <div>
                  <div>{humans[index].resourceNameLocalized}</div>
                  <div style={{ fontSize: 24 }} className="text-right">
                    {humans[index].remainingQuantity}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="bg-error">
              <div className="text-right">
                {Math.min(weapons[index].remainingQuantity, humans[index].remainingQuantity)} x {MULTIPLIERS[index]} =
              </div>
              <div style={{ fontSize: 24 }} className="text-right">
                {Math.min(weapons[index].remainingQuantity, humans[index].remainingQuantity) * MULTIPLIERS[index]}
              </div>
            </Card>
          </Col>
        </Row>
      ))}
      <hr />
      <Row gutter={16}>
        <Col span={16}></Col>
        <Col span={8}>
          <Card className="bg-secondary">
            <div className="text-right">Total Military Points</div>
            <div style={{ fontSize: 24 }} className="text-right">
              {total}
            </div>
          </Card>
        </Col>
      </Row>
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} type="primary" onClick={handlePlanInvasion} disabled={total === 0}>
          Plan Invasion
        </Button>
        <Button className="ml-16" style={{ width: 200 }} type="primary" onClick={handleMaintainDefense}>
          Maintain Defense
        </Button>
      </div>
    </>
  );
};
