import { Form } from 'antd';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-text {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    color: #f00;
  }

  .logo-img {
    max-width: 560px;
    width: 100%;
  }

  .description {
    margin-bottom: 32px;
    max-width: 450px;
    text-align: center;
    color: #fff;
  }
`;

export const StyledForm = styled(Form)`
  margin-bottom: 160px;
  padding: 24px;
  max-width: 360px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);

  .form-title {
    margin-top: 0;
    text-align: center;
    color: #fff;
  }

  button {
    width: 100%;
  }
`;
