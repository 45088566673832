import { useEffect, useState } from 'react';
import { notification, Button, Form, Modal, Select } from 'antd';
import ApiService from '../../../services/ApiService';

export const ClassTeacherModal = ({ currentClass, members, teacher, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const memberOptions = members.map(member => ({
    label: `${member.firstName} ${member.lastName} (${member.userName})`,
    value: member.userId,
  }));

  const onFinish = async values => {
    setSubmitting(true);
    try {
      await ApiService.addClassTeacher(currentClass.classId, values.userId);
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, teacher]);

  return (
    <Modal
      title="Add Teacher"
      open={!!teacher}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Add
        </Button>,
      ]}
    >
      {contextHolder}
      <Form
        layout="vertical"
        initialValues={teacher}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Teacher"
          name="userId"
          rules={[
            {
              required: true,
              message: 'Teacher is required!',
            },
          ]}
        >
          <Select showSearch placeholder="Select a teacher" optionFilterProp="label" options={memberOptions} />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
