import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { STUDENTS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { SimulationCountryNewStudentModal } from './SimulationCountryNewStudentModal';
import { SimulationCountryExistingStudentModal } from './SimulationCountryExistingStudentModal';

export const SimulationCountryStudents = () => {
  const { simulationId, simulationCountryId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [classStudents, setClassStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [newStudent, setNewStudent] = useState(null);
  const [existingStudent, setExistingStudent] = useState(null);

  const fetchSimulation = async () => {
    try {
      const { result: simulationData } = await ApiService.retrieveSimulation(simulationId);
      const { resultList: countryList } = await ApiService.retrieveSimulationCountryList(simulationId);
      const countryData = countryList.find(c => c.simulationCountryId === +simulationCountryId);
      setSimulation(simulationData);
      setBreadcrumbItems([
        {
          title: 'Simulations',
          path: '/main/simulations',
        },
        {
          title: simulationData.simulationName,
          path: `/main/simulations/${simulationId}`,
        },
        {
          title: 'Countries',
          path: `/main/simulations/${simulationId}/countries`,
        },
        {
          title: countryData.countryName,
          path: `/main/simulations/${simulationId}/countries/${simulationCountryId}`,
        },
        {
          title: 'Students',
        },
      ]);
    } catch (e) {
      //
    }
  };

  const fetchSimulationCountryStudents = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveSimulationCountryStudentList(simulationId);
      setStudents(data.resultList.filter(result => result.simulationCountryId === +simulationCountryId));
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const fetchClassStudents = async () => {
    try {
      const data = await ApiService.retrieveClassStudentList(simulation.classId);
      setClassStudents(data.resultList);
    } catch (e) {
      //
    }
  };

  const handleAddNewStudent = () => {
    setNewStudent({});
  };

  const handleCloseNewStudentModal = () => {
    setNewStudent(null);
  };

  const handleNewStudentSaved = () => {
    setNewStudent(null);
    fetchSimulationCountryStudents();
  };

  const handleAddExistingStudent = () => {
    setExistingStudent({});
  };

  const handleCloseExistingStudentModal = () => {
    setExistingStudent(null);
  };

  const handleExistingStudentSaved = () => {
    setExistingStudent(null);
    fetchSimulationCountryStudents();
  };

  const handleDeleteStudent = async student => {
    try {
      await ApiService.deleteSimulationCountryStudent(student.simulationCountryUserId);
      await fetchSimulationCountryStudents();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchSimulation();
    fetchSimulationCountryStudents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (simulation) {
      fetchClassStudents();
    }
    // eslint-disable-next-line
  }, [simulation]);

  const studentsTableColumns = [
    ...STUDENTS_TABLE_COLUMNS,
    {
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this student?" onConfirm={() => handleDeleteStudent(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    },
  ];

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleAddNewStudent}>
        Add New Student
      </Button>
      <Button size="small" type="primary" onClick={handleAddExistingStudent}>
        Add Existing Student
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card size="small" title="Manage Students" extra={ActionBar}>
        <Table
          size="small"
          pagination={false}
          loading={isLoading}
          columns={studentsTableColumns}
          dataSource={students}
          rowKey="simulationCountryUserId"
          bordered
          head
        />
      </Card>
      <SimulationCountryNewStudentModal
        simulationId={simulationId}
        simulationCountryId={simulationCountryId}
        student={newStudent}
        onClose={handleCloseNewStudentModal}
        onSave={handleNewStudentSaved}
      />
      <SimulationCountryExistingStudentModal
        simulationId={simulationId}
        simulationCountryId={simulationCountryId}
        classStudents={classStudents}
        student={existingStudent}
        onClose={handleCloseExistingStudentModal}
        onSave={handleExistingStudentSaved}
      />
    </>
  );
};
