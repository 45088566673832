import moment from 'moment-timezone';

export const SIMULATION_YEARS_TABLE_COLUMNS = [
  {
    id: 'Year',
    title: 'Year',
    dataIndex: 'Year',
  },
  {
    id: 'StartsOn',
    title: 'Started On',
    render: row => moment.utc(row.StartsOn, 'M/D/YYYY H:m:s').local().format('YYYY-MM-DD HH:mm:ss A'),
  },
  {
    id: 'EndsOn',
    title: 'Ended On',
    render: row => moment.utc(row.EndsOn, 'M/D/YYYY H:m:s').local().format('YYYY-MM-DD HH:mm:ss A'),
  },
  {
    id: 'FortuitousEventsProbability',
    title: 'Fortuitous Events',
    render: row => `${Math.round(row.FortuitousEventsProbability * 100)}%`,
  },
  {
    id: 'DisastrousEventsProbability',
    title: 'Disastrous Events',
    render: row => `${Math.round(row.DisastrousEventsProbability * 100)}%`,
  },
  {
    id: 'Status',
    title: 'Status',
    render: row => {
      const color = row.Status === 'COMPLETED' ? '#52c41a' : '#ff4d4f';
      return <span style={{ color }}>{row.Status}</span>;
    },
  },
];
