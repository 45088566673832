import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, List, Row } from 'antd';
import ApiService from '../../../services/ApiService';
import { ChangePasswordModal } from './ChangePasswordModal';
import { EditProfileModal } from './EditProfileModal';

export const MyProfile = () => {
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [accountRoles, setAccountRoles] = useState([]);

  const accountSchools = accountRoles
    .filter(ar => ar.roleName.includes('School'))
    .map((ar, index) => `${index + 1}) ${ar.referenceName} (${ar.roleName.replace(' of School', '')})`);
  const accountClasses = accountRoles
    .filter(ar => ar.roleName.includes('Class'))
    .map((ar, index) => `${index + 1}) ${ar.referenceName} (${ar.roleName.replace(' of Class', '')})`);
  const accountSimulations = accountRoles
    .filter(ar => ar.roleName.includes('Simulation'))
    .map((ar, index) => `${index + 1}) ${ar.referenceName} (${ar.roleName.replace(' of Simulation', '')})`);

  const fetchAccount = async () => {
    const data = await ApiService.retrieveAccountView();
    setAccount(data.result);
  };

  const fetchAccountRoleList = async () => {
    const data = await ApiService.retrieveAccountRoleList();
    setAccountRoles(data.resultList);
  };

  const handleEditProfile = () => {
    setEditProfileModalOpen(true);
  };

  const handleCloseEditProfileModal = () => {
    setEditProfileModalOpen(false);
  };

  const handleSaveProfile = async () => {
    await fetchAccount();
    setEditProfileModalOpen(false);
  };

  const handleChangePassword = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const ActionBar = (
    <>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleEditProfile}>
        Edit Profile
      </Button>
      <Button size="small" type="primary" onClick={handleChangePassword}>
        Change Password
      </Button>
    </>
  );

  useEffect(() => {
    fetchAccount();
    fetchAccountRoleList();
  }, []);

  return (
    <>
      <Card size="small" title="My Profile" extra={ActionBar}>
        <Form labelCol={{ span: 4 }}>
          <Form.Item label="Username">
            <Input readOnly value={account?.userName} />
          </Form.Item>
          <Form.Item label="First Name">
            <Input readOnly value={account?.firstName} />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input readOnly value={account?.lastName} />
          </Form.Item>
          <Form.Item label="Email">
            <Input readOnly value={account?.email} />
          </Form.Item>
          <Form.Item label="Gender">
            <Input readOnly value={account?.gender} />
          </Form.Item>
          <Form.Item label="Primary Language">
            <Input readOnly value={account?.primaryLanguageName} />
          </Form.Item>
          <Form.Item label="Home Country">
            <Input readOnly value={account?.countryName} />
          </Form.Item>
          <Form.Item label="Year of Birth">
            <Input readOnly value={account?.yearOfBirth} />
          </Form.Item>
        </Form>
      </Card>
      <Row style={{ marginTop: 24 }} gutter={24}>
        <Col span={8}>
          <List
            size="small"
            header={<b>Account Schools</b>}
            bordered
            dataSource={accountSchools}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
        <Col span={8}>
          <List
            size="small"
            header={<b>Account Classes</b>}
            bordered
            dataSource={accountClasses}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
        <Col span={8}>
          <List
            size="small"
            header={<b>Account Simulations</b>}
            bordered
            dataSource={accountSimulations}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Col>
      </Row>
      <EditProfileModal
        open={isEditProfileModalOpen}
        onClose={handleCloseEditProfileModal}
        onSave={handleSaveProfile}
      />
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
        onSave={handleCloseChangePasswordModal}
      />
    </>
  );
};
