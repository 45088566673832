export const SCHOOLS_TABLE_COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    dataIndex: 'schoolName',
  },
  {
    id: 'level',
    title: 'Level',
    dataIndex: 'levelName',
  },
  {
    id: 'state',
    title: 'State',
    dataIndex: 'stateName',
  },
  {
    id: 'country',
    title: 'Country',
    dataIndex: 'countryName',
  },
  {
    id: 'language',
    title: 'Language',
    dataIndex: 'primaryLanguageName',
  },
  {
    id: 'enrollment',
    title: 'Enrollment',
    dataIndex: 'sizeName',
  },
];
