import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, Outlet } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { CreditsModal } from '../../components/CreditsModal';
import { AdminHelpModal } from '../../components/AdminHelpModal';
import { NotesModal } from '../../components/NotesModal';
import DataService from '../../services/DataService';
import SessionService from '../../services/SessionService';
import { DATA_LIST, SIDER_MENU_ITEMS } from './data';
import { ContentLayout, MainLayout, StyledBreadcrumb, StyledContent, StyledSider } from './styles';
import { AppHeader } from './AppHeader';
import { MainContext } from './Context';
import { DataLoader } from './DataLoader';

export const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const username = SessionService.getUsername();
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [isCreditsModalOpen, setCreditsModalOpen] = useState(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [isDataLoaded, setDataLoaded] = useState(DataService.getItem(DATA_LIST[DATA_LIST.length - 1].key));
  const selectedMenuItem = SIDER_MENU_ITEMS.find(item => item.path === location.pathname);
  const selectedKey = selectedMenuItem?.key;
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const sections = location.pathname.split('/').filter(Boolean);

  const itemRender = (item, _params, items, _paths) => {
    const isLast = items.indexOf(item) === items.length - 1;
    const path = item.path ?? '/main/my-profile';
    return isLast ? <span>{item.title}</span> : <Link to={path}>{item.title}</Link>;
  };

  const handleBack = () => {
    let path = sections.slice(0, -1).join('/');
    path = `/${path}`;
    navigate(path);
  };

  useEffect(() => {
    if (selectedMenuItem) {
      setBreadcrumbItems(selectedMenuItem.breadcrumbs.map(title => ({ title })));
    } else {
      setBreadcrumbItems([]);
    }
  }, [selectedMenuItem]);

  const handleCompleteDataLoading = () => {
    setDataLoaded(true);
  };

  if (!isDataLoaded) {
    return <DataLoader onComplete={handleCompleteDataLoading} />;
  }

  const headerMenuItems = [
    {
      key: 'help',
      label: <Link onClick={() => setHelpModalOpen(true)}>How to Set up Simpolicon</Link>,
    },
    {
      key: 'credits',
      label: <Link onClick={() => setCreditsModalOpen(true)}>Credits</Link>,
    },
    {
      key: 'notes',
      label: <Link onClick={() => setNotesModalOpen(true)}>Notes</Link>,
    },
  ];

  return (
    <MainContext.Provider
      value={{
        breadcrumbItems,
        setBreadcrumbItems,
      }}
    >
      <MainLayout>
        <AppHeader initial={username[0]} headerMenuItems={headerMenuItems} />
        <Layout>
          <StyledSider width={200}>
            <Menu
              style={{ height: '100%' }}
              mode="inline"
              defaultSelectedKeys={[selectedKey]}
              items={SIDER_MENU_ITEMS}
            />
          </StyledSider>
          <ContentLayout>
            <div className="d-flex justify-space-between align-center mb-8">
              <StyledBreadcrumb items={breadcrumbItems} itemRender={itemRender}></StyledBreadcrumb>
              {sections.length > 2 && (
                <Button type="secondary" size="small" onClick={handleBack}>
                  {'<'} Back
                </Button>
              )}
            </div>
            <StyledContent>
              <Outlet />
            </StyledContent>
          </ContentLayout>
        </Layout>
        <AdminHelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
        <CreditsModal open={isCreditsModalOpen} onClose={() => setCreditsModalOpen(false)} />
        <NotesModal open={isNotesModalOpen} onClose={() => setNotesModalOpen(false)} />
      </MainLayout>
    </MainContext.Provider>
  );
};
