import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Col, InputNumber, Radio, Row, Select } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { SIMULATION_COUNTRY_STATES } from '../../../../../data';
import Utils from '../../../../../utils';

const WEAPONS = ['Light Weapon', 'Medium Weapon', 'Heavy Weapon'];
const HUMANS = ['Soldier', 'Junior Military Officer', 'Senior Military Officer'];
const SYSTEMS = [
  'Complete Communications System',
  'Complete Communications System-D',
  'Information System',
  'Information System-C',
  'Information System-Depleted',
  'Information System-R',
  'Power System',
  'Power System-C',
  'Power System-Depleted',
  'Power System-R',
  'Transportation System',
  'Transportation System-C',
  'Transportation System-Depleted ',
  'Transportation System-R',
];
const MULTIPLIERS = [2, 6, 18];

const CountrySelect = styled(Select)`
  .ant-select-selector {
    border: 2px solid #f00 !important;
  }

  .ant-select-selection-placeholder {
    color: #f00;
  }
`;

const getResourceList = (resourceList, simulationCountry, list) => {
  if (!simulationCountry) return [];

  const scResources = simulationCountry.simulationCountryResourceViewList;

  const resources = list.map(key => {
    const resource = resourceList.find(r => r.resourceNameLocalized === key || r.resourceId === key);
    const scResource = scResources.find(scr => scr.resourceId === resource.resourceId);

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;

    return {
      resourceId: resource.resourceId,
      resourceNameLocalized: resource.resourceNameLocalized,
      iconUrl,
      remainingQuantity: WEAPONS.includes(resource.resourceNameLocalized)
        ? scResource.currentSimulationYearRemainingUses
        : scResource.remainingUsefulQuantity,
    };
  });

  return resources;
};

const getResources = resourceList => {
  return resourceList
    .filter(
      r =>
        !SYSTEMS.includes(r.resourceNameLocalized) &&
        (r.resourceNameLocalized.includes('Land') || r.resourceNameLocalized.includes('Deposit')),
    )
    .map(r => ({
      value: r.resourceId,
      label: r.resourceNameLocalized,
    }));
};

const getProducts = resourceList => {
  return resourceList
    .filter(r => r.tradable && !SYSTEMS.includes(r.resourceNameLocalized))
    .map(r => ({
      value: r.resourceId,
      label: r.resourceNameLocalized,
    }));
};

export const Plan = ({ onCancel, onDeclareWar }) => {
  const params = useParams();
  const { simulation, simulationCountry, fetchSimulationCountry } = useSimulationContext();
  const resourceList = DataService.getItem('resourceList');
  const [attackingCountries, setAttackingCountries] = useState([]);
  const [attackingCountryId, setAttackingCountryId] = useState(null);
  const [attackingForces, setAttackingForces] = useState([0, 0, 0]);
  const [soughtType, setSoughtType] = useState(1);
  const [resourceId1, setResourceId1] = useState(null);
  const [resourceId2, setResourceId2] = useState(null);
  const [productId1, setProductId1] = useState(null);
  const [productId2, setProductId2] = useState(null);

  const resources = getResources(resourceList);
  const products = getProducts(resourceList);

  const weapons = getResourceList(resourceList, simulationCountry, WEAPONS);
  const humans = getResourceList(resourceList, simulationCountry, HUMANS);
  const remainingQuantities = weapons.map((_, index) =>
    Math.min(weapons[index].remainingQuantity, humans[index].remainingQuantity),
  );
  const attackingForce = attackingForces.reduce((total, af, index) => total + af * MULTIPLIERS[index], 0);
  const gold = Math.max(attackingForce * 0.1, 2);

  const { totalGold } = Utils.calculateResourceValues(simulationCountry?.simulationCountryResourceViewList ?? []);
  const hasEnoughGold = totalGold >= gold;

  const isAble =
    (resourceId1 || productId1) &&
    (resourceId2 || productId2) &&
    ((resourceId1 && resourceId1 !== resourceId2) || (productId1 && productId1 !== productId2));

  const loadData = async () => {
    const attackingCountryList = simulation.simulationCountrySimpleViewObjectList
      .filter(sc => sc.simulationCountryId !== +params.simulationCountryId)
      .map(sc => ({
        label: sc.countryName,
        value: sc.simulationCountryId,
        state: sc.simulationCountryState,
      }));
    setAttackingCountries(attackingCountryList);
  };

  const handleChangeAttackingCountry = e => {
    const { label, state } = attackingCountries.find(sc => sc.value === e);
    let error = null;
    switch (state) {
      case SIMULATION_COUNTRY_STATES.NEW:
        error = 'The country has not started the simulation.';
        break;
      case SIMULATION_COUNTRY_STATES.NEW_YEAR:
        error = 'The country has not started the current year.';
        break;
      case SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_ANOTHER:
      case SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_BY_ANOTHER:
        error = 'The country is already at war';
        break;
      case SIMULATION_COUNTRY_STATES.END_YEAR:
        error = 'The country has ended the current year.';
        break;
      case SIMULATION_COUNTRY_STATES.END_SIMULATION:
        error = '';
        break;
      default:
    }
    if (error === null) {
      setAttackingCountryId(e);
    } else {
      Swal.fire({
        title: 'Country Unavailable',
        icon: 'error',
        html: `<span style="color: red;">${label} cannot be invaded now.</span><br />${error}<br />Please try again later.`,
      });
      setAttackingCountryId(null);
    }
  };

  const handleChangeAttackingForce = index => e => {
    const newAttackingForces = [...attackingForces];
    newAttackingForces[index] = +e;
    setAttackingForces(newAttackingForces);
  };

  const handleDeclareWar = async () => {
    const attackingCountry = attackingCountries.find(ac => ac.value === attackingCountryId);
    const rp1 = resourceList.find(r => r.resourceId === resourceId1 || r.resourceId === productId1);
    const rp2 = resourceList.find(r => r.resourceId === resourceId2 || r.resourceId === productId2);
    const result = await Swal.fire({
      title: '<h6 style="margin: 0;">Are you sure you want to declare war?<h6>',
      icon: 'question',
      html: `
        <style>
          .invasion-table {
            width: 100%;
          }
          .invasion-table tr td:nth-child(1) {
            background-color: #eee;
          }
        </style>
        <table class="invasion-table">
          <tr>
            <td style="text-align: right;">Target:</td>
            <td style="text-align: left;">${attackingCountry.label}</td>
          </tr>
          <tr>
            <td style="text-align: right;">Attacking Power:</td>
            <td style="text-align: left;">${attackingForce}</td>
          </tr>
          <tr>
            <td style="text-align: right;">1st Resource/Product:</td>
            <td style="text-align: left;">${rp1.resourceNameLocalized}</td>
          </tr>
          <tr>
            <td style="text-align: right;">2nd Resource/Product:</td>
            <td style="text-align: left;">${rp2.resourceNameLocalized}</td>
          </tr>
        </table>
      `,
      showCancelButton: true,
      confirmButtonText: 'Invade',
    });
    if (result.isConfirmed) {
      const payload = {
        demandPercentage: soughtType / 10,
        resourceIdDemand1: resourceId1 || productId1,
        resourceIdDemand2: resourceId2 || productId2,
        lightPowerQuantity: attackingForces[0],
        mediumPowerQuantity: attackingForces[1],
        heavyPowerQuantity: attackingForces[2],
        simulationCountryIdAttackedAnother: simulationCountry.simulationCountryId,
        simulationCountryIdAttackedByAnother: attackingCountryId,
      };
      await ApiService.addSimulationCountryWar(payload);
      await fetchSimulationCountry();
      onDeclareWar();
    }
  };

  useEffect(() => {
    if (simulation) {
      loadData();
    }
    // eslint-disable-next-line
  }, [simulation]);

  useEffect(() => {
    setAttackingForces([0, 0, 0]);
    setSoughtType(1);
    setResourceId1(null);
    setResourceId2(null);
    setProductId1(null);
    setProductId2(null);
  }, [attackingCountryId]);

  useEffect(() => {
    setResourceId1(null);
    setResourceId2(null);
    setProductId1(null);
    setProductId2(null);
  }, [soughtType]);

  return (
    <>
      <Card title="Invasion Plan - Country to Invade" size="small">
        <CountrySelect
          className="w-100"
          placeholder="Select the country to invade"
          options={attackingCountries}
          value={attackingCountryId}
          onChange={handleChangeAttackingCountry}
        />
      </Card>
      {attackingCountryId && (
        <Card className="mt-16" title="Invasion Plan - Attacking Force" size="small">
          {weapons.map((_, index) => (
            <div className="d-flex justify-space-between align-center" key={`af-${index}`}>
              <div className="d-flex align-center mb-8">
                <img width={24} src={weapons[index].iconUrl} alt={weapons[index].resourceNameLocalized} />
                <img
                  className="ml-4"
                  width={24}
                  src={humans[index].iconUrl}
                  alt={humans[index].resourceNameLocalized}
                />
                <div className="ml-4">
                  <span>
                    {weapons[index].resourceNameLocalized}s & {humans[index].resourceNameLocalized}s{' '}
                  </span>
                  <span className={remainingQuantities[index] > 0 ? 'text-success' : 'text-error'}>
                    ({remainingQuantities[index]})
                  </span>
                </div>
              </div>
              <InputNumber
                style={{ border: '2px solid #4096ff' }}
                size="small"
                min={0}
                max={remainingQuantities[index]}
                value={attackingForces[index]}
                onChange={handleChangeAttackingForce(index)}
              />
            </div>
          ))}
          <hr />
          <div className="d-flex justify-space-between">
            <div>Total Attack Force</div>
            <div style={{ color: hasEnoughGold ? 'green' : 'red' }}>
              Required Gold: {gold.toFixed(2)} (Current Gold: {totalGold.toFixed(2)})
            </div>
            <div>(Minimum 6) {attackingForce.toFixed(2)}</div>
          </div>
        </Card>
      )}
      {hasEnoughGold && attackingForce >= 6 && (
        <Card className="mt-16" title="Invasion Plan - Resources & Products Sought" size="small">
          <Radio.Group
            className="w-100"
            name="soughtType"
            value={soughtType}
            onChange={e => setSoughtType(e.target.value)}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Radio value={1}>10% of Two Resources</Radio>
                  <Row className="mt-16" gutter={8}>
                    <Col span={12}>
                      <label>Resource #1</label>
                      <Select
                        className="w-100"
                        options={resources}
                        value={resourceId1}
                        onChange={e => setResourceId1(e)}
                        disabled={soughtType === 2}
                      />
                    </Col>
                    <Col span={12}>
                      <label>Resource #2</label>
                      <Select
                        className="w-100"
                        options={resources}
                        value={resourceId2}
                        onChange={e => setResourceId2(e)}
                        disabled={soughtType === 2}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Radio value={2}>20% of Two Products</Radio>
                  <Row className="mt-16" gutter={8}>
                    <Col span={12}>
                      <label>Product #1</label>
                      <Select
                        className="w-100"
                        options={products}
                        value={productId1}
                        onChange={e => setProductId1(e)}
                        disabled={soughtType === 1}
                      />
                    </Col>
                    <Col span={12}>
                      <label>Product #2</label>
                      <Select
                        className="w-100"
                        options={products}
                        value={productId2}
                        onChange={e => setProductId2(e)}
                        disabled={soughtType === 1}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Radio.Group>
        </Card>
      )}
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button style={{ width: 200 }} className="ml-16" type="primary" onClick={handleDeclareWar} disabled={!isAble}>
          Declare War
        </Button>
      </div>
    </>
  );
};
