import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import ApiService from '../services/ApiService';
import DataService from '../services/DataService';
import { GOALS, SIMULATION_COUNTRY_STATES } from '../data';
import { SIDER_MENU_ITEMS } from '../layouts/Simulation/data';

const defaultValue = {
  overviewLoaded: false,
  simulationCountry: null,
  simulation: null,
  selectedResourceId: null,
  allowedRoutes: null,
};

const timeoutIds = [];

export const SimulationContext = createContext(defaultValue);
export const useSimulationContext = () => useContext(SimulationContext);

export const SimulationContextProvider = ({ children }) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [simulation, setSimulation] = useState(null);
  const [simulationCountry, setSimulationCountry] = useState(null);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const resourceList = DataService.getItem('resourceList');
  const [overviewLoaded, setOverviewLoaded] = useState(false);
  const [allowedRoutes, setAllowedRoutes] = useState(null);

  const fetchSimulationCountry = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    setSimulationCountry(data.result);
    const sc = data.result;

    data = await ApiService.retrieveSimulation(data.result.simulationId);
    setSimulation(data.result);
    const s = data.result;

    const { simulationYearArray } = s;
    const simulationYearEndsOn = moment.utc(
      simulationYearArray[sc.simulationCountryYear - 1]?.EndsOn,
      'M/D/YYYY H:m:s',
    );

    for (const timeoutId of timeoutIds) {
      clearTimeout(timeoutId);
    }
    timeoutIds.splice();
    if (
      [
        SIMULATION_COUNTRY_STATES.NEW_YEAR,
        SIMULATION_COUNTRY_STATES.IN_YEAR,
        SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_ANOTHER,
        SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_BY_ANOTHER,
      ].includes(sc.simulationCountryState)
    ) {
      const alertTime = simulationYearEndsOn.subtract(10, 'minutes');
      if (alertTime.isAfter(moment())) {
        const timeout = +alertTime.toDate() - Date.now();
        const timeoutId = setTimeout(() => {
          Swal.fire({
            icon: 'info',
            text: 'The current simulation year will end in 10 minutes. Please complete any production or trade before then.',
          });
        }, timeout);
        timeoutIds.push(timeoutId);
      }
    }

    if ([SIMULATION_COUNTRY_STATES.NEW, SIMULATION_COUNTRY_STATES.NEW_YEAR].includes(sc.simulationCountryState)) {
      setAllowedRoutes([]);
    } else if (sc.simulationCountryState === SIMULATION_COUNTRY_STATES.IN_YEAR && !sc.goalsAreLocked) {
      setAllowedRoutes(['world-briefing', 'national-briefing', 'goals']);
    } else if (
      sc.simulationCountryState === SIMULATION_COUNTRY_STATES.END_YEAR ||
      sc.simulationCountryState === SIMULATION_COUNTRY_STATES.END_SIMULATION ||
      moment().isSameOrAfter(moment(simulationYearEndsOn))
    ) {
      setAllowedRoutes(['world-briefing', 'national-briefing', 'goals', 'feed-the-people']);
    } else {
      setAllowedRoutes(SIDER_MENU_ITEMS.map(item => item.key));
    }
  };

  const getSimulationCountryResourceValueByName = (resourceName, valueName) => {
    if (!simulationCountry) return 0;
    const scResources = simulationCountry.simulationCountryResourceViewList;
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    const resourceObject = scResources?.find(r => r.resourceId === resource.resourceId);
    return resourceObject?.[valueName] ?? 0;
  };

  const calculateSimulationCountryGoals = async (sync = false) => {
    const goals = _.cloneDeep(GOALS);
    const goalTypeList = DataService.getItem('goalTypeList');
    const resourceList = DataService.getItem('resourceList');
    const { result: sc } = await ApiService.retrieveSimulationCountry(params.simulationCountryId);

    const goalTypeIds = goals.map(goal => goalTypeList.find(g => g.goalTypeNameLocalized === goal.title).goalTypeId);
    const goalRanks = goalTypeIds.map(goalTypeId =>
      sc.simulationCountryGoalTypeViewList.findIndex(scGoal => +scGoal.goalTypeId === goalTypeId),
    );

    // Achieve High GDP per Capita
    goals[0].children[0].value = sc.gpc.toFixed(2);
    goals[0].actualValue = (12 - goalRanks[0]) * sc.gpc;
    goals[0].value = goals[0].actualValue.toFixed(2);

    // Conserve Natural Resources
    let titles = [
      'Bauxite Deposit',
      'Coal Deposit',
      'Cotton Land',
      'Forest Land',
      'Gold Deposit',
      'Grazing Land',
      'Iron Deposit',
      'Petroleum Deposit',
      'Tubers Land',
      'Riparian Land',
      'Copper Deposit',
      'Grains Land',
      'Specialty Foods Land',
      'High Tech Mineral Deposit',
    ];
    let resourceIds = titles.map(title => resourceList.find(r => r.resourceNameLocalized === title)?.resourceId);
    let values = resourceIds.map((resourceId, index) => {
      let value = 0;
      const scResource = sc.simulationCountryResourceViewList.find(scr => scr.resourceId === resourceId);
      if (titles[index] === 'Riparian Land') {
        const gained = scResource.remainingQuantity - scResource.initialQuantity;
        if (gained > 0) {
          value = scResource.initialQuantity ? (gained * 100) / scResource.initialQuantity : 0;
          value = Math.min(value, 100);
        }
      } else {
        const depleted = scResource.initialQuantity - scResource.remainingQuantity;
        if (depleted > 0) {
          value = scResource.initialQuantity ? (depleted * 100) / scResource.initialQuantity : 0;
          value = Math.min(value, 100);
        }
      }
      return value === 0 ? 0 : -value;
    });
    let totalValue = values.reduce((total, value) => total + value, 0);
    goals[1].children.forEach((item, index) => {
      item.value = `${values[index].toFixed(2)}%`;
      item.weightedValue = (12 - goalRanks[1]) * values[index];
    });
    goals[1].actualValue = (12 - goalRanks[1]) * totalValue;
    goals[1].value = goals[1].actualValue.toFixed(2);

    // Develop Agriculture and Mining
    // Develop Business Infrastructure
    // Develop Education Infrastructure
    // Develop Health Services
    // Develop Life Style
    // Develop Military Strength
    for (let i = 2; i <= 7; i += 1) {
      titles = goals[i].children.map(item => item.title);
      resourceIds = titles.map(title => resourceList.find(r => r.resourceNameLocalized === title)?.resourceId);
      const values = resourceIds.map(resourceId => {
        const scResource = sc.simulationCountryResourceViewList.find(scr => scr.resourceId === resourceId);
        return scResource.remainingQuantity;
      });
      totalValue = values.filter(value => value > 0).length;
      totalValue = totalValue === goals[i].children.length ? totalValue + 50 : totalValue;

      goals[i].children.forEach((item, index) => {
        item.value = values[index];
      });
      goals[i].points = totalValue;
      goals[i].actualValue = (12 - goalRanks[i]) * totalValue;
      goals[i].value = goals[i].actualValue;
    }

    // Educate People
    titles = ['Unskilled Labor', 'Semi-Skilled Labor', 'Soldier', 'Retired Population', 'Dependent Population'];
    resourceIds = titles.map(title => resourceList.find(r => r.resourceNameLocalized === title).resourceId);
    totalValue = 0;
    resourceIds.forEach(resourceId => {
      const scResource = sc.simulationCountryResourceViewList.find(scr => scr.resourceId === resourceId);
      totalValue += scResource.remainingQuantity;
    });
    totalValue = sc.population ? ((sc.population - totalValue) * 100) / sc.population : 0;
    goals[8].children[0].value = `${totalValue.toFixed(2)}%`;
    goals[8].actualValue = (12 - goalRanks[8]) * totalValue;
    goals[8].value = goals[8].actualValue.toFixed(2);

    // Limit Pollution
    titles = ['Pollution Clean-Up'];
    resourceIds = titles.map(title => resourceList.find(r => r.resourceNameLocalized === title).resourceId);
    values = resourceIds.map(resourceId => {
      const scResource = sc.simulationCountryResourceViewList.find(scr => scr.resourceId === resourceId);
      return scResource.remainingQuantity;
    });
    totalValue = Math.max(sc.pollution - values[0], 0);
    goals[9].children[0].value = sc.pollution;
    goals[9].children[1].value = values[0];
    goals[9].children[2].value = totalValue;
    goals[9].actualValue = (12 - goalRanks[9]) * -totalValue;
    goals[9].value = goals[9].actualValue;

    // Minimize Deaths
    goals[10].children[0].value = -sc.deceasedPopulation;
    goals[10].actualValue = (12 - goalRanks[10]) * sc.deceasedPopulation;
    goals[10].value = goals[10].actualValue;

    // Provide Consumer Goods
    titles = [
      'Major Consumer Purchase',
      'Durable Consumer Good',
      'Non-Durable Consumer Good',
      'Foreign Tourism Ticket',
      'Imported Specialty Foods',
    ];
    resourceIds = titles.map(title => resourceList.find(r => r.resourceNameLocalized === title).resourceId);
    values = resourceIds.map(resourceId => {
      const scResource = sc.simulationCountryResourceViewList.find(scr => scr.resourceId === resourceId);
      return scResource.remainingQuantity;
    });
    const weight = 12 - goalRanks[11];

    goals[11].children[0].value = (sc.population ? values[0] / sc.population : 0).toFixed(2);
    goals[11].children[1].value = (sc.population ? values[1] / sc.population : 0).toFixed(2);
    goals[11].children[2].value = (sc.population ? values[2] / sc.population : 0).toFixed(2);
    goals[11].children[3].value = values[3];
    goals[11].children[4].value = values[4];

    goals[11].children[0].weightedValue = sc.population ? (weight * 50 * values[0]) / sc.population : 0;
    goals[11].children[1].weightedValue = sc.population ? (weight * 20 * values[1]) / sc.population : 0;
    goals[11].children[2].weightedValue = sc.population ? (weight * 10 * values[2]) / sc.population : 0;
    goals[11].children[3].weightedValue = weight * 10 * values[3];
    goals[11].children[4].weightedValue = weight * 10 * values[4];

    totalValue = goals[11].children.reduce((total, item) => total + item.weightedValue, 0);

    goals[11].actualValue = totalValue;
    goals[11].value = goals[11].actualValue.toFixed(2);

    const score = +goals.reduce((total, goal) => total + goal.actualValue ?? 0, 0).toFixed(2);
    if (sync) {
      await ApiService.updateSimulationCountryScore(params.simulationCountryId, score);
    }

    return { goals, goalRanks, score };
  };

  useEffect(() => {
    fetchSimulationCountry();
    // eslint-disable-next-line
  }, [params.simulationCountryId]);

  useEffect(() => {
    if (
      [SIMULATION_COUNTRY_STATES.NEW, SIMULATION_COUNTRY_STATES.NEW_YEAR].includes(
        simulationCountry?.simulationCountryState,
      ) &&
      !location.pathname.endsWith('/welcome')
    ) {
      navigate(`/portal/simulations/${params.simulationCountryId}/welcome`);
    }
    // eslint-disable-next-line
  }, [location, simulationCountry]);

  return (
    <SimulationContext.Provider
      value={{
        overviewLoaded,
        resourceList,
        simulation,
        simulationCountry,
        allowedRoutes,
        selectedResourceId,
        setOverviewLoaded,
        fetchSimulationCountry,
        getSimulationCountryResourceValueByName,
        setSelectedResourceId,
        calculateSimulationCountryGoals,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
};
