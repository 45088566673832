import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Select, Table } from 'antd';
import { useSimulationContext } from '../../../../../contexts/SimulationContext';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';
import { AddOfferModal } from './AddOfferModal';
import { ManageOfferModal } from '../../Trade/Market/ManageOfferModal';
import { AdmitMigrantsModal } from './AdmitMigrantsModal';
import { AddRequestModal } from './AddRequestModal';
import { ManageRequestModal } from './ManageRequestModal';
import { FulfillRequestModal } from './FulfillRequestModal';
import { SelectOption } from './styles';

const getCategoryList = () => {
  const categoryList = [
    {
      value: 0,
      label: (
        <SelectOption>
          <img src="/images/pages/simulation/trade/market/all-categories.png" alt="" />
          <div>All Human Resources</div>
        </SelectOption>
      ),
    },
    ...DataService.getItem('resourceList')
      .filter(r => r.resourceTypeId === 3) // Human Resources
      .sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized))
      .map(rt => {
        const iconName = rt.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        const item = {
          value: rt.resourceId,
          label: (
            <SelectOption>
              <img src={iconUrl} alt="" />
              <div>{rt.resourceNameLocalized}</div>
            </SelectOption>
          ),
        };
        return item;
      }),
  ];
  return categoryList;
};

const getOfferTableColumns = (simulationCountryId, handleManageOffer, handleAdmitMigrants, setSelectedResourceId) => {
  const countryList = DataService.getItem('countryList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'laborType',
      title: 'Labor Type',
      width: 240,
      render: row => {
        const resource = resourceList.find(r => r.resourceId === row.resourceId);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resource.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'countryOffering',
      title: 'Country Offering',
      width: 200,
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdSeller);
        const flagUrl = `/images/flags/${row.sellAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.sellAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'numberOfPeople',
      title: 'Number Of People',
      dataIndex: 'quantity',
      align: 'center',
    },
    {
      id: 'wagePerLaborer',
      title: 'Wage Per Laborer',
      align: 'center',
      render: row => row.unitPrice.toFixed(2),
    },
    {
      id: 'totalWages',
      title: 'Total Wages',
      align: 'center',
      render: row => (row.quantity * row.unitPrice).toFixed(2),
    },
    {
      id: 'actions',
      align: 'center',
      render: row => {
        if (row.simulationCountryIdSeller === simulationCountryId) {
          return (
            <Button style={{ width: 80 }} type="primary" size="small" danger onClick={() => handleManageOffer(row)}>
              Manage
            </Button>
          );
        }
        return (
          <Button style={{ width: 80 }} type="primary" size="small" onClick={() => handleAdmitMigrants(row)}>
            Admit
          </Button>
        );
      },
    },
  ];

  return columns;
};

const getRequestTableColumns = (
  simulationCountryId,
  handleManageRequest,
  handleFulfillRequest,
  setSelectedResourceId,
) => {
  const countryList = DataService.getItem('countryList');
  const resourceList = DataService.getItem('resourceList');

  const columns = [
    {
      id: 'laborType',
      title: 'Labor Type',
      width: 240,
      render: row => {
        const resource = resourceList.find(r => r.resourceId === row.resourceId);
        const iconName = resource.iconUrl.split('/').pop();
        const iconUrl = `/images/icons/resources/${iconName}`;
        return (
          <div className="resource-link" onClick={() => setSelectedResourceId(row.resourceId)}>
            <img width={20} src={iconUrl} alt={resource.resourceNameLocalized} />
            <span style={{ paddingLeft: 8 }}>{resource.resourceNameLocalized}</span>
          </div>
        );
      },
    },
    {
      id: 'countryRequesting',
      title: 'Country Requesting',
      width: 200,
      render: row => {
        const country = countryList.find(c => c.countryId === row.countryIdBuyer);
        const flagUrl = `/images/flags/${row.buyAnonymous ? 'pirate' : country.countryCode}.png`;
        const countryName = row.buyAnonymous ? '******' : country.countryName;
        return (
          <>
            <img src={flagUrl} alt={countryName} />
            <span style={{ paddingLeft: 4 }}>{countryName}</span>
          </>
        );
      },
    },
    {
      id: 'numberOfPeople',
      title: 'Number Of People',
      dataIndex: 'quantity',
      align: 'center',
    },
    {
      id: 'wagePerLaborer',
      title: 'Wage Per Laborer',
      align: 'center',
      render: row => row.unitPrice.toFixed(2),
    },
    {
      id: 'totalWages',
      title: 'Total Wages',
      align: 'center',
      render: row => (row.quantity * row.unitPrice).toFixed(2),
    },
    {
      id: 'actions',
      align: 'center',
      render: row => {
        if (row.simulationCountryIdBuyer === simulationCountryId) {
          return (
            <Button style={{ width: 80 }} type="primary" size="small" danger onClick={() => handleManageRequest(row)}>
              Manage
            </Button>
          );
        }
        return (
          <Button style={{ width: 80 }} type="primary" size="small" onClick={() => handleFulfillRequest(row)}>
            Fulfill
          </Button>
        );
      },
    },
  ];

  return columns;
};

export const Migration = () => {
  const params = useParams();
  const { resourceList, simulationCountry, fetchSimulationCountry, setSelectedResourceId } = useSimulationContext();
  const categoryList = getCategoryList();
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [offers, setOffers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] = useState(false);
  const [isManageOfferModalOpen, setIsManageOfferModalOpen] = useState(false);
  const [isAdmitMigrantsModalOpen, setIsAdmitMigrantsModalOpen] = useState(false);
  const [offer, setOffer] = useState(null);
  const [isAddRequestModalOpen, setIsAddRequestModalOpen] = useState(false);
  const [isManageRequestModalOpen, setIsManageRequestModalOpen] = useState(false);
  const [isFulfillRequestModalOpen, setIsFulfillRequestModalOpen] = useState(false);
  const [request, setRequest] = useState(null);

  const refresh = () => {
    fetchOffers();
    fetchRequests();
  };

  const fetchOffers = async () => {
    const data = await ApiService.retrieveSimulationOfferList(simulationCountry.simulationId, {
      resourceTypeId: 0,
      tradable: false,
      migratable: true,
      resourceId: selectedCategoryId,
    });
    data.resultList.forEach(offer => {
      offer.resourceNameLocalized = resourceList.find(r => r.resourceId === offer.resourceId).resourceNameLocalized;
    });
    data.resultList.sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized));
    setOffers(data.resultList);
  };

  const fetchRequests = async () => {
    const data = await ApiService.retrieveSimulationRequestList(simulationCountry.simulationId, {
      resourceTypeId: 0,
      tradable: false,
      migratable: true,
      resourceId: selectedCategoryId,
    });
    data.resultList.forEach(request => {
      request.resourceNameLocalized = resourceList.find(r => r.resourceId === request.resourceId).resourceNameLocalized;
    });
    data.resultList.sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized));
    setRequests(data.resultList);
  };

  const handleAddOffer = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsAddOfferModalOpen(false);
  };

  const handleOpenManageOffer = selectedOffer => {
    setOffer(selectedOffer);
    setIsManageOfferModalOpen(true);
  };

  const handleUpdateOffer = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsManageOfferModalOpen(false);
  };

  const handleOpenAdmitMigrants = selectedOffer => {
    setOffer(selectedOffer);
    setIsAdmitMigrantsModalOpen(true);
  };

  const handleAdmitMigrants = () => {
    fetchSimulationCountry();
    fetchOffers();
    setIsAdmitMigrantsModalOpen(false);
  };

  const handleAddRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsAddRequestModalOpen(false);
  };

  const handleOpenManageRequest = selectedRequest => {
    setRequest(selectedRequest);
    setIsManageRequestModalOpen(true);
  };

  const handleUpdateRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsManageRequestModalOpen(false);
  };

  const handleOpenFulfillRequest = selectedRequest => {
    setRequest(selectedRequest);
    setIsFulfillRequestModalOpen(true);
  };

  const handleFulfillRequest = () => {
    fetchSimulationCountry();
    fetchRequests();
    setIsFulfillRequestModalOpen(false);
  };

  const OFFER_TABLE_COLUMNS = useMemo(
    () =>
      getOfferTableColumns(
        +params.simulationCountryId,
        handleOpenManageOffer,
        handleOpenAdmitMigrants,
        setSelectedResourceId,
      ),
    // eslint-disable-next-line
    [params.simulationCountryId],
  );
  const REQUEST_TABLE_COLUMNS = useMemo(
    () =>
      getRequestTableColumns(
        +params.simulationCountryId,
        handleOpenManageRequest,
        handleOpenFulfillRequest,
        setSelectedResourceId,
      ),
    // eslint-disable-next-line
    [params.simulationCountryId],
  );

  useEffect(() => {
    if (simulationCountry) {
      fetchOffers();
      fetchRequests();
    }
    // eslint-disable-next-line
  }, [simulationCountry, selectedCategoryId]);

  const offerActionButtons = [
    <Button
      style={{ width: 100 }}
      type="primary"
      size="small"
      onClick={() => setIsAddOfferModalOpen(true)}
      key="add-offer"
    >
      Add Offer
    </Button>,
  ];

  const requestActionButtons = [
    <Button
      style={{ width: 100 }}
      type="primary"
      size="small"
      onClick={() => setIsAddRequestModalOpen(true)}
      key="add-request"
    >
      Add Request
    </Button>,
  ];

  return (
    <>
      <div className="d-flex justify-space-between align-center">
        <Select
          style={{ width: 210 }}
          listHeight={800}
          options={categoryList}
          value={selectedCategoryId}
          onSelect={e => setSelectedCategoryId(e)}
        />
        <Button style={{ width: 100 }} type="primary" onClick={refresh}>
          Refresh
        </Button>
      </div>
      <Card size="small" style={{ marginTop: 16 }} title="People Willing to Migrate" extra={offerActionButtons}>
        <p className="mt-0">
          People willing to emigrate must be new with a full complement of annual and lifetime uses. They are
          immediately deducted from the offering country and held in escrow until they are hired, at which time you
          receive the wages and the country receives the immigrant.
        </p>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(50vh - 275px)' }}
          rowKey="offerId"
          columns={OFFER_TABLE_COLUMNS}
          dataSource={offers}
        />
      </Card>
      <Card size="small" style={{ marginTop: 16 }} title="Labor Requested" extra={requestActionButtons}>
        <p className="mt-0">
          Gold to hire requested workers is immediately deducted from the requesting country and held in escrow until
          fulfilled, at which time you receive the worker and the other country receives the wages.
        </p>
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(50vh - 275px)' }}
          rowKey="requestId"
          columns={REQUEST_TABLE_COLUMNS}
          dataSource={requests}
        />
      </Card>
      <AddOfferModal
        isOpen={isAddOfferModalOpen}
        simulationCountry={simulationCountry}
        onSave={handleAddOffer}
        onClose={() => setIsAddOfferModalOpen(false)}
      />
      <ManageOfferModal
        isOpen={isManageOfferModalOpen}
        simulationCountry={simulationCountry}
        offer={offer}
        onSave={handleUpdateOffer}
        onClose={() => setIsManageOfferModalOpen(false)}
      />
      <AdmitMigrantsModal
        isOpen={isAdmitMigrantsModalOpen}
        simulationCountry={simulationCountry}
        offer={offer}
        onSave={handleAdmitMigrants}
        onClose={() => setIsAdmitMigrantsModalOpen(false)}
      />
      <AddRequestModal
        isOpen={isAddRequestModalOpen}
        simulationCountry={simulationCountry}
        onSave={handleAddRequest}
        onClose={() => setIsAddRequestModalOpen(false)}
      />
      <ManageRequestModal
        isOpen={isManageRequestModalOpen}
        simulationCountry={simulationCountry}
        request={request}
        onSave={handleUpdateRequest}
        onClose={() => setIsManageRequestModalOpen(false)}
      />
      <FulfillRequestModal
        isOpen={isFulfillRequestModalOpen}
        request={request}
        simulationCountry={simulationCountry}
        onSave={handleFulfillRequest}
        onClose={() => setIsFulfillRequestModalOpen(false)}
      />
    </>
  );
};
