import styled from 'styled-components';
import moment from 'moment-timezone';
import { useSimulationContext } from '../../contexts/SimulationContext';
import DataService from '../../services/DataService';
import { SIMULATION_COUNTRY_STATES } from '../../data';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1px;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
`;

export const Summary = () => {
  const { simulation, simulationCountry } = useSimulationContext();

  if (!simulation || !simulationCountry) {
    return;
  }

  const countryList = DataService.getItem('countryList');
  const country = countryList.find(c => c.countryId === simulationCountry.countryId);

  const getSCYearStatus = () => {
    const { simulationCountryState, simulationCountryYear } = simulationCountry;
    const { simulationYearArray, simulationYearArrayLength } = simulation;
    const simulationYearEndsOn = moment.utc(simulationYearArray[simulationCountryYear - 1]?.EndsOn, 'M/D/YYYY H:m:s');
    const endDate = moment(simulationYearEndsOn).format('MMM/DD/YYYY HH:mm:ss');

    let status = `Year ${simulationCountryYear} of ${simulationYearArrayLength}`;
    if (simulationYearArray.length === 0) {
      return status;
    }
    if (moment().isSameOrAfter(moment(simulationYearEndsOn))) {
      if (
        [SIMULATION_COUNTRY_STATES.END_YEAR, SIMULATION_COUNTRY_STATES.END_SIMULATION].includes(simulationCountryState)
      ) {
        status = `${status} Has Ended.`;
      } else {
        status = `${status} Has Ended. Feed Your People.`;
      }
      return status;
    }

    switch (simulationCountryState) {
      case SIMULATION_COUNTRY_STATES.NEW:
        status = `Year 0 of ${simulationYearArrayLength}`;
        break;
      case SIMULATION_COUNTRY_STATES.NEW_YEAR:
      case SIMULATION_COUNTRY_STATES.IN_YEAR:
      case SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_ANOTHER:
      case SIMULATION_COUNTRY_STATES.IN_YEAR_ATTACKED_BY_ANOTHER:
        status = `${status} (Year ${simulationCountryYear} Ends On ${endDate})`;
        break;
      default:
        status = `${status} Has Ended.`;
        break;
    }

    return status;
  };

  return (
    <Wrapper>
      <div>
        <img src={`/images/flags/${country.countryCode}.png`} alt="" />
        <span style={{ paddingLeft: 8 }}>{country.countryName}</span>
      </div>
      <div>{getSCYearStatus()}</div>
      <div>Population: {simulationCountry.population}</div>
      <div>Gold: {simulationCountry.gold.toFixed(0)}</div>
      <div>Pollution: {simulationCountry.pollution}</div>
    </Wrapper>
  );
};
