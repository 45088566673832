export const SIMULATION_STUDENTS_TABLE_COLUMNS = [
  {
    id: 'lastName',
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    id: 'firstName',
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    id: 'email',
    title: 'Email',
    dataIndex: 'email',
  },
  {
    id: 'username',
    title: 'Username',
    dataIndex: 'userName',
  },
  {
    id: 'countryName',
    title: 'Country Name',
    dataIndex: 'countryName',
  },
  {
    id: 'classSection',
    title: 'Class Section',
    dataIndex: 'classSection',
  },
];
