import { Card, Modal } from 'antd';

export const HelpModal = ({ open, onClose }) => (
  <Modal width="80%" title="How to Play" footer={null} open={open} onCancel={onClose}>
    <Card className="mt-16" size="small" title="Your Main Objective">
      To create and maintain a sustainable economy with a high quality of life for the citizens of a developing nation,
      of which you are the leader.
    </Card>
    <Card className="mt-16" size="small" title="Overview">
      Each country in the simulation bears the name of a country in the real world. These countries begin the simulation
      with a <b>population</b> and <b>natural resources</b> that are representative of that country in the real world,
      and in relative proportion to natural resources in the real world. See notes on how population and natural
      resources were determined.
      <br />
      <br />
      <Card size="small">
        <b>Step One</b>
        <br />
        <br />
        Enter your username and password
        <br />
        <br />
        Click on "Enter Country."
        <br />
        <br />
        On the dashboard, click on "National Briefing." Get acquainted with your country's natural resources and
        starting population by using the tab "Resources and Products."
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Two</b>
        <br />
        <br />
        On the dashboard, click "Goals."
        <br />
        <br />
        There are 12 economic development objectives. Using your own values and experience, drop and drag these "Goals"
        to rank them in order of importance, 1 through 12, with 1 being your most important goal and 12 being your least
        import goal. These serve as your national priorities and their rankings will be the "weights" by which your
        achievements will be multiplied.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Three</b>
        <br />
        <br />
        On the dashboard, click "Production."
        <br />
        <br />
        Using your country's natural resources and population, produce (or trade with other countries to produce) items
        that will improve your citizens' standard of living, which is measured by your country's GDP. You can see your
        success in the scores that will be posted in National Briefing within the "Evaluation and Score" tab. These are
        impacted by the weights you assigned in step two.
        <br />
        <br />
        To produce products, on the dashboard click "Production." Choose a category, such as "Agricultural Products."
        Click on a specific resource that you want to produce, such as "Fish." Click on each of the Processes to see the
        ways you can produce the item you have chosen. Those at the bottom of the process list are generally the easiest
        way; those toward the top are more efficient but more difficult and they generate more pollution.
        <br />
        <br />
        When you choose a "Process" the specifics of that process will appear on the right side of the screen. If the
        "Factor" box is green, you have the resources to use that process; if it is red, you do not have enough of one
        or more of the items needed for this production, so you will have to choose another process or another product.
        <br />
        <br />
        What to produce?
        <br />
        <br />
        Produce enough food and water to sustain your people, who each need 50 carbohydrate units, 50 protein units, and
        50 water units per year. You can use Grains Land and Tubers Land to produce needed carbohydrates; Riparian Land
        and Grazing Land to produce Fish and Meat; and Water Resources to Produce Potable Water and Non-Potable Water.
        Improve your production output using optional items like Fertilizer and Pesticide. Grow Specialty Foods to sell
        in international trade.
        <br />
        <br />
        Derive Lumber from Forest Land for construction and trade.
        <br />
        <br />
        Build Primary Schools, Secondary Schools, and Universities. Educate your people in different ways to develop
        Semi-Skilled Labor, Skilled Labor, Teachers, Engineers, Ecologists, Managers, Doctors, and Philanthropists.
        <br />
        <br />
        Produce Cotton from Cotton Land, and convert it into Cloth or graze Sheep for their Wool to manufacture
        Non-Durable Consumer Goods.
        <br />
        <br />
        Build Cultural Facilities, set aside land for National Parks, and Beautify Land that has been mined and is no
        longer useable for production, to improve your population's quality of life. Promote Tourism to earn Gold from
        international trade.
        <br />
        <br />
        Build Pharmacies, Clinics, and Hospitals and educate Doctors to improve your population's health and
        preparedness for unforeseen events, like a disease outbreak. Computer-generated disasters may happen at any
        time.
        <br />
        <br />
        Mine your Mineral Deposits to obtain Bauxite, Coal, Copper, Gold, High Tech Metals, Iron, and Petroleum. Use
        them for international trade and/or to manufacture Steel, Aluminum, Durable Consumer Goods, Gasoline, Major
        Consumer Purchases, Pipe, Pumps, Computer Chips, Weapons, Wire, Small Machines, Agricultural Equipment,
        Construction Equipment, Mining Equipment, and Production Equipment.
        <br />
        <br />
        Build Information Systems, Transportation Systems, Power Systems, and Complete Communications Systems to
        multiply the production output that can be achieved by simpler means. They are found in Production under
        Optional Inputs.
        <br />
        <br />
        Train Soldiers, Junior Military Officers, and Senior Military Officers, and build Small Weapons, Medium Weapons,
        and Heavy Weapons to be able to successfully defend your country from possible attack and/or to launch an attack
        against another country (see Rules of War tab in Military).
        <br />
        <br />
        Because manufacturing and large-scale agriculture causes Pollution, you can minimize and/or clean-up pollution
        when producing various things when you use Optional Inputs, like Ecologists, Pollution Control Devices, and/or
        Pollution Clean-Up.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Four</b>
        <br />
        <br />
        On the dashboard, click "Trade."
        <br />
        <br />
        Conduct international trade by offering your goods or requesting goods from others. Set you own prices or
        negotiate. If you see a product offered on the trade board that you want, click "Purchase" button on it to
        purchase all or part of it. If you want to remove or modify the quantity of a product you have offered or
        requested click "Manage" button on it to make the change.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Five</b>
        <br />
        <br />
        On the dashboard, click "Population" to manage your population in three ways:
        <br />
        <br />
        Use the "Reproduction" tab to reduce your "fertile" population which will influence how many "Dependent" babies
        are born at the end of the simulated year;
        <br />
        <br />
        Use the "Nutrition" tab to monitor your food needs and status;
        <br />
        <br />
        Use the "Migration" tab to offer or request labor. Click to activate selected personnel.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Six</b>
        <br />
        <br />
        On the dashboard, click "Military." Here you can review your military status and the rules of war, along with
        seeing a history of wars that have taken place, if any.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Seven</b>
        <br />
        <br />
        To review your progress, click "National Briefing" on the dashboard and use the "Resources and Products",
        "Economic Goals", "Key Data By Year", "Evaluation and Score", and "Transaction History" tabs.
      </Card>
      <Card className="mt-8" size="small">
        <b>Step Eight</b>
        <br />
        <br />
        At the end of each simulated year (as defined by the Administrator for your simulation) you need to end your
        year. Your simulation year ends when you either feed your people or the time for the year expires as indicated
        in the black header above. Only World Briefing, National Briefing, and Goals buttons will be active until a new
        year is announced. Your simulation year ends when you either feed your people or the time for the year expires
        as indicated in the black header above. Only World Briefing, National Briefing, and Goals buttons will be active
        until a new year is announced. On the dashboard, click "End Year." Here you will provide your population with
        the needed carbohydrates, proteins, and water that you produced. When you have fed your people, you will see how
        many births your country has and any random deaths that may have occurred in addition to deaths, if any, from
        lack of food. Note: Your population's births are called Dependent Population for one year, after which they
        automatically become "Unskilled Labor."
        <br />
        <br />
        After reviewing these results, click "End Year" at the bottom of the screen and you are ready to begin the next
        year in the simulation using steps three to eight above.
      </Card>
    </Card>
    <Card className="mt-16" size="small" title="Helpful Hints">
      1. In Production, a production factor value may be exceeded. For example, if the factor value is 10, you may enter
      factors inputs which when multiplied together exceed 10. However, you will not get any extra output as a result,
      but you will have wasted some inputs. Also, no fractional input values are accepted.
      <br />
      <br />
      2. Some production techniques generate pollution. It is usually easier to prevent pollution, using an Ecologist or
      Pollution Control Device, than it is to clean up pollution using Pollution Clean-Up units once it has occurred.
      <br />
      <br />
      3. Optional Inputs may be used to increase production output and/or increase or decrease potential pollution
      depending on which optional inputs are used. Hover your cursor over the specific optional input icon to see a tool
      tip explaining their effect.
      <br />
      <br />
      4. Educating people does not add to your population. The person changes to a higher educational level, now able to
      do different or more productive tasks.
      <br />
      <br />
      5. A military person in your country may not do civilian work, so think carefully if you build a military. Be sure
      to read the Rules of War by clicking "Military" on the dashboard and choosing the Rules of War tab.
      <br />
      <br />
      6. Many production items, as in real life, have a limited useful lifetime or have a limited number of uses in a
      given time. Such depreciation of capital must be taken into account. Click on National Briefing and the tab
      "Master List" to see the Annual Uses and Lifetime uses of these products and people. For example, one Small
      Machine has a maximum of 5 annual uses and 15 lifetime uses. When the annual uses have been used, they come back
      each year until the lifetime uses have been exhausted. At that point, it no longer exists. Many other products,
      such as Coal, Fish, or Wire, come in bulk and their quantity, annual uses, and lifetime uses are the same, and
      their uses do not come back each year.
      <br />
      <br />
      7. When their lifetime uses have been reached, People become "Retired Population" and must be fed, but no longer
      work.
      <br />
      <br />
      8. Information Systems, Power Systems, Transportation Systems, and Complete Communications Systems, once they have
      been built and have used their lifetime uses, will still exist and may be "renewed" with fewer factor inputs. They
      also may be rebuilt from scratch.
      <br />
      <br />
      9. Computer-generated disasters may occur at any time, so be prepared in advance by building health facilities and
      educating Doctors. It's too late to produce them for useful effect after the disaster.
      <br />
      <br />
      10. Tourism Tickets and Specialty Foods purchased in international trade earn bonus points.
    </Card>
  </Modal>
);
