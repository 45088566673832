import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Menu } from 'antd';
import Swal from 'sweetalert2';
import InputNumber2 from '../../../../components/InputNumber2/InputNumber2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';
import Utils from '../../../../utils';
import { Page, SelectOption, SummaryTable } from './styles';
import { OptionalFactor } from './OptionalFactor';
import { RecipeCard } from './RecipeCard';

const SystemResourceIds = [15, 50, 65, 109, 111, 112, 133];

const getResourceTypeList = () => {
  return DataService.getItem('resourceTypeList')
    .sort((a, b) => a.resourceTypeNameLocalized.localeCompare(b.resourceTypeNameLocalized))
    .map(rt => {
      const iconName = rt.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resource-types/${iconName}`;
      const item = {
        key: rt.resourceTypeId,
        label: (
          <SelectOption style={{ height: '100%' }}>
            <img src={iconUrl} alt="" />
            <div>{rt.resourceTypeNameLocalized}</div>
          </SelectOption>
        ),
      };
      return item;
    });
};

const getResourceList = (resourceTypeId, simulationCountry) => {
  return DataService.getItem('resourceList')
    .filter(r => r.resourceTypeId === resourceTypeId)
    .filter(r => getRecipeList(r.resourceId).length > 0)
    .sort((a, b) => a.resourceNameLocalized.localeCompare(b.resourceNameLocalized))
    .map(r => {
      const scResource = simulationCountry?.simulationCountryResourceViewList.find(
        scr => scr.resourceId === r.resourceId,
      );
      const iconName = r.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resources/${iconName}`;
      const item = {
        key: r.resourceId,
        label: (
          <div style={{ flexDirection: 'column', height: '100%' }} className="d-flex justify-center">
            <SelectOption>
              <img src={iconUrl} alt="" />
              <div>{r.resourceNameLocalized}</div>
            </SelectOption>
            <div className="quantity">
              <div>Quantity</div>
              <div> {(scResource?.remainingQuantity ?? 0).toFixed(0)}</div>
            </div>
          </div>
        ),
      };
      return item;
    });
};

const getRecipeList = resourceId => {
  const resourceList = DataService.getItem('resourceList');
  return DataService.getItem('recipeList')
    .filter(r => r.resourceIdProduced === resourceId)
    .sort((a, b) => a.recipeName.localeCompare(b.recipeName))
    .map(r => {
      const iconUrls = [];
      r.recipeFunctionViewList.forEach(rfv => {
        const primaryResource = resourceList.find(resource => resource.resourceId === rfv.PrimaryResourceIdUsed);
        const secondaryResource = resourceList.find(resource => resource.resourceId === rfv.SecondaryResourceIdUsed);
        if (primaryResource) {
          const iconName = primaryResource.iconUrl.split('/').pop();
          iconUrls.push(`/images/icons/resources/${iconName}`);
        }
        if (secondaryResource) {
          const iconName = secondaryResource.iconUrl.split('/').pop();
          iconUrls.push(`/images/icons/resources/${iconName}`);
        }
      });
      return {
        key: r.recipeId,
        label: (
          <SelectOption style={{ height: '100%', justifyContent: 'center' }}>
            {iconUrls.map((iconUrl, index) => (
              <img style={{ margin: '0 4px' }} src={iconUrl} key={`recipe-${r.recipeId}-${index}`} alt="" />
            ))}
          </SelectOption>
        ),
      };
    });
};

export const Production = () => {
  const { simulationCountry, fetchSimulationCountry } = useSimulationContext();
  const resourceList = DataService.getItem('resourceList');
  const recipeList = DataService.getItem('recipeList');
  const resourceTypeList = getResourceTypeList();
  const [selectedResourceTypeId, setSelectedResourceTypeId] = useState(null);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [filteredResourceList, setFilteredResourceList] = useState([]);
  const [filteredRecipeList, setFilteredRecipeList] = useState([]);
  const [recipeData, setRecipeData] = useState(null);
  const [basicProductionValues, setBasicProductionValues] = useState([]);
  const [optionalFactorValues, setOptionalFactorValues] = useState([]);
  const [repeatCount, setRepeatCount] = useState(1);
  const [totalResource, setTotalResource] = useState(0);
  const [totalPollution, setTotalPollution] = useState(0);

  const summaryData = useMemo(() => {
    if (!simulationCountry || !recipeData) {
      return null;
    }
    const rows = [];
    let isProduceEnabled = true;
    let isExceeded = false;
    let currentHR = -1;

    if (Utils.getResourceTypeNameById(selectedResourceTypeId) === 'Human Resources') {
      const scResources = simulationCountry.simulationCountryResourceViewList;
      recipeData.basicProduction.forEach((bp, _index) => {
        // const bpValue = basicProductionValues[index];
        const primarySCResource = scResources.find(scr => scr.resourceId === bp.PrimaryResourceIdUsed);
        // const secondarySCResource = scResources.find(scr => scr.resourceId === bp.SecondaryResourceIdUsed);

        if (Utils.getResourceTypeNameByResourceId(bp.PrimaryResourceIdUsed) === 'Human Resources') {
          if (repeatCount > primarySCResource.remainingQuantity) {
            currentHR = primarySCResource.remainingQuantity;
          }
        }
        // if (secondarySCResource) {
        //   if (Utils.getResourceTypeNameByResourceId(bp.SecondaryResourceIdUsed) === 'Human Resources') {
        //     if (bpValue.secondaryResourceCount * repeatCount > secondarySCResource.remainingQuantity) {
        //       currentHR = secondarySCResource.remainingQuantity;
        //     }
        //   }
        // }
      });
    }

    recipeData.basicProduction.forEach((bp, index) => {
      const bpValue = basicProductionValues[index];
      const primaryResource = resourceList.find(r => r.resourceId === bp.PrimaryResourceIdUsed);
      const secondaryResource = resourceList.find(r => r.resourceId === bp.SecondaryResourceIdUsed);
      rows.push({
        col1: primaryResource.resourceNameLocalized,
        col2: bpValue.primaryResourceCount,
        col3: bpValue.primaryResourceCount * repeatCount,
      });
      if (secondaryResource) {
        rows.push({
          col1: secondaryResource.resourceNameLocalized,
          col2: bpValue.secondaryResourceCount,
          col3: bpValue.secondaryResourceCount * repeatCount,
        });
        if (bpValue.primaryResourceCount * bpValue.secondaryResourceCount < bp.ProductRequired) {
          isProduceEnabled = false;
        }
      } else if (bpValue.primaryResourceCount < bp.ProductRequired) {
        isProduceEnabled = false;
      }

      const primarySCResource = simulationCountry.simulationCountryResourceViewList.find(
        scr => scr.resourceId === bp.PrimaryResourceIdUsed,
      );
      const secondarySCResource = simulationCountry.simulationCountryResourceViewList.find(
        scr => scr.resourceId === bp.SecondaryResourceIdUsed,
      );
      if (bpValue.primaryResourceCount * repeatCount > primarySCResource.currentSimulationYearRemainingUses) {
        isExceeded = true;
      }
      if (secondarySCResource) {
        if (bpValue.secondaryResourceCount * repeatCount > secondarySCResource.currentSimulationYearRemainingUses) {
          isExceeded = true;
        }
      }
    });

    let productionAlpha = 1;
    let productionBeta = 0;
    let productionMultiplier = 1;
    let pollutionAlpha = 1;
    let pollutionBeta = 0;
    let pollutionMultiplier = 1;

    recipeData.optionalInputFactors.forEach((oif, index) => {
      const ofValue = optionalFactorValues[index];
      const usesRequired = ofValue.checked ? oif.UsesRequired : 0;
      const oifSCResource = simulationCountry.simulationCountryResourceViewList.find(
        scr => scr.resourceId === oif.ResourceIdUsed,
      );

      if (ofValue.checked) {
        if (SystemResourceIds.includes(oif.ResourceIdUsed)) {
          if (oif.ProductionAlpha > 0) {
            productionMultiplier = oif.ProductionAlpha;
          }
          if (oif.PollutionAlpha > 0) {
            pollutionMultiplier = oif.PollutionAlpha;
          }

          if (oifSCResource.currentSimulationYearRemainingUses < usesRequired) {
            isExceeded = true;
          }
        } else {
          if (oif.ProductionAlpha > 0) {
            productionAlpha *= oif.ProductionAlpha;
          }
          if (oif.PollutionAlpha > 0) {
            pollutionAlpha *= oif.PollutionAlpha;
          }

          if (oifSCResource.currentSimulationYearRemainingUses < usesRequired * repeatCount) {
            isExceeded = true;
          }
        }
        productionBeta += oif.ProductionBeta;
        pollutionBeta += oif.PollutionBeta;
      }

      rows.push({
        col1: oif.resourceNameLocalized,
        col2: usesRequired,
        col3: SystemResourceIds.includes(oif.ResourceIdUsed) ? usesRequired : usesRequired * repeatCount,
        bgColor: oif.ConversionResourceId === 0 ? '#BFFFFE' : '#FFFFCA',
      });
    });

    let resourceQuantity = +recipeData.resourceQuantity.split(' ')[0];
    resourceQuantity = productionAlpha * resourceQuantity + productionBeta;
    resourceQuantity *= productionMultiplier;
    let pollutionQuantity = pollutionAlpha * recipeData.pollutionQuantity + pollutionBeta;
    pollutionQuantity *= pollutionMultiplier;

    setTotalResource(resourceQuantity * repeatCount);
    setTotalPollution(pollutionQuantity * repeatCount);

    return {
      isProduceEnabled,
      currentHR,
      isExceeded,
      summaryTableData: rows,
    };
    // eslint-disable-next-line
  }, [simulationCountry, selectedResourceTypeId, recipeData, basicProductionValues, optionalFactorValues, repeatCount]);

  const getRecipeData = () => {
    const data = {};
    const selectedResource = resourceList.find(r => r.resourceId === selectedResourceId);
    if (!selectedResource) return;
    const selectedRecipe = recipeList.find(r => r.recipeId === selectedRecipeId);
    if (!selectedRecipe) return;
    const scResource = simulationCountry.simulationCountryResourceViewList.find(
      r => r.resourceId === selectedResourceId,
    );

    // Summary
    const iconName = selectedResource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;
    data.iconUrl = iconUrl;
    data.resourceName = selectedResource.resourceNameLocalized;
    data.currentAmount = scResource.remainingQuantity.toLocaleString();
    data.ngv = scResource.ngv;
    data.resourceQuantity = selectedRecipe.resourceQuantity > 1 ? `${selectedRecipe.resourceQuantity} Units` : '1 Unit';
    data.pollutionQuantity = selectedRecipe.pollutionQuantity;

    // Description
    data.description = selectedRecipe.resourceDescription
      .replaceAll('<TextFlow xmlns="http://ns.adobe.com/textLayout/2008" fontSize="12">', '<div>')
      .replaceAll('</TextFlow>', '</div>')
      .replaceAll('<span fontWeight="bold">', '<b>')
      .replaceAll('</span>', '</b>');

    // Basic Production
    data.basicProduction = selectedRecipe.recipeFunctionViewList;

    const bpValues = [];
    data.basicProduction.forEach(() => {
      bpValues.push({
        primaryResourceCount: 0,
        secondaryResourceCount: 0,
      });
    });
    setBasicProductionValues(bpValues);

    // Optional Input Factors
    data.optionalInputFactors = selectedRecipe.recipeOptionalViewList.map(rov => {
      const resource = resourceList.find(r => r.resourceId === rov.ResourceIdUsed);
      const iconName = resource.iconUrl.split('/').pop();
      const iconUrl = `/images/icons/resources/${iconName}`;

      const scResource = simulationCountry.simulationCountryResourceViewList.find(
        r => r.resourceId === rov.ResourceIdUsed,
      );

      return {
        ...rov,
        iconUrl,
        resourceNameLocalized: resource.resourceNameLocalized,
        currentSimulationYearRemainingUses: scResource.currentSimulationYearRemainingUses,
      };
    });

    setRecipeData(data);

    const ofValues = data.optionalInputFactors.map(of => ({
      resourceId: of.ResourceIdUsed,
      disabled: of.currentSimulationYearRemainingUses === 0,
      checked: false,
    }));
    setOptionalFactorValues(ofValues);

    setRepeatCount(1);
  };

  const handleChangeBasicProduction = (index, primaryResourceCount, secondaryResourceCount) => {
    const bpValues = [...basicProductionValues];
    bpValues[index].primaryResourceCount = primaryResourceCount;
    bpValues[index].secondaryResourceCount = secondaryResourceCount;
    setBasicProductionValues(bpValues);
  };

  const handleChangeOptionalFactor = (index, checked) => {
    const optionalInputFactor = recipeData.optionalInputFactors[index];
    const ofValues = [...optionalFactorValues];
    ofValues[index].checked = checked;

    if (SystemResourceIds.includes(optionalInputFactor.ResourceIdUsed) && checked) {
      ofValues
        .filter((_, i) => SystemResourceIds.includes(recipeData.optionalInputFactors[i].ResourceIdUsed) && i !== index)
        .forEach(of => (of.disabled = true));
    } else if (SystemResourceIds.includes(optionalInputFactor.ResourceIdUsed) && !checked) {
      ofValues.forEach(of => (of.disabled = false));
    }
    setOptionalFactorValues(ofValues);
  };

  const handleOpenCalculator = () => {
    const width = 320;
    const height = 580;
    // eslint-disable-next-line
    const left = screen.width / 2 - width / 2;
    // eslint-disable-next-line
    const top = screen.height / 2 - height / 2;
    window.open(
      'https://www.theonlinecalculator.com',
      '_blank',
      `width=${width},height=${height},left=${left},top=${top}`,
    );
  };

  const handleProduce = async () => {
    if (summaryData.currentHR !== -1) {
      await Swal.fire({
        title: 'Human Resources Error',
        icon: 'error',
        text: `The maximum that can be educated now is ${summaryData.currentHR}, the actual number of people in this category or their uses remaining for the year, whichever is smaller.`,
      });
      return;
    }

    if (summaryData.isExceeded) {
      await Swal.fire({
        title: 'Production Error',
        icon: 'error',
        titleText: 'Available Uses Exceeded',
        text: 'The specified production inputs and repetitions exceed the uses you have available. Please change the production inputs to lower the number of uses required.',
      });
      return;
    }

    const result = await Swal.fire({
      title: '<h6 style="margin: 0;">Do you want to produce the following?<h6>',
      icon: 'question',
      html: `
        <div style="display: flex; justify-content: center;">
          <div style="min-width: 360px;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <img width="24" src="${recipeData.iconUrl}" />
                <div style="margin-left: 4px;">${recipeData.resourceName}</div>
              </div>
              <div>${totalResource}</div>
            </div>
            <hr />
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <div style="width: 24px"></div>
                <div style="margin-left: 4px;">Pollution</div>
              </div>
              <div>${totalPollution}</div>
            </div>
          </div>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });
    if (result.isConfirmed) {
      const recipeObject = {
        recipeId: selectedRecipeId,
        recipeFunctionObjectList: basicProductionValues.map((bp, index) => ({
          primaryResourceUses: bp.primaryResourceCount,
          secondaryResourceUses: bp.secondaryResourceCount,
          recipeFunctionIndex: index + 1,
        })),
        recipeOptionalResourceIdList: [],
      };
      recipeData.optionalInputFactors.forEach((oif, index) => {
        if (optionalFactorValues[index].checked) {
          recipeObject.recipeOptionalResourceIdList.push(oif.ResourceIdUsed);
        }
      });

      await ApiService.addSimulationCountryRecipeUse(
        simulationCountry.simulationCountryId,
        repeatCount,
        JSON.stringify(recipeObject),
      );

      fetchSimulationCountry();
      await Swal.fire({
        title: 'Production Completed',
        icon: 'success',
      });
    }
  };

  useEffect(() => {
    setSelectedResourceId(null);
    // eslint-disable-next-line
  }, [selectedResourceTypeId]);

  useEffect(() => {
    setFilteredResourceList(getResourceList(selectedResourceTypeId, simulationCountry));
    // eslint-disable-next-line
  }, [selectedResourceTypeId, simulationCountry]);

  useEffect(() => {
    setFilteredRecipeList(getRecipeList(selectedResourceId));
    setSelectedRecipeId(null);
    // eslint-disable-next-line
  }, [selectedResourceId]);

  useEffect(() => {
    if (selectedRecipeId) {
      getRecipeData();
    } else {
      setRecipeData(null);
    }
    // eslint-disable-next-line
  }, [selectedRecipeId]);

  useEffect(() => {
    getRecipeData();
    // eslint-disable-next-line
  }, [simulationCountry]);

  return (
    <Page size="small" title="Production">
      <div className="d-flex">
        <div>
          <div className="menu-title">Category ({resourceTypeList.length})</div>
          <Menu
            style={{ minWidth: 180, height: 'calc(100vh - 170px)', overflowY: 'auto' }}
            items={resourceTypeList}
            onSelect={e => setSelectedResourceTypeId(+e.key)}
          />
        </div>
        <div>
          <div className="menu-title">Product ({filteredResourceList.length})</div>
          <Menu
            style={{ minWidth: 180, height: 'calc(100vh - 170px)', overflowY: 'auto' }}
            items={filteredResourceList}
            onSelect={e => setSelectedResourceId(+e.key)}
          />
        </div>
        <div>
          <div className="menu-title">Process ({filteredRecipeList.length})</div>
          <Menu
            style={{ minWidth: 160, height: 'calc(100vh - 170px)', overflowY: 'auto' }}
            items={filteredRecipeList}
            onSelect={e => setSelectedRecipeId(+e.key)}
          ></Menu>
        </div>
        {!!recipeData && (
          <div style={{ padding: 8, height: 'calc(100vh - 170px)', overflowY: 'auto' }}>
            <Card size="small" className="recipe-summary">
              <div className="d-flex align-center">
                <img width={96} src={recipeData.iconUrl} alt="" />
                <div className="recipe-summary-data">
                  <h2>{recipeData.resourceName}</h2>
                  <p>
                    <b>Current Amount: </b>
                    {recipeData.currentAmount}
                  </p>
                  <p>
                    <b>GDP Value Per Unit: </b>
                    {recipeData.ngv}
                  </p>
                </div>
                <div>
                  <h3>{recipeData.resourceQuantity}</h3>
                  <p>
                    <b>{recipeData.pollutionQuantity} Pollution</b>
                  </p>
                </div>
              </div>
              {!!recipeData && (
                <div className="description" dangerouslySetInnerHTML={{ __html: recipeData.description }} />
              )}
            </Card>

            {!!simulationCountry && (
              <>
                <Card
                  className="mt-16 basic-production"
                  title="Basic Production"
                  extra={
                    <Button type="primary" size="small" onClick={handleOpenCalculator}>
                      Open Calculator
                    </Button>
                  }
                  size="small"
                >
                  <p>
                    Multiply any combination of input uses to the right to equal or exceed the amount in the Factor box
                    to the left: If the Factor box on the left is red = insufficient quantities for production.
                  </p>
                  {recipeData.basicProduction.map((bp, index) => (
                    <RecipeCard
                      scResources={simulationCountry.simulationCountryResourceViewList}
                      data={bp}
                      repeatCount={repeatCount}
                      index={index}
                      onChange={handleChangeBasicProduction}
                      key={bp.FunctionDescription}
                    />
                  ))}
                </Card>
                {recipeData.optionalInputFactors.length > 0 && (
                  <Card className="mt-16" title="Optional Input Factors" size="small">
                    {recipeData.optionalInputFactors.map((oif, index) => (
                      <OptionalFactor
                        optionalFactor={oif}
                        index={index}
                        repeatCount={repeatCount}
                        disabled={optionalFactorValues[index]?.disabled}
                        checked={optionalFactorValues[index]?.checked}
                        onChange={handleChangeOptionalFactor}
                        key={`oif-${oif.ResourceIdUsed}`}
                      />
                    ))}
                  </Card>
                )}
              </>
            )}

            {!!simulationCountry && (
              <Card className="mt-16" title="Summary: Current Production Procedure" size="small">
                <SummaryTable>
                  <thead>
                    <tr>
                      <th>Inputs</th>
                      <th>Basic Inputs</th>
                      <th>Total Inputs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summaryData &&
                      summaryData.summaryTableData.map((row, index) => (
                        <tr key={`summary-${row[0]}-${index}`}>
                          <td style={{ backgroundColor: row.bgColor ?? '#FFFFFF' }}>{row.col1}</td>
                          <td style={{ backgroundColor: row.bgColor ?? '#FFFFFF' }}>{row.col2}</td>
                          <td style={{ backgroundColor: row.bgColor ?? '#FFFFFF' }}>{row.col3}</td>
                        </tr>
                      ))}
                    <tr>
                      <td></td>
                      <td>Projected Resource Output</td>
                      <td>{totalResource}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Projected Pollution Output</td>
                      <td>{totalPollution}</td>
                    </tr>
                  </tbody>
                </SummaryTable>
              </Card>
            )}

            <div className="d-flex justify-center align-center mt-16">
              <label className="mr-8">Repeat Procedure?</label>
              <InputNumber2
                className="mr-8"
                min={1}
                max={1000}
                disabled={!summaryData.isProduceEnabled}
                value={repeatCount}
                onChange={e => setRepeatCount(e)}
              />
              <Button type="primary" disabled={!summaryData.isProduceEnabled} onClick={handleProduce}>
                Produce
              </Button>
            </div>
          </div>
        )}
        {!recipeData && selectedResourceId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              height: 'calc(100vh - 170px)',
              fontSize: 20,
              color: '#ff0000',
            }}
          >
            Select a Process
          </div>
        )}
        {!recipeData && !selectedResourceId && selectedResourceTypeId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              height: 'calc(100vh - 170px)',
              fontSize: 20,
              color: '#ff0000',
            }}
          >
            Select a Product
          </div>
        )}
        {!recipeData && !selectedResourceId && !selectedResourceTypeId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              height: 'calc(100vh - 170px)',
              fontSize: 20,
              color: '#ff0000',
            }}
          >
            Select a Category
          </div>
        )}
      </div>
    </Page>
  );
};
