import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification, Button, Card, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import { MainContext } from '../../../layouts/Main/Context';
import { CLASS_TEACHERS_TABLE_COLUMNS } from './data';
import { ActionColumn } from './styles';
import { ClassTeacherModal } from './ClassTeacherModal';
import Utils from '../../../utils';

export const ClassTeachers = () => {
  const { classId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const { setBreadcrumbItems } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);
  const [members, setMembers] = useState([]);
  const [classTeachers, setClassTeachers] = useState([]);
  const [teacher, setTeacher] = useState(false);

  const fetchClass = async () => {
    try {
      const data = await ApiService.retrieveClass(classId);
      if (data.success) {
        setCurrentClass(data.result);
        setBreadcrumbItems([
          {
            title: 'Classes',
            path: '/main/classes',
          },
          {
            title: data.result.className,
            path: `/main/classes/${data.result.classId}`,
          },
          {
            title: 'Teachers',
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const fetchMembers = async () => {
    try {
      const data = await ApiService.retrieveMemberList(classId);
      if (data.success) {
        setMembers(data.resultList);
      }
    } catch (e) {
      //
    }
  };

  const fetchClassTeachers = async () => {
    setLoading(true);
    try {
      const data = await ApiService.retrieveClassTeacherList(classId);
      if (data.success) {
        setClassTeachers(data.resultList);
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const handleAddTeacher = () => {
    setTeacher({});
  };

  const handleCloseTeacherModal = () => {
    setTeacher(null);
  };

  const handleTeacherSaved = () => {
    setTeacher(null);
    fetchClassTeachers();
  };

  const handleDeleteTeacher = async teacher => {
    try {
      await ApiService.deleteClassTeacher(currentClass.classId, teacher.userId);
      await fetchClassTeachers();
    } catch (error) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
  };

  useEffect(() => {
    fetchClass();
    fetchMembers();
    fetchClassTeachers();
    // eslint-disable-next-line
  }, []);

  const classTeachersTableColumns = [...CLASS_TEACHERS_TABLE_COLUMNS];
  if (Utils.checkPermissions('Admin of School', currentClass?.schoolId)) {
    classTeachersTableColumns.push({
      id: 'actions',
      title: '',
      render: row => (
        <ActionColumn>
          <Popconfirm title="Are you sure to delete this teacher?" onConfirm={() => handleDeleteTeacher(row)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </ActionColumn>
      ),
    });
  }

  const ActionBar = (
    <>
      <Button size="small" type="primary" onClick={handleAddTeacher}>
        Add Teacher
      </Button>
    </>
  );

  return (
    <>
      {contextHolder}
      <Card
        size="small"
        title="Teachers"
        extra={Utils.checkPermissions('Admin of School', currentClass?.schoolId) ? ActionBar : null}
      >
        <Table
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          loading={isLoading}
          columns={classTeachersTableColumns}
          dataSource={classTeachers}
          rowKey="userId"
          bordered
          head
        />
      </Card>
      <ClassTeacherModal
        currentClass={currentClass}
        members={members}
        teacher={teacher}
        onClose={handleCloseTeacherModal}
        onSave={handleTeacherSaved}
      />
    </>
  );
};
