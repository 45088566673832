import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, InputNumber, Row } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';

export const Feed = ({ onChangeStep }) => {
  const resourceList = DataService.getItem('resourceList');
  const params = useParams();
  const { fetchSimulationCountry } = useSimulationContext();
  const [simulationCountry, setSimulationCountry] = useState(null);
  const [inputs, setInputs] = useState([0, 0, 0, 0, 0, 0, 0]);

  const getResourceDetails = resourceName => {
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    let scResource = simulationCountry?.simulationCountryResourceViewList.find(
      r => r.resourceId === resource.resourceId,
    );

    if (!scResource) {
      scResource = {
        remainingQuantity: 0,
      };
    }

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;
    return {
      ...resource,
      ...scResource,
      iconUrl,
    };
  };

  const requiredUnits = (simulationCountry?.population ?? 0) * 50;
  const sections = [
    {
      title: 'Available Carbohydrates',
      subtitle: `${requiredUnits} Units Required`,
      resources: [getResourceDetails('Tubers'), getResourceDetails('Bread')],
    },
    {
      title: 'Available Proteins',
      subtitle: `${requiredUnits} Units Required`,
      resources: [getResourceDetails('Fish'), getResourceDetails('Meat')],
    },
    {
      title: 'Available Water',
      subtitle: `${requiredUnits} Units Required`,
      resources: [getResourceDetails('Non-Potable Water'), getResourceDetails('Potable Water')],
    },
    {
      title: 'Available Imported Specialty Food',
      subtitle: 'Optional Unit',
      resources: [
        getResourceDetails('Imported Specialty Foods'),
        {
          ...getResourceDetails('Imported Specialty Foods'),
          resourceNameLocalized: 'Bonus points from eating Imported Specialty Foods:',
          computed: true,
        },
      ],
    },
  ];

  const getFeedableCount = () => {
    const carbohydratesAmount = inputs[0] + inputs[1];
    const proteinsAmount = inputs[2] + inputs[3];
    const waterAmount = inputs[4] + inputs[5];
    const amount = Math.min(carbohydratesAmount, proteinsAmount, waterAmount);
    return Math.floor(amount / 50);
  };

  const loadData = async () => {
    const data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    setSimulationCountry(data.result);
  };

  const handleChangeInput = index => e => {
    const newInputs = [...inputs];
    newInputs[index] = e;
    setInputs(newInputs);
  };

  const handleFeedPeople = async () => {
    const feedableCount = getFeedableCount();
    const result = await Swal.fire({
      title: 'Do you want to proceed?',
      icon: 'question',
      html: `
        <p>The current population is ${simulationCountry?.population}</p>
        <p>The designated food will feed ${feedableCount} ${feedableCount < 2 ? 'person' : 'people'}</p>
      `,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });
    if (result.isConfirmed) {
      const carbohydrateObjectList = [
        {
          resourceId: sections[0].resources[0].resourceId,
          uses: inputs[0],
        },
        {
          resourceId: sections[0].resources[1].resourceId,
          uses: inputs[1],
        },
      ];
      const proteinObjectList = [
        {
          resourceId: sections[1].resources[0].resourceId,
          uses: inputs[2],
        },
        {
          resourceId: sections[1].resources[1].resourceId,
          uses: inputs[3],
        },
      ];
      const waterObjectList = [
        {
          resourceId: sections[2].resources[0].resourceId,
          uses: inputs[4],
        },
        {
          resourceId: sections[2].resources[1].resourceId,
          uses: inputs[5],
        },
      ];
      const specialObjectList = [
        {
          resourceId: sections[3].resources[0].resourceId,
          uses: inputs[6],
        },
      ];
      const payload = {
        carbohydrateObjectListString: JSON.stringify(carbohydrateObjectList),
        proteinObjectListString: JSON.stringify(proteinObjectList),
        waterObjectListString: JSON.stringify(waterObjectList),
        specialObjectListString: JSON.stringify(specialObjectList),
      };
      await ApiService.advanceSimulationCountry(params.simulationCountryId, payload);
      await ApiService.addSimulationCountrySnapshot(params.simulationCountryId);
      await fetchSimulationCountry();
      // await Swal.fire({
      //   title: `It's time to end the year`,
      //   text: 'Feeding the people is complete',
      //   icon: 'info',
      // });
      onChangeStep(3);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const renderSections = () =>
    sections.map((section, index) => {
      const [resource1, resource2] = section.resources;
      const remainingQuantity1 = resource1.remainingQuantity - inputs[index * 2];
      const remainingQuantity2 = resource2.remainingQuantity - inputs[index * 2 + 1];
      const total = inputs[index * 2] + (!resource2.computed ? inputs[index * 2 + 1] : 0);

      return (
        <Col span={6} key={`section-${index}`}>
          <Card size="small">
            <div style={{ fontSize: 16, fontWeight: 700 }} className="text-center">
              {section.title}
            </div>
            <div className="text-center">{section.subtitle}</div>
            <hr />
            <div className="d-flex justify-space-between mt-16">
              <div className="d-flex align-center">
                <img className="mr-8" width={20} src={resource1.iconUrl} alt={resource1.resourceNameLocalized} />
                <div style={{ fontSize: 12 }}>
                  {resource1.resourceNameLocalized}
                  <span className={remainingQuantity1 > 0 ? 'text-success' : 'text-error'}>({remainingQuantity1})</span>
                </div>
              </div>
              <InputNumber
                size="small"
                min={0}
                max={resource1.remainingQuantity}
                value={inputs[index * 2]}
                onChange={handleChangeInput(index * 2)}
              />
            </div>
            <div className="d-flex justify-space-between mt-16">
              <div className="d-flex align-center">
                <img className="mr-8" width={20} src={resource2.iconUrl} alt={resource2.resourceNameLocalized} />
                <div style={{ fontSize: 12 }}>
                  {resource2.resourceNameLocalized}
                  {!resource2.computed && (
                    <span className={remainingQuantity2 > 0 ? 'text-success' : 'text-error'}>
                      ({remainingQuantity2})
                    </span>
                  )}
                </div>
              </div>
              {!resource2.computed && (
                <InputNumber
                  size="small"
                  min={0}
                  max={resource2.remainingQuantity}
                  value={inputs[index * 2 + 1]}
                  onChange={handleChangeInput(index * 2 + 1)}
                />
              )}
              {resource2.computed && <div>{inputs[6]}</div>}
            </div>
            <div style={{ fontSize: 24 }} className="text-right mt-16">
              {total}
            </div>
            <div className="text-right">Units</div>
          </Card>
        </Col>
      );
    });

  const isEnough = getFeedableCount() >= simulationCountry?.population;

  return (
    <>
      <p>
        To prevent deaths from famine or dehydration, every person needs 50 carbohydrate units (from <b>Bread</b> and/or{' '}
        <b>Tubers</b>), <b>and</b> 50 protein units (from <b>Fish</b> and/or <b>Meat</b>), <b>and</b> 50 water units
        (from <b>Potable Water</b> and/or
        <b>Non-Potable Water</b>).
      </p>
      <p>In addition, some people may die randomly from natural causes. These random deaths are unavoidable.</p>
      <Row gutter={16}>
        <Col span={12}>
          <Card size="small">
            <div className="d-flex justify-space-between align-center">
              <img width={48} src="/images/layouts/simulation/population.png" alt="Population" />
              <div className="text-right">
                <div>Current Population is</div>
                <div style={{ fontSize: 24 }}>{simulationCountry?.population}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small">
            <div className="d-flex justify-space-between align-center">
              <img width={48} src="/images/layouts/simulation/feed.png" alt="Feed" />
              <div className="text-right">
                <div>Specified Amount Will Feed</div>
                <div style={{ fontSize: 24 }} className={isEnough ? 'text-success' : 'text-error'}>
                  {getFeedableCount()}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-16" gutter={16}>
        {renderSections()}
      </Row>
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} onClick={() => onChangeStep(1)}>
          Cancel
        </Button>
        <Button style={{ width: 200 }} className="ml-16" type="primary" onClick={handleFeedPeople}>
          Feed The People
        </Button>
      </div>
    </>
  );
};
