export const CLASS_SIMULATIONS_TABLE_COLUMNS = [
  {
    id: 'simulationName',
    title: 'Simulation Name',
    dataIndex: 'simulationName',
  },
  {
    id: 'schoolName',
    title: 'School Name',
    dataIndex: 'schoolName',
  },
  {
    id: 'numberOfSimulationCountries',
    title: 'Total Countries',
    dataIndex: 'numberOfSimulationCountries',
  },
  {
    id: 'numberOfSimulationCountryUsers',
    title: 'Total Players',
    dataIndex: 'numberOfSimulationCountryUsers',
  },
  {
    id: 'simulationYearIndex',
    title: 'Current Year',
    dataIndex: 'simulationYearIndex',
  },
  {
    id: 'simulationYearArrayLength',
    title: 'Total Years',
    dataIndex: 'simulationYearArrayLength',
  },
];
