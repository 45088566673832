import { Button, Modal } from 'antd';
import InputNumber2 from '../../../../components/InputNumber2/InputNumber2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import { useState } from 'react';
import DataService from '../../../../services/DataService';
import Swal from 'sweetalert2';

const resourceNames = ['Clinic', 'Pharmacy', 'Doctor', 'Hospital'];

export const MedicalHelpModal = ({ open, totalNeed, onSave, onClose }) => {
  const resourceList = DataService.getItem('resourceList');
  const { simulationCountry } = useSimulationContext();
  const [resources, setResources] = useState([0, 0, 0, 0]);

  if (!simulationCountry) {
    return null;
  }

  const scResources = simulationCountry.simulationCountryResourceViewList;
  const medicalResources = resourceNames.map(resourceName => {
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    const scResource = scResources.find(scr => scr.resourceId === resource.resourceId);
    scResource.resourceName = resource.resourceNameLocalized;
    return scResource;
  });

  const handleChange = index => e => {
    const newResources = [...resources];
    newResources[index] = e;
    setResources(newResources);
  };

  const handleSave = async () => {
    const totalHelp = resources.reduce((t, r) => t + r, 0);
    const result = await Swal.fire({
      title: 'Save People',
      html: `
        <div>
          Current population needing medical attention: ${totalNeed}
        </div>
        <div>
          The designated medical uses will treat: ${Math.min(totalNeed, totalHelp)}
        </div>
      `,
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      onSave(resources);
    }
  };

  return (
    <Modal width={480} centered title="Save People" footer={null} open={open} closable={false}>
      <p>A medical disaster has happened. You can use your medical resources to prevent deaths.</p>
      {medicalResources.map((medicalResource, index) => (
        <div
          className="d-flex justify-space-between mt-16"
          style={{ padding: '4px 8px', background: 'lightgrey' }}
          key={index}
        >
          <div className="d-flex align-center">
            <div style={{ fontSize: 12 }}>
              {medicalResource.resourceName}
              <span className={medicalResource.remainingQuantity > 0 ? 'text-success' : 'text-error'}>
                ({medicalResource.remainingQuantity})
              </span>
            </div>
          </div>
          <InputNumber2
            size="small"
            min={0}
            max={medicalResource.remainingQuantity}
            value={resources[index]}
            onChange={handleChange(index)}
          />
        </div>
      ))}
      <div className="d-flex justify-center align-center mt-16">
        <Button type="primary" style={{ margin: '0 8px', width: 160 }} onClick={handleSave}>
          Save People
        </Button>
        <Button style={{ margin: '0 8px', width: 160 }} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
