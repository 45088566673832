import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import ApiService from '../../../../services/ApiService';

export const NewYearAvailable = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { fetchSimulationCountry } = useSimulationContext();

  const handleContinue = async () => {
    await ApiService.advanceSimulationCountry(params.simulationCountryId, {
      carbohydrateObjectListString: '',
      proteinObjectListString: '',
      waterObjectListString: '',
      specialObjectListString: '',
    });
    await fetchSimulationCountry();
    await Swal.fire({
      title: 'A new year has been started!',
      icon: 'info',
    });
    navigate(`/portal/simulations/${params.simulationCountryId}/welcome`);
  };

  return (
    <div className="d-flex flex-column align-center">
      <h3>A new year is now available</h3>
      <img src="/images/pages/simulation/feed/calendar.png" alt="Calendar" />
      <div className="d-flex justify-center mt-16">
        <Button style={{ width: 200 }} type="primary" onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
};
