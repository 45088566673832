import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import styled from 'styled-components';
import ApiService from '../../../../../services/ApiService';
import DataService from '../../../../../services/DataService';

const Page = styled.div`
  .statistics-card .ant-card-body {
    padding: 4px 16px;
  }

  .nutrition-wrapper {
    .ant-card-body {
      .ant-card-body {
        padding: 0 16px;
      }
    }
  }
`;

export const Nutrition = () => {
  const resourceList = DataService.getItem('resourceList');
  const params = useParams();
  const [simulationCountry, setSimulationCountry] = useState(null);

  const getResourceDetails = resourceName => {
    const resource = resourceList.find(r => r.resourceNameLocalized === resourceName);
    let scResource = simulationCountry?.simulationCountryResourceViewList.find(
      r => r.resourceId === resource.resourceId,
    );

    if (!scResource) {
      scResource = {
        remainingQuantity: 0,
      };
    }

    const iconName = resource.iconUrl.split('/').pop();
    const iconUrl = `/images/icons/resources/${iconName}`;
    return {
      ...resource,
      ...scResource,
      iconUrl,
    };
  };

  const retiredPopulation = getResourceDetails('Retired Population');
  const dependentPopulation = getResourceDetails('Dependent Population');
  const tubers = getResourceDetails('Tubers');
  const bread = getResourceDetails('Bread');
  const carbohydratesStatus =
    ((tubers?.remainingQuantity + bread?.remainingQuantity) * 100) / (simulationCountry?.population * 50) - 100;
  const fish = getResourceDetails('Fish');
  const meat = getResourceDetails('Meat');
  const proteinsStatus =
    ((fish?.remainingQuantity + meat?.remainingQuantity) * 100) / (simulationCountry?.population * 50) - 100;
  const nonPotableWater = getResourceDetails('Non-Potable Water');
  const potableWater = getResourceDetails('Potable Water');
  const waterStatus =
    ((nonPotableWater?.remainingQuantity + potableWater?.remainingQuantity) * 100) /
      (simulationCountry?.population * 50) -
    100;

  const loadData = async () => {
    const data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    setSimulationCountry(data.result);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  if (!simulationCountry) {
    return null;
  }

  return (
    <Page>
      <Row gutter={16}>
        <Col span={16}>
          <Card size="small" title="Nutrition Requirements And Status">
            Your simulation year ends when you either feed your people or the time for the year expires as indicated in
            the black header above. Only World Briefing, National Briefing, and Goals buttons will be active until a new
            year is announced. Nourishment is the provision, to cells and organisms, or the materials necessary (in the
            form of food) to support life. Many common health problems can be prevented or alleviated with a healthy
            diet. A poor diet can have an injourious impact on health, causing deficiency diseases. In general, eating a
            wide variety of fresh, whole (unprocessed) foods has proven favorable for one's health compared to
            monotonous diets based on processed foods. In particular, the consumption of whole-plant foods slows
            digestion and allows better absorption, and a more favorable balance of essential nutrients per Calorie,
            resulting in better management of cell growth, maintenance, and mitosis (cell division), as well as better
            regulation of appetite and blood sugar. Regularly scheduled meals (every few hours) have also proven more
            wholesome than infrequent or haphazard ones. There are six major classes of nutrients: carbohydrates, fats,
            minerals, protein, vitamins, and water.
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Population Statistics" size="small">
            <Card className="statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>National Population</b>
                <div>{simulationCountry?.population}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Number Fertile</b>
                <div>{(simulationCountry?.population * simulationCountry?.fertility).toFixed(0)}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Retired Population</b>
                <div>{retiredPopulation?.remainingQuantity}</div>
              </div>
            </Card>
            <Card className="mt-4 statistics-card" size="small">
              <div className="d-flex justify-space-between">
                <b>Dependent Population</b>
                <div>{dependentPopulation?.remainingQuantity}</div>
              </div>
            </Card>
          </Card>
        </Col>
      </Row>
      <div className="nutrition-wrapper">
        <Card style={{ marginTop: 16 }} size="small" title="Carbohydrates">
          <Row gutter={16}>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Amount Required</h4>
                <h2 style={{ height: 52, lineHeight: '52px' }}>{(simulationCountry?.population * 50).toFixed(0)}</h2>
                <p className={carbohydratesStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  50 Carbohydrates Per Person
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h4 style={{ textAlign: 'center' }}>Amount Available</h4>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={tubers.iconUrl} alt="Tubers" width={24} />
                      <div style={{ marginLeft: 8 }}>Tubers</div>
                    </div>
                    <span>{tubers?.remainingQuantity.toFixed(0)}</span>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginTop: 8 }}
                  >
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={bread.iconUrl} alt="Bread" width={24} />
                      <div style={{ marginLeft: 8 }}>Bread</div>
                    </div>
                    <span>{bread?.remainingQuantity.toFixed(0)}</span>
                  </div>
                </div>
                <p
                  style={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px' }}
                  className={carbohydratesStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}
                >
                  <div>Total:</div>
                  <div>{(tubers?.remainingQuantity + bread?.remainingQuantity).toFixed(0)}</div>
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Current Status</h4>
                <h2
                  style={{ height: 52, lineHeight: '52px' }}
                  className={carbohydratesStatus >= 0 ? 'text-success' : 'text-error'}
                >
                  {carbohydratesStatus >= 0 ? '+  ' : ''}
                  {carbohydratesStatus.toFixed(2)}%
                </h2>
                <p className={carbohydratesStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  {carbohydratesStatus >= 0 ? 'Surplus' : 'Deficit'}
                </p>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginTop: 16 }} size="small" title="Proteins">
          <Row gutter={16}>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Amount Required</h4>
                <h2 style={{ height: 52, lineHeight: '52px' }}>{(simulationCountry?.population * 50).toFixed(0)}</h2>
                <p className={proteinsStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  50 Proteins Per Person
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h4 style={{ textAlign: 'center' }}>Amount Available</h4>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={fish.iconUrl} alt="Fish" width={24} />
                      <div style={{ marginLeft: 8 }}>Fish</div>
                    </div>
                    <span>{fish?.remainingQuantity.toFixed(0)}</span>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginTop: 8 }}
                  >
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={meat.iconUrl} alt="Meat" width={24} />
                      <div style={{ marginLeft: 8 }}>Meat</div>
                    </div>
                    <span>{meat?.remainingQuantity.toFixed(0)}</span>
                  </div>
                </div>
                <p
                  style={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px' }}
                  className={proteinsStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}
                >
                  <div>Total:</div>
                  <div>{(fish?.remainingQuantity + meat?.remainingQuantity).toFixed(2)}</div>
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Current Status</h4>
                <h2
                  style={{ height: 52, lineHeight: '52px' }}
                  className={proteinsStatus >= 0 ? 'text-success' : 'text-error'}
                >
                  {proteinsStatus >= 0 ? '+  ' : ''}
                  {proteinsStatus.toFixed(2)}%
                </h2>
                <p className={proteinsStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  {proteinsStatus >= 0 ? 'Surplus' : 'Deficit'}
                </p>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginTop: 16 }} size="small" title="Water">
          <Row gutter={16}>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Amount Required</h4>
                <h2 style={{ height: 52, lineHeight: '52px' }}>{(simulationCountry?.population * 50).toFixed(0)}</h2>
                <p className={waterStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  50 Units Of Water Per Person
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h4 style={{ textAlign: 'center' }}>Amount Available</h4>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={nonPotableWater.iconUrl} alt="Non-Potable Water" width={24} />
                      <div style={{ marginLeft: 8 }}>Non-Potable Water</div>
                    </div>
                    <span>{nonPotableWater?.remainingQuantity.toFixed(0)}</span>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginTop: 8 }}
                  >
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      <img src={potableWater.iconUrl} alt="Potable Water" width={24} />
                      <div style={{ marginLeft: 8 }}>Potable Water</div>
                    </div>
                    <span>{potableWater?.remainingQuantity.toFixed(0)}</span>
                  </div>
                </div>
                <p
                  style={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px' }}
                  className={waterStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}
                >
                  <div>Total:</div>
                  <div>{(nonPotableWater?.remainingQuantity + potableWater?.remainingQuantity).toFixed(0)}</div>
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ textAlign: 'center' }}>
                <h4>Current Status</h4>
                <h2
                  style={{ height: 52, lineHeight: '52px' }}
                  className={waterStatus >= 0 ? 'text-success' : 'text-error'}
                >
                  {waterStatus >= 0 ? '+' : ''}
                  {waterStatus.toFixed(2)}%
                </h2>
                <p className={waterStatus >= 0 ? 'bg-success text-success' : 'bg-error text-error'}>
                  {waterStatus >= 0 ? 'Surplus' : 'Deficit'}
                </p>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </Page>
  );
};
