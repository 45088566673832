import { useEffect, useState } from 'react';
import { Card, Modal } from 'antd';
import ApiService from '../../services/ApiService';

// const _OldInstruction = (
//   <>
//     <Card size="small">
//       <b>Step 1: Add Members to system:</b>
//       <br />
//       <br />
//       Click Members on dashboard.
//       <br />
//       <br />
//       Click Add Member button, fill form, click Save.
//       <br />
//       <br />
//       Repeat as many times as needed for anyone using Simpolicon.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 2: Add Schools to system:</b>
//       <br />
//       <br />
//       Click Schools on dashboard.
//       <br />
//       <br />
//       Click Add School button, fill form, click Add.
//       <br />
//       <br />
//       Repeat as needed.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 3: Add Admin to system</b>
//       <br />
//       <br />
//       Click Schools on dashboard.
//       <br />
//       <br />
//       Find school in grid and click 3 dot menu on the right.
//       <br />
//       <br />
//       Click Manage Admins.
//       <br />
//       <br />
//       Click Add Admin button.
//       <br />
//       <br />
//       Find name in pull down menu.
//       <br />
//       <br />
//       Click Add button again.
//       <br />
//       <br />
//       If need to add a new member to the system, go to MEMBERS on the dashboard.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 4: Add Teacher to system:</b>
//       <br />
//       <br />
//       Click Schools on dashboard.
//       <br />
//       <br />
//       Find school in grid and click 3 dot menu on the right.
//       <br />
//       <br />
//       Click Manage Teachers.
//       <br />
//       <br />
//       Click Add Teacher button.
//       <br />
//       <br />
//       Find teacher from pull down menu, click Add.
//       <br />
//       <br />
//       Note: the school admin can also be a teacher.
//       <br />
//       <br />
//       If need to add a new member to the system, go to MEMBERS on the dashboard.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 5: Add Class to system:</b>
//       <br />
//       <br />
//       Click Class on dashboard.
//       <br />
//       <br />
//       Click Add Class button, fill out form, click Add Class button.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 6: Add Teacher to class:</b>
//       <br />
//       <br />
//       Click Class from dashboard.
//       <br />
//       <br />
//       Find class in grid and click 3 dot menu on the right.
//       <br />
//       <br />
//       Click Manage Teachers.
//       <br />
//       <br />
//       Click Add Teacher button.
//       <br />
//       <br />
//       Find teacher from pull down menu, click Add.
//       <br />
//       <br />
//       Repeat step 5 and 6 as needed until all classes and teachers have been added to the school and all their classes.
//       <br />
//       <br />
//       If need to add a new member to the system, go to MEMBERS on the dashboard.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 7: Create Simulation:</b>
//       <br />
//       <br />
//       Click Simulation on dashboard.
//       <br />
//       <br />
//       Use existing simulation or click Add Simulation button, fill out form, click Add.
//       <br />
//       <br />
//       If class not in pull down, go back to step 5 Add Class to System.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 8: Add Country and Students to Simulation:</b>
//       <br />
//       <br />
//       Click Simulation on dashboard.
//       <br />
//       <br />
//       Find simulation in grid and click.
//       <br />
//       <br />
//       Click Countries.
//       <br />
//       <br />
//       Click Add Country.
//       <br />
//       <br />
//       Choose country from pull down menu, click Add.
//       <br />
//       <br />
//       Click on the new country in the grid.
//       <br />
//       <br />
//       Click Students button.
//       <br />
//       <br />
//       Add New Student or Add Existing Student.
//       <br />
//       <br />
//       Fill out form or use pull down.
//       <br />
//       <br />
//       Add student.
//       <br />
//       <br />
//       Repeat Step 8 until all countries and students have been added.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 9: Add Year to Simulation:</b>
//       <br />
//       <br />
//       Click Simulation on dashboard.
//       <br />
//       <br />
//       Find and click simulation from the grid.
//       <br />
//       <br />
//       Click Years & Events button.
//       <br />
//       <br />
//       Click Add Year.
//     </Card>
//     <Card className="mt-8" size="small">
//       <b>Step 10: Change Resources and Goal:</b>
//       <br />
//       <br />
//       Click Simulation on the dashboard.
//       <br />
//       <br />
//       Find and click simulation in the grid.
//       <br />
//       <br />
//       Click your country.
//       <br />
//       <br />
//       Click Resources button.
//       <br />
//       <br />
//       Click Goals button.
//     </Card>
//   </>
// );

const SuperAdminInstruction = (
  <>
    <h2>Super Admin How to Set up Simpolicon</h2>
    <h3>Simpolicon to set up New School and School Admin:</h3>
    <p>(Log in as with Super Admin user name and password)</p>
    <Card className="mt-16" size="small">
      Step 1: <b>Add School</b>
      <ol>
        <li>Click on School on Dashboard</li>
        <li>Click Add School button, fill form, click Add</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 2: <b>Add School Admin</b>
      <ol>
        <li>Click on Members on Dashboard</li>
        <li>Click on Add Member, fill out form for School Admin, click Save</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 3: <b>Add School Admin to School</b>
      <ol>
        <li>Click Schools on Dashboard</li>
        <li>Click on the name of the school</li>
        <li>Click on Admins</li>
        <li>Click on Add Admin, find School Admin’s name on drop-down, click Add</li>
      </ol>
    </Card>
    <p>Send login information to School Admin with instructions on how to enter Classes and Teacher’s names</p>
    <p>Logout of Super Admin</p>
  </>
);

const SchoolAdminInstruction = (
  <>
    <h2>School Admin How to Set up Simpolicon</h2>
    <h3>Login to www.Simpolicon.org with user name and password assigned to you by Simpolicon</h3>
    <h3>School Admin to Add Classes and Teachers:</h3>
    <Card className="mt-16" size="small">
      Step 1: <b>Add Class to School</b>
      <ol>
        <li>Click on School on Dashboard</li>
        <li>Click on the school name</li>
        <li>Click on Classes tab, Add Class, fill out form, Add</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 2: <b>Add Teacher to Members</b>
      <ol>
        <li>Click on Members on Dashboard</li>
        <li>
          Click on Add Member, fill out form <b>assigning user name and password</b>, click Save
        </li>
        <li>Repeat for each Teacher using Simpolicon</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 3: <b>Add Teacher To Class</b>
      <ol>
        <li>Click on Classes on Dashboard, find class on list and click on it</li>
        <li>Click on Teachers</li>
        <li>Click on Add Teachers, click Teacher’s name on list, and click Add</li>
      </ol>
    </Card>
    <p>
      ***Send login information with <b>username and password YOU have assigned</b> to all the teachers in your school
      using Simpolicon. Also send “Teacher instructions on How to Set up Simpolicon” (link found in the email Simpolicon
      sent to you with your login info.){' '}
      <b>
        Suggestion: maintain a spreadsheet of all the Teachers, user name and passwords, and the classes assigned to
        them in case the forget their logins.
      </b>
    </p>
    <p>Log off as School Admin</p>
  </>
);

const TeacherInstruction = (
  <>
    <h2>Teacher’s Instructions on How to Set up Simpolicon</h2>
    <h3>
      Login to www.Simpolicon.org with the user name and password given to you by your School person setting up
      Simpolicon
    </h3>
    <h3>Teacher’s to add Simulation, Countries, Students:</h3>
    <Card className="mt-16" size="small">
      Step 1: <b>Add a Simulation</b>
      <ol>
        <li>Click Simulation on Dashboard</li>
        <li>Click on Add Simulation</li>
        <li>Name Simulation and choose class name from drop-down, Add</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 2: <b>Add Countries and Students to Simulation</b>
      <ol>
        <li>Click on Simulation on Dashboard</li>
        <li>Click on Simulation you want to play</li>
        <li>Click on Countries Tab</li>
        <li>Add Country from drop-down menu</li>
        <li>Click on the country you just added</li>
        <li>Click on Students Tab</li>
        <li>Click on Add New Students, fill out form assigning user name and password, click Add</li>
        <li>Repeat for enough countries for 2 students per country</li>
      </ol>
    </Card>
    <Card className="mt-16" size="small">
      Step 3: <b>Add Year/Events to Simulation</b>
      <ol>
        <li>Click on Simulation on Dashboard</li>
        <li>Click on Simulation you want to play</li>
        <li>Click on Years/Events</li>
        <li>Click on Add Year</li>
        <li>Fill out Start/Stop dates, green check mark ends the year; green arrow starts the clock</li>
        <li>Fill out Randomization for Fortuitous &amp; Disastrous Events</li>
      </ol>
      <p>Note: You cannot have more than one year in progress at the same time</p>
    </Card>
    <p>
      Assign <b>user names and passwords</b> to all the students playing Simpolicon. When they login, they can go to
      their profile and change the password...
    </p>
  </>
);

export const AdminHelpModal = ({ open, onClose }) => {
  const [role, setRole] = useState(null);

  const fetchAccountRoles = async () => {
    const { resultList } = await ApiService.retrieveAccountRoleList();

    if (resultList.some(role => ['Admin of System', 'Admin of Simpolicon'].includes(role.roleName))) {
      setRole(1);
    } else {
      const isAdmin = resultList.some(role => ['Admin of School'].includes(role.roleName));
      const isTeacher = resultList.some(role => ['Teacher of School', 'Teacher of Class'].includes(role.roleName));
      if (isAdmin && !isTeacher) {
        setRole(2);
      } else if (!isAdmin && isTeacher) {
        setRole(3);
      } else {
        setRole(4);
      }
    }
  };

  useEffect(() => {
    fetchAccountRoles();
  }, []);

  if (role === 1) {
    return (
      <Modal width="80%" title="How to Set up Simpolicon" footer={null} open={open} onCancel={onClose}>
        {SuperAdminInstruction}
        {SchoolAdminInstruction}
        {TeacherInstruction}
      </Modal>
    );
  }

  if (role === 2) {
    return (
      <Modal width="80%" title="How to Set up Simpolicon" footer={null} open={open} onCancel={onClose}>
        {SchoolAdminInstruction}
      </Modal>
    );
  }

  if (role === 3) {
    return (
      <Modal width="80%" title="How to Set up Simpolicon" footer={null} open={open} onCancel={onClose}>
        {TeacherInstruction}
      </Modal>
    );
  }

  return (
    <Modal width="80%" title="How to Set up Simpolicon" footer={null} open={open} onCancel={onClose}>
      {SchoolAdminInstruction}
      {TeacherInstruction}
    </Modal>
  );
};
