import { Link } from 'react-router-dom';
import { IdcardOutlined, PlayCircleOutlined, SettingOutlined } from '@ant-design/icons';
import SessionService from '../../services/SessionService';

export const DATA_LIST = [
  {
    title: 'Languages',
    source: 'retrieveLanguageList',
    key: 'languageList',
  },
  {
    title: 'Regions',
    source: 'retrieveRegionList',
    key: 'regionList',
  },
  {
    title: 'Countries',
    source: 'retrieveCountryList',
    key: 'countryList',
  },
  {
    title: 'States',
    source: 'retrieveStateList',
    key: 'stateList',
    payload: { countryId: 188 },
  },
  {
    title: 'School Levels',
    source: 'retrieveLevelList',
    key: 'schoolLevelList',
  },
  {
    title: 'School Sizes',
    source: 'retrieveSizeList',
    key: 'schoolSizeList',
  },
  {
    title: 'Class Terms',
    source: 'retrieveTermList',
    key: 'classTermList',
  },
  {
    title: 'Class Grades',
    source: 'retrieveGradeList',
    key: 'classGradeList',
  },
  {
    title: 'Resource Types',
    source: 'retrieveResourceTypeList',
    key: 'resourceTypeList',
  },
  {
    title: 'Resources',
    source: 'retrieveResourceList',
    key: 'resourceList',
  },
  {
    title: 'Recipes',
    source: 'retrieveRecipeList',
    key: 'recipeList',
  },
  {
    title: 'Goal Types',
    source: 'retrieveGoalTypeList',
    key: 'goalTypeList',
  },
  {
    title: 'Event Groups',
    source: 'retrieveEventGroupList',
    key: 'eventGroupList',
  },
  {
    title: 'Event Group Items',
    source: 'retrieveEventGroupItemList',
    key: 'eventGroupItemList',
  },
  {
    title: 'Account Roles',
    source: 'retrieveAccountRoleList',
    key: 'accountRoleList',
  },
];

export const SIDER_MENU_ITEMS = [
  {
    key: 'manage',
    icon: <SettingOutlined />,
    label: 'Manage',
    children: [
      {
        key: 'my-profile',
        path: '/portal/my-profile',
        icon: <IdcardOutlined />,
        label: <Link to="/portal/my-profile">My Profile</Link>,
      },
    ],
  },
  {
    key: 'play',
    icon: <PlayCircleOutlined />,
    label: 'Play',
    children: [],
  },
];

export const getDropdownMenuItems = () => {
  const username = SessionService.getUsername();

  return [
    {
      key: 'username',
      label: <label style={{ color: '#bfbfbf' }}>{username}</label>,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: 'Log Out',
    },
  ];
};
