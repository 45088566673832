import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { MainContext } from '../../../layouts/Main/Context';
import ApiService from '../../../services/ApiService';
import Utils from '../../../utils';
import { SchoolModal } from '../Schools/SchoolModal';

export const SchoolDetails = () => {
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useContext(MainContext);
  const { schoolId } = useParams();
  const [school, setSchool] = useState(null);
  const [currentSchool, setCurrentSchool] = useState(null);

  const fetchSchool = async () => {
    try {
      const data = await ApiService.retrieveSchool(schoolId);
      if (data.success) {
        setSchool(data.result);
        setBreadcrumbItems([
          {
            title: 'Schools',
            path: '/main/schools',
          },
          {
            title: data.result.schoolName,
          },
        ]);
      }
    } catch (e) {
      //
    }
  };

  const handleEditSchool = () => {
    setCurrentSchool({ ...school });
  };

  const handleCloseSchoolModal = () => {
    setCurrentSchool(null);
  };

  const handleSchoolSaved = () => {
    handleCloseSchoolModal();
    fetchSchool();
  };

  const handleManageClasses = () => {
    navigate(`/main/schools/${schoolId}/classes`);
  };

  const handleManageStudents = () => {
    navigate(`/main/schools/${schoolId}/students`);
  };

  const handleManageTeachers = () => {
    navigate(`/main/schools/${schoolId}/teachers`);
  };

  const handleManageAdmins = () => {
    navigate(`/main/schools/${schoolId}/admins`);
  };

  const ActionBar = (
    <>
      {Utils.checkPermissions('Admin of School', school?.schoolId) && (
        <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleEditSchool}>
          Edit School
        </Button>
      )}
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageClasses}>
        Classes
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageStudents}>
        Students
      </Button>
      <Button size="small" style={{ marginRight: 8 }} type="primary" onClick={handleManageTeachers}>
        Teachers
      </Button>
      {Utils.checkPermissions('Admin of School', school?.schoolId) && (
        <Button size="small" type="primary" onClick={handleManageAdmins}>
          Admins
        </Button>
      )}
    </>
  );

  useEffect(() => {
    fetchSchool();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card size="small" title="Manage School" extra={ActionBar}>
        <Form labelCol={{ span: 2 }} disabled>
          <Form.Item label="Name">
            <Input value={school?.schoolName} />
          </Form.Item>
          <Form.Item label="Level">
            <Input value={school?.levelName} />
          </Form.Item>
          <Form.Item label="Country">
            <Input value={school?.countryName} />
          </Form.Item>
          <Form.Item label="State">
            <Input value={school?.stateName} />
          </Form.Item>
          <Form.Item label="City">
            <Input value={school?.city} />
          </Form.Item>
          <Form.Item label="Language">
            <Input value={school?.primaryLanguageName} />
          </Form.Item>
        </Form>
      </Card>
      <SchoolModal school={currentSchool} onClose={handleCloseSchoolModal} onSave={handleSchoolSaved} />
    </>
  );
};
