import { useEffect, useState } from 'react';
import { notification, Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { DOB_YEAR_LIST, GENDER_LIST, LANGUAGE_LIST } from '../../../data';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';

export const MemberModal = ({ member, onClose, onSave }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const isNew = !member?.userId;

  const countryList = DataService.getItem('countryList');

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = { ...values };
      if (isNew) {
        if (!payload.genderId) {
          payload.genderId = 0;
        }
        if (!payload.primaryLanguageId) {
          payload.primaryLanguageId = 1;
        }
        if (!payload.countryId) {
          payload.countryId = 188;
        }
        if (!payload.yearOfBirth) {
          payload.yearOfBirth = 0;
        }
        await ApiService.addMember(payload);
      } else {
        await ApiService.updateMember(member.userId, payload);
      }
      onSave();
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (member?.userId) {
      form.setFieldsValue(member);
    } else {
      form.resetFields();
    }
  }, [form, member]);

  return (
    <Modal
      title={isNew ? 'Add Member' : 'Edit Member'}
      open={!!member}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {contextHolder}
      <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={false} autoComplete="off">
        <Form.Item
          label="Username"
          name="userName"
          rules={[
            {
              required: true,
              message: 'Username is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={
            isNew
              ? [
                  {
                    required: true,
                    message: 'Password is required!',
                  },
                ]
              : []
          }
        >
          <Input.Password />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'First name is required!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Last name is required!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Gender" name="genderId">
          <Select showSearch placeholder="Select gender" options={GENDER_LIST} />
        </Form.Item>
        <Form.Item label="Primary Language" name="primaryLanguageId">
          <Select showSearch placeholder="Select primary language" options={LANGUAGE_LIST} />
        </Form.Item>
        <Form.Item label="Country" name="countryId">
          <Select
            showSearch
            placeholder="Select country"
            optionFilterProp="countryName"
            fieldNames={{ label: 'countryName', value: 'countryId' }}
            options={countryList}
          />
        </Form.Item>
        <Form.Item label="Year of Birth" name="yearOfBirth">
          <Select showSearch placeholder="Select year of birth" options={DOB_YEAR_LIST} />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
